import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumb = props => {
    const { history, actualPosition, previousPositions, match } = props;

    return (
        <nav aria-label="breadcrumb" id="breadCrumb">
            <ol className="breadcrumb px-0">
                {
                    previousPositions.map((position, index) => 
                        <li key={index} className="breadcrumb-item">
                            <Link to={(() =>
                            {
                                if(position === "Inicio")
                                    return "/";
                                if(position === "Recursos")
                                    return "/recursos";

                                return "#";
                                
                            })()}
                            >
                                {position}
                            </Link>
                        </li>
                    )
                }

                <li className="breadcrumb-item active" aria-current="page">
                    {actualPosition}
                </li>
            </ol>
        </nav>
    )
}
export default BreadCrumb;