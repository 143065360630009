import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { useMisRecursos } from "./MisRecursosContext";
import { MisRecursosLikeList } from "./MisRecursosLikeList";

export const MisRecursosContent = ({
    loading,
    loadingAuth,
    likes,
    recursos,
    addRecursoToFolder,
}) =>
{
    const {
        activeFolderId,
        activeFolder,
    } = useMisRecursos();
    const isEmpty = (activeFolderId !== null && (likes ?? []).length === 0) || (activeFolderId === null && (recursos.compartidos ?? []).length === 0);

    return (
<>
<div className="mis-recursos-content">
    <h5>Mis Recursos</h5>
    <h6 style={{
        overflowWrap: 'break-word',
    }}>
        "{activeFolder?.title}"
    </h6>
    <div className="row mt-4 px-3" id="Popover2">
        { (loading || loadingAuth)
            ? <CircularProgress className="mr-auto ml-auto" />
            :
            <>
            { isEmpty &&
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>La lista esta vacía. <Link to="/recursos">Ir al Listado de Recursos</Link></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                </table>
            }
            { !isEmpty &&
                <MisRecursosLikeList
                    likes={likes}
                    recursos={recursos}
                    activeFolderId={activeFolderId}
                    addRecursoToFolder={addRecursoToFolder}
                />
            }
            </>
        }
    </div>
</div>
</>
    )
}
