import React from "react";
import prettifyDate from "src/util/prettifyDate";
import LicenceImage from "../base/images/LicenceImage";
import LinkToResourcesWithFilter from "../base/LinkToResourcesWithFilter";

export default function CollectionDataSheet(props)
{
    const {
        dataCollection
    } = props;

    const {
        // collection,
        subjects,
        tags,
        audiences,
        authors,
        levels,
        publication_date,
        created_at,
        pedagogic_proposal_id,
        pedagogic_proposal_description,
        pedagogic_offer_id,
        pedagogic_offer_description,
        // pedagogicOffer,
        filters,
    } = dataCollection;

    console.log("<<<<<dataCollection>>>>>")
    console.log(dataCollection)
    //const { filters } = recurso;

    return (
<div className="ficha-container-grid">
    <div className="card">
        <div className="card-body p-0">
            <h3 className="titulo-ficha">Ficha tecnica</h3>
            <div className="ficha-publicacion-container">
                <div className="ficha-publicacion-data col-md-6">
                    <p className="publicacion-title">Publicado el:</p>
                    <p className="publicacion-fecha">{ prettifyDate(created_at) }</p>
                </div>
                <div className="publicacion-data col-md-6">
                    <p className="publicacion-title">Última modificación el:</p>
                    <p className="publicacion-fecha">{ prettifyDate(publication_date) }</p>
                </div>
            </div>
            <div className="ficha-detalle-container">
                <div className="row">
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Eje PNIDE</p>
                        <LinkToResourcesWithFilter
                            className="detalle-link"
                            filters={filters}
                            filterName="pedagogic_proposals"
                            filterValue={pedagogic_proposal_id}
                        >
                            {pedagogic_proposal_description}
                        </LinkToResourcesWithFilter>
                    </div>
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Área / disciplina</p>
                        {
                            subjects.map((area)=>
                            (
                                <LinkToResourcesWithFilter
                                    key={area.id}
                                    className="detalle-link"
                                    filters={filters}
                                    filterName="subjects"
                                    filterValue={area.id}
                                >
                                    {area.description}
                                </LinkToResourcesWithFilter>
                            ))
                        }
                    </div>
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Tipo de recursos</p>
                        <LinkToResourcesWithFilter
                            className="detalle-link"
                            filters={filters}
                            filterName="resource_types"
                            filterValue={dataCollection.resource_type_id}
                        >
                            {dataCollection.resource_type_description}
                        </LinkToResourcesWithFilter>
                    </div>
                    
                    {/* Tipo de propuesta */}
                    { (dataCollection.category_id && dataCollection.category_description) &&
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Tipo de propuesta</p>
                        <LinkToResourcesWithFilter
                            className="detalle-link"
                            filters={filters}
                            filterName="category"
                            filterValue={dataCollection.category_id}
                        >
                            {dataCollection.category_description}
                        </LinkToResourcesWithFilter>
                    </div>}
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Etiquetas</p>
                        {
                            tags.map((tag)=>
                            (
                                <LinkToResourcesWithFilter
                                    key={tag.id}
                                    className="detalle-link"
                                    filters={filters}
                                    filterName="tags"
                                    filterValue={tag.id}
                                >
                                    #{tag.description}
                                </LinkToResourcesWithFilter>
                            ))
                        }
                    </div>
                    {
                        pedagogic_offer_id && (
                            <div className="detalle-data col-sm-3 mb-3">
                                <p className="detalle-title">Propuesta pedagógica</p>
                                <LinkToResourcesWithFilter
                                    className="detalle-link"
                                    filters={filters}
                                    filterName="pedagogic_offerings"
                                    filterValue={pedagogic_offer_id}
                                >
                                    {pedagogic_offer_description}
                                </LinkToResourcesWithFilter>
                            </div>
                        )
                    }
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Persona Destinataria</p>
                        {
                            Object.values(audiences).map((destinatario, keyDestinatario)=>{
                                return (
                                    <p className="data-info" key={keyDestinatario}>{destinatario.description}</p>
                                )
                            })
                        }
                    </div>

                    {/* Niveles/Ciclos */}
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Niveles/Ciclos</p>
                        {
                            Object.values(levels).map((nivel, keyNivel)=>{
                                return (
                                    <React.Fragment key={keyNivel}>
                                        <LinkToResourcesWithFilter
                                            key={keyNivel}
                                            className="detalle-link"
                                            filters={filters}
                                            filterName="levels"
                                            filterValue={nivel.id}
                                        >
                                            {nivel.description}
                                        </LinkToResourcesWithFilter>
                                        <br/>
                                    </React.Fragment>
                                    
                                )
                            })
                        }
                    </div>
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Autor/es</p>
                        {
                            Object.values(authors).map((autor, keyAuthor)=>{
                                return (
                                    <p className="data-info" key={keyAuthor}>{autor.description}</p>
                                )
                            })
                        }
                    </div>
                    <div className="detalle-data col-sm-3 mb-3">
                        <p className="detalle-title">Licencia</p>
                        <LicenceImage/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    )
}