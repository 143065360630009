import React from 'react'

const InteractivoRecurso = (props) => {
    const { interactivos } = props
    
    return (
        <>
            {
                interactivos.map(interactivo =>{
                    return(
                        <iframe key={interactivo.id} id="iframeInteractivo" src={interactivo.url} title={interactivo.file} className="embed-responsive embed-responsive-16by9" style={{ height: "100vh" }} />
                    )
                })
            }
        </>
    )
}

export default InteractivoRecurso