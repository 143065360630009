import React from 'react';

const NotFound = ( { history } ) => {

    return (
        <div className="container-fluid e-404-comp">
            <div className="col-xl-8 offset-xl-2 col-12">
                <div className="card-login2 jm1 shadow-sm text-center">
                    <h2 className="font-weight-bold mt-1 text-white">404</h2>
                    <p className="mt-0 text-white">La página que busca no esta disponible</p>
                    <a
                        href="#REA"
                        className="btn btn-sm btn-secondary btn-bold ripple"
                        onClick={(event) => {
                            event.preventDefault();
                            history.push("/inicio");
                        }}
                    >
                    VOLVER AL SITIO
                    </a>
                </div>
            </div>
        </div>
    )
}
export default NotFound;