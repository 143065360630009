import React from 'react';

import LinkComponent from 'src/components/shared/LinkComponent';

const FootBar = ({ options }) => (
    <React.Fragment>     
        {           
            options && options.map(item => (
                <div key={item.id}>
                    {item.link_type !== 'children' && (
                        <LinkComponent
                            href={item.link}
                            className="text-white lh-30"
                        >
                            {item.name}
                        </LinkComponent>
                    )}
                </div>
            ))
        }
    </React.Fragment>
)

export default FootBar