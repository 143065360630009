import React from 'react';

import DownloadTranscript from 'src/assets/images/boton-descarga-transcripcion-icono-negro.svg';

const TranscriptIcon = () => {
    return (
        <>
            <p>Descargar transcripción</p>
            <img src={DownloadTranscript} alt="Descargar Transcripción" width="48px" height="48px" />
        </>
    );
}

export default TranscriptIcon