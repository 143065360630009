import { UserTypes } from "../constants/types"
import { AuthService, BackofficeService } from "src/axios";
// import { NotificationManager } from 'react-notifications';
// import { refreshTokenUser } from "src/helpers/auth";
import { cleanCookie, hasCookie } from "src/helpers/cookie";
import { showDeveloperMessage } from "src/helpers/env";

export const ActionCreators = {
    userLoading: () => ({ type: UserTypes.LOADING }),
    userNotLoading: () => ({ type: UserTypes.NOT_LOADING }),
    userFetchSuccess: (data) => ({ type: UserTypes.FETCH_SUCCESS, payload: data }),
    userFetchFailure: () => ({ type: UserTypes.FETCH_FAILURE }),
    userMenu: (data) => ({ type: UserTypes.SET_MENU, payload: data })
};

export const getDataUser = () => async dispatch => {
    if(!hasCookie('logged_in'))
        return;

    dispatch(ActionCreators.userLoading());
    dispatch({ type: UserTypes.FETCH });

    const menu = await BackofficeService.fetchUserMenu()
                            .catch((error) => []);

    try {
        const user = await AuthService.getAuthUser()
                                .then((res) => res.data);

        user.folderMisFavoritos = user.likeFolders[0]
        user.likeFolders = user.likeFolders.filter(e => e.id !== 0);

        dispatch(ActionCreators.userFetchSuccess(user));
        dispatch(ActionCreators.userMenu(menu ?? []));

        showDeveloperMessage('user', user);
    } catch(error) {  
        cleanCookie('logged_in');
        dispatch(ActionCreators.userFetchFailure(error));
    } finally {
        dispatch(ActionCreators.userNotLoading());
    }
}
