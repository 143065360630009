const decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');

    function decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
}());

export const stripHTML = (string) => {
    const htmlEl = document.createElement('html');
    htmlEl.innerHTML = string;

    return (
        htmlEl.innerHTML.replace(/<\/?[^>]+(>|$)/g, '').replace(/(&[AaEeIiOoUu]acute;)/g, (match) => decodeEntities(match)).replace(/(&[AaEeIiOoUu]grave;)/g, (match) => decodeEntities(match))
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
    )
};
