const unescape = require('unescape');

export const unescapeHtml = (s) =>
{
    return unescape(s)
    .replaceAll("&aacute;", "á")
    .replaceAll("&eacute;", "é")
    .replaceAll("&iacute;", "í")
    .replaceAll("&oacute;", "ó")
    .replaceAll("&uacute;", "ú")
    .replaceAll("&Aacute;", "Á")
    .replaceAll("&Eacute;", "É")
    .replaceAll("&Iacute;", "Í")
    .replaceAll("&Oacute;", "Ó")
    .replaceAll("&Uacute;", "Ú")

    .replaceAll("&auml;", "ä")
    .replaceAll("&euml;", "ë")
    .replaceAll("&iuml;", "ï")
    .replaceAll("&ouml;", "ö")
    .replaceAll("&uuml;", "ü")
    .replaceAll("&Auml;", "Ä")
    .replaceAll("&Euml;", "Ë")
    .replaceAll("&Iuml;", "Ï")
    .replaceAll("&Ouml;", "Ö")
    .replaceAll("&Uuml;", "Ü")

    .replaceAll("&agrave;", "à")
    .replaceAll("&egrave;", "è")
    .replaceAll("&igrave;", "ì")
    .replaceAll("&ograve;", "ò")
    .replaceAll("&ugrave;", "ù")
    .replaceAll("&Agrave;", "À")
    .replaceAll("&Egrave;", "È")
    .replaceAll("&Igrave;", "Ì")
    .replaceAll("&Ograve;", "Ò")
    .replaceAll("&Ugrave;", "Ù")

    .replaceAll("&acirc;", "â")
    .replaceAll("&ecirc;", "ê")
    .replaceAll("&icirc;", "î")
    .replaceAll("&ocirc;", "ô")
    .replaceAll("&ucirc;", "û")
    .replaceAll("&Acirc;", "Â")
    .replaceAll("&Ecirc;", "Ê")
    .replaceAll("&Icirc;", "Î")
    .replaceAll("&Ocirc;", "Ô")
    .replaceAll("&Ucirc;", "Û")

    .replaceAll("&ntilde;", "ñ")
    .replaceAll("&Ntilde;", "Ñ")

    .replaceAll("&szlig;", "ß")

    .replaceAll("&nbsp;", " ")

    .replaceAll("&iexcl;", "¡")
    .replaceAll("&iquest;", "¿")
    ;
}
