import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { MisRecursosMoveLikeDialog } from './dialog/MisRecursosMoveLikeDialog';
import { MisRecursosUnlikeResourceDialog } from './dialog/MisRecursosUnlikeResourceDialog';
import { useMisRecursos } from './MisRecursosContext';
import { buildResourceRoute } from 'src/util/buildResourceRoute';

export const MisRecursosLikeListItemDropdown = (props) =>
{
    const history = useHistory();
    const { activeFolderId, likeFolders } = useMisRecursos();

    const [ modalOpen, setModalOpen ] = useState(false);
    const [ resourceIdToUnlike, setResourceIdToUnlike ] = React.useState(null);

    const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);
    const modalToggle = () => setModalOpen(!modalOpen);
    const toggle = () => { setMenuDropdownOpen(!menuDropdownOpen)};

    return (
        <>
        <Dropdown isOpen={menuDropdownOpen} toggle={toggle} style={{textAlign:'right'}}>

            <DropdownToggle className="btn-round shadow" color="light">
                <i className="fa fa-ellipsis-v" style={{color: "black"}}/>
            </DropdownToggle>

            <DropdownMenu right className="dropdown-menu" style={{ backgroundColor: "white", overflowX: "hidden", overflowY: "hidden", right: 0, left: "auto" }}>
                {
                    activeFolderId !== null && likeFolders.length > 0 &&
                    <DropdownItem onClick={modalToggle}>
                        MOVER
                    </DropdownItem>
                }

                <DropdownItem onClick={()=>
                {
                    // El segundo es para eliminar de moddle
                    setResourceIdToUnlike(activeFolderId !== null ? props.recurso.resource_id : props.recurso.id);
                }}>
                    ELIMINAR
                </DropdownItem>

                <DropdownItem onClick={()=>
                {
                    history.push(buildResourceRoute(props.recurso));
                }}>
                    VER
                </DropdownItem>

            </DropdownMenu>
        </Dropdown>

        { /* Move like dialog */ }
        { activeFolderId !== null &&
        <MisRecursosMoveLikeDialog
            modalOpen={modalOpen}
            modalToggle={modalToggle}
            resource={props.recurso}
            addRecursoToFolder={props.addRecursoToFolder}
        /> }

        { /* Unklike resource dialog */ }
        <MisRecursosUnlikeResourceDialog
            resourceIdToUnlike={resourceIdToUnlike}
            onClose={() => setResourceIdToUnlike(null)}
        />

        </>
    )
}
