import React, { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isInMaintenanceMode } from 'src/helpers/env';

const CheckForMaintenanceMode = ({ ...props }) => {
    const match = useRouteMatch("/mantenimiento");
    const history = useHistory();

    useEffect(() => {
        if(isInMaintenanceMode() && !match) {
            history.push("/mantenimiento");
        }

        if(isInMaintenanceMode() && match) {
            
        }

        if(!isInMaintenanceMode() && match) {
            history.push("/inicio");
        }
    }, [match, history]);

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default CheckForMaintenanceMode;
