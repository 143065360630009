import React, { useState } from 'react';
// import ReactJWPlayer from 'react-jw-player';
import { UncontrolledCollapse, /* UncontrolledDropdown */ } from 'reactstrap';
// import SubMenuVideoSeason from './subMenuVideoSeason';
import Reproductor from './reproductor';
import ReactHtmlParser from 'html-react-parser';

const VideoRecursoSeasons = ({ history, recurso, seasons }) => {
    const recursoId = recurso.resource_id;
    const temporadas = seasons.filter((season, index) => season.chapters[0].length > 0);

    const currentChapter = temporadas.reduce((acummulatorCapitulo, curSeason) => {
        /* Si ya encontró el capítulo, va a devolverlo en lugar de analizar la temporada. */
        if(acummulatorCapitulo.resource_id === recursoId) return acummulatorCapitulo;
        /* El array de capitulos es de 1 elemento, el cual contiene el subarrray de los capítulos */
        const foundChapter = curSeason.chapters[0].find(
            (curChapter) => curChapter.resource_id === recursoId
        )
        /* Si no encuentra el capitulo en la temporada, devuelve el seed. */
        return foundChapter ?? acummulatorCapitulo;
    }, {} /* seed */
    );
    
    const [videoAReproducir/* , setVideoAReproducir */] = useState(currentChapter);

/*     const changePlayerVideo = (video) => {
        setVideoAReproducir(video)
        // $('html, body').animate({scrollTop:$('#videoPlayer').position().top}, 'slow');
    } */

    return (
        <>
            { !recurso.embebedCod && <Reproductor video={{...videoAReproducir, preview_video_path: recurso?.attachments[0]?.preview_video_path ?? '' }} />}
            <div className="row vidGallery">
                <div className="col-md-12">
                    {
                        temporadas.map((temporada, index) => {
                            const { id: season_id, title: season_title, chapters } = temporada;
                            return (
                                <div className="card card-dark mb-3" key={index}>
                                    <div className="card-header2" id="headingOne">
                                        <h5 className="mb-0">
                                            <button id={`toggler-${season_id}`} className="btn btn-block text-left text-secondary" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                {season_title}
                                            </button>
                                        </h5>
                                    </div>
                                    <UncontrolledCollapse toggler={`#toggler-${season_id}`}>
                                        <div className="card-body">
                                            <table className="table table-dark">
                                                <tbody>
                                                    {
                                                        chapters[0].map((capitulo, index) => {
                                                            return (
                                                                <tr className={videoAReproducir.id === capitulo.id ? "vidActive" : ""} key={index}>
                                                                    <td style={{ width: "10%" }} className="hidden-xs">
                                                                        {/* <div className="icono_play play" onClick={() => changePlayerVideo(capitulo)}></div> */}
                                                                        <div className="icono_play play" onClick={() => {history.push(`/recurso/${capitulo.resource_id}`)}}></div>
                                                                    </td>
                                                                   {/* <td style={{ width: "80%" }}><a href="#REA" onClick={(event) => { event.preventDefault(); changePlayerVideo(capitulo) }} className="h5 group card-title inner list-group-item-heading mb-0 parrafo-sin-margen-abajo">{ReactHtmlParser(capitulo.title)}</a><br /><p style={{ color: "#fff" }}>{capitulo.duration && `(${capitulo.duration} minutos)`}</p></td> */}
                                                                    <td style={{ width: "80%" }}>
                                                                        <a href="#REA" onClick={(event) => { event.preventDefault(); history.push(`/recurso/${capitulo.resource_id}`) }} className="h5 group card-title inner list-group-item-heading mb-0 parrafo-sin-margen-abajo">{ReactHtmlParser(capitulo.title)}</a>
                                                                        <br />
                                                                        <p style={{ color: "#fff" }}>{capitulo.duration && `(${capitulo.duration} minutos)`}</p>
                                                                    </td>
                                                                    <td style={{ width: "10%" }} className="text-right pr-3">
                                                                        {/* <SubMenuVideoSeason /> */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </UncontrolledCollapse>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
        </>
    )
}

export default VideoRecursoSeasons