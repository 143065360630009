import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { isMobile } from "src/helpers";

export default function REATooltip({children, ...props})
{

  if(isMobile()){
    return <></>
  }
  return (
    <UncontrolledTooltip {...props} >
      {children}
    </UncontrolledTooltip>
  )
}

