export const GET_RECURSOS="GET_RECURSOS"
export const GET_RECURSOS_SUCCESS="GET_RECURSOS_SUCCESS"
export const GET_RECURSOS_FAILURE="GET_RECURSOS_FAILURE"
export const GET_RECURSO_BY_ID="GET_RECURSO_BY_ID"
export const GET_RECURSO_BY_ID_SUCCESS="GET_RECURSO_BY_ID_SUCCESS"
export const GET_RECURSO_BY_ID_FAILURE="GET_RECURSO_BY_ID_FAILURE"

export const UserTypes = {
    FETCH: "GET_USER",
    FETCH_SUCCESS: "GET_USER_SUCCESS",
    FETCH_FAILURE: "GET_USER_FAILURE"
}

export const GET_USERMENU_BACKOFFICE = "GET_USERMENU_BACKOFFICE"
export const GET_USERMENU_BACKOFFICE_SUCCESS = "GET_USERMENU_BACKOFFICE_SUCCESS"
export const GET_USERMENU_BACKOFFICE_FAILURE = "GET_USERMENU_BACKOFFICE_FAILURE"

export const GET_LIKED_RECURSOS = "GET_LIKED_RECURSOS"
export const GET_LIKED_RECURSOS_SUCCESS = "GET_LIKED_RECURSOS_SUCCESS"
export const GET_LIKED_RECURSOS_FAILURE = "GET_LIKED_RECURSOS_FAILURE"

export const GET_HEADER = "GET_HEADER"
export const GET_HEADER_SUCCESS = "GET_HEADER_SUCCESS"
export const GET_HEADER_FAILURE = "GET_HEADER_FAILURE"

export const GET_FOOTER = "GET_FOOTER"
export const GET_FOOTER_SUCCESS = "GET_FOOTER_SUCCESS"
export const GET_FOOTER_FAILURE = "GET_FOOTER_FAILURE"

export const GET_CONTACT = "GET_CONTACT"
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS"
export const GET_CONTACT_FAILURE = "GET_CONTACT_FAILURE"

export const GET_TAG_BACKOFFICE = "GET_TAG_BACKOFFICE"
export const GET_TAG_BACKOFFICE_SUCCESS = "GET_TAG_BACKOFFICE_SUCCESS"
export const GET_TAG_BACKOFFICE_FAILURE = "GET_TAG_BACKOFFICE_FAILURE"

export const GET_CLASSROOMS = "GET_CLASSROOMS"
export const GET_CLASSROOMS_SUCCESS = "GET_CLASSROOMS_SUCCESS"
export const GET_CLASSROOMS_FAILURE = "GET_CLASSROOMS_FAILURE"

export const GET_CLASSROOMS_ROLES = "GET_CLASSROOMS_ROLES";
export const GET_CLASSROOMS_ROLES_SUCCESS = "GET_CLASSROOMS_ROLES_SUCCESS";
export const GET_CLASSROOMS_ROLES_FAILURE = "GET_CLASSROOMS_ROLES_FAILURE";

export const CLASSROOM_NOT_LOADING = "CLASSROOM_NOT_LOADING";

export const GET_RECURSOS_COMPARTIDOS = "GET_RECURSOS_COMPARTIDOS";
export const GET_RECURSOS_COMPARTIDOS_SUCCESS = "GET_RECURSOS_COMPARTIDOS_SUCCESS";
export const GET_RECURSOS_COMPARTIDOS_FAILURE = "GET_RECURSOS_COMPARTIDOS_FAILURE";
