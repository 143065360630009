import React from "react";
import { Menu, makeStyles } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { isMobile } from 'src/helpers';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ShareResourceButtonMenuItem } from "./ShareResourceButtonMenuItem";

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        width: "100%",
        left: "0%!important"
    }
}));

export const ShareResourceButtonMenu = ({resourceId, anchorEl, onClose, isCollection}) =>
{
    const classes = useStyles();

    let urlPortalSplitted = (process.env.REACT_APP_BASE_URL).split('/');
    urlPortalSplitted = urlPortalSplitted.splice(0,urlPortalSplitted.length - 2);
    const urlPortalAPI = (urlPortalSplitted).join('/');
    const location = useLocation();

    const urlToShare = isCollection
            ? `${window.origin}${location.pathname}`
            : `${urlPortalAPI}/compartir/${resourceId}`
            ;

    const CancelButtonDeMania = () => (
        window.screen.availWidth <= 769
        ? (<>
            <hr/>
            <span style={{color: "black", cursor: 'pointer'}} onClick={(e) =>
            {
                onClose();
            }}>
                <div className="pl-1 text-center" >
                    Cancelar
                </div>
            </span>
        </>) : <></>
    );

    return (
<>
<Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={anchorEl != null}
    onClose={onClose}
    classes={ window.screen.availWidth < 769 ? { paper: classes.menuPaper } : {}}
    style={{marginTop: "40px", width: "110%"}}
>
    <div className="row">
        <div className="col-6">
        <strong className="pl-1">Compartir</strong>
        </div>
        {
        window.screen.availWidth > 769 &&
        <div className="col-5" style={{textAlign: "right"}}>
            <span style={{cursor: 'pointer'}} onClick={(e) =>
                {
                    onClose();
                }}
            >
                <i className="fa fa-close" style={{color: "black"}}/>
            </span>
        </div>
        }
    </div>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>

        { /* Facebook */ }
        <ShareResourceButtonMenuItem
            title="Compartir en Facebook"
            href={`https://www.facebook.com/sharer/sharer.php?u=${urlToShare}&amp;src=sdkpreparse`}
        >
            <FacebookIcon style={{ color: "#3B5998" }} />
        </ShareResourceButtonMenuItem>

        { /* Twitter */ }
        <ShareResourceButtonMenuItem
            href={`http://twitter.com/share?url=${urlToShare}`}
            title="Compartir en Twitter"
            data-show-count="false"
        >
            <TwitterIcon style={{ color: "#1da1f2" }} />
            <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
        </ShareResourceButtonMenuItem>

        { /* WhatsApp */ }
        <ShareResourceButtonMenuItem
            href={
                isMobile()
                ? `whatsapp://send?text=${urlToShare}`
                : `https://web.whatsapp.com/send?text=${urlToShare}`
            }
            title="Compartir en WhatsApp"
            data-action="share/whatsapp/share"
        >
            <WhatsAppIcon style={{ color: "#64d467" }} />
        </ShareResourceButtonMenuItem>

    </div>
    <CancelButtonDeMania/>
</Menu>
</>
    )
}
/* const SafeLink = ({children, ...props}) => (
    <a
        target="_blank"
        rel="noopener noreferrer"
        {...props}
    >
        {children}
    </a>
) */