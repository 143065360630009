import React from "react";
import { GALERIA_IMAGENES } from "src/util/constantes";
import GetAppIcon from 'src/assets/images/JM_iconos-07_descargar.svg';

export const DownloadImageGalleryResourceButton = ({view, resource, hasAttachments}) =>
{
    if(resource.resources_format_id === GALERIA_IMAGENES && hasAttachments)
    {
        const url = resource.attachments.length > 1
                    ? resource.downloadZippedAttachmentsUrl
                    : resource.attachments[0].url
                    ;

        return (
            <a
                href={url}
                download={resource.display_title}
                className={view !== 'detail' ? "icon-bar" : "icon-bar detail"}
                rel="noopener noreferrer"
                title="Descargar"
            >
                <img src={GetAppIcon} alt="Descargar" width="28px" height="28px" />
            </a>
        )
    }

    return <></>
}
