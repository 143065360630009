import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Loading from 'src/components/shared/loading';
import { getHeader, getFooter } from 'src/redux/actions/backofficeActions';

import { isInMaintenanceMode } from 'src/helpers/env';

import Header from './shared/Header';
import Footer from './shared/Footer';

const AppLayout = ({ footer, header, fetchHeader, fetchFooter, ...props }) => {

    useEffect(() => {
        fetchHeader();
        fetchFooter();
    }, [fetchHeader, fetchFooter]);

    if(footer.loading || header.loading) {
        return <Loading/>;
    }

    return (
        <React.Fragment>
            <Header options={header.menu} logo={header.logo} maintenanceMode={isInMaintenanceMode()} />
                <main role="main" className="flex-shrink-0">
                    {props.children}
                </main>
            <Footer options={footer.menu} logo={footer.logo} logo_centro={footer.logo_centro} text={footer.text} maintenanceMode={isInMaintenanceMode()} />
        </React.Fragment>
    )
}

const mapStateToProps = ({ header, footer }) => ({
    header,
    footer
});

const mapDispatchToProps = {
	fetchHeader: getHeader,
	fetchFooter: getFooter
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout)
