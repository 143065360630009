import React from "react";
import shareIcon from 'src/assets/images/shareIcon.svg';
import { ShareResourceButtonMenu } from "./ShareResourceButtonMenu";

export const ShareResourceButton = ({view, resourceId, isCollection}) =>
{
    const [ anchorEl, setAnchorEl ] = React.useState(null);

    return (
<>
{ /* Botón */ }
<div
    title="Compartir en redes"
    aria-controls="simple-menu"
    aria-haspopup="true"
    onClick={(e) =>
    {
        setAnchorEl(e.currentTarget);
    }}
    className={ view !== 'detail' ? "icon-bar" : "icon-bar detail"}
    style={{
        cursor: 'pointer'
    }}
>
    <img
        src={shareIcon}
        alt="Botón de compartir"
        width="24"
        height="24"
    />
</div>

{ /* Menu */ }
<ShareResourceButtonMenu
    resourceId={resourceId}
    isCollection={isCollection}
    anchorEl={anchorEl}
    onClose={() =>
    {
        setAnchorEl(null);
    }}
/>
</>
    )
}
