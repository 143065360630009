import rea from '../api/rea';

const userParams = new URLSearchParams({
    likes: 1,
    aulas: 0,
    preferences: 0
});

const AuthService = {
    /**
     * @method getAuthUser
     * @description Obtiene usuario autenticado
     */
    getAuthUser:() => rea.get(`/user?${(userParams).toString()}`),
    /**
     * @method sendMail
     * @params data: Object
     * @description Envía mail a contacto
     */
    sendMail: (data) => rea.post('/contact', data),
};

export {AuthService as default};
