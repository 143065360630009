import rea from '../api/rea';

const MoodleService = {
    /**
     * @method getAulas
     * @description Obtiene aulas de Moodle
     */
    getAulas: () => rea.get(`/classroom/access-points`),
    /**
     * @method getClassroomUser
     * @description Obtiene aulas de moodle
     */
    getClassroomUser: () => rea.get(`/classroom`),
    /**
     * @method getRolesUser
     * @description Obtiene roles de moodle
     */
    getRolesUser: () => rea.get(`/classroom/roles`),
    /**
     * @method getSharedResources
     * @description Obtiene recursos compartidos en moodle
     */
    getSharedResources: () => rea.get('/resources'),
    /**
     * @method deleteResource 
     * @params resourceId: Number, folder?: Number
     * @description Elimina un recurso compartido en moodle
     */
    deleteResource: (id) => rea.delete(`/classroom/mod/${id}`),

    getSectionsAula : (idAula, type_aula) => (
        rea.get(`/classroom/${type_aula}/${idAula}/sections`)
    ),

    getUrlModdleTask : (type_aula, idAula, query = '') => (
         rea.get(`/classroom/${type_aula}/${idAula}/task/add?${query}`)
    ),

    getUrlModdleResource : (type_aula, idAula, query = '') => (
        rea.get(`/classroom/${type_aula}/${idAula}/resource/add?${query}`)
    ),
};

export { MoodleService as default };
