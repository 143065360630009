import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Loading from "src/components/shared/loading";
import { GenericSideBar, useGenericSideBar } from 'src/components/sidebar/GenericSideBar';
import { VistaRecursosListaContent } from "./VistaRecursosListaContent";
import { VistaRecursosListaSidebar } from "./VistaRecursosListaSidebar";
import { showDeveloperMessage } from "src/helpers";

export function VistaRecursosLista(props)
{
    const {
        recursos,
        authReducer,
        handleGetRecursos,
        breadcrumbCurrent,
        breadcrumbPrevious,
        renderSearchInput,
    } = props;

    const { show } = useGenericSideBar();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        if(recursos.all) {
            showDeveloperMessage("<<<<<<<recursos>>>>>>>", recursos)
        }
    }, [recursos]);

    if(recursos.all == null)
        return <Loading fullWidth={false} />

    const className = ["pt-0"];
    if(show)
        className.push("collapsed");

    return (
        <>
            <div className="container-fluid px-0">
                <div className="wrapper" id="wrapper">
                    <GenericSideBar title={"Filtros"}>
                        <VistaRecursosListaSidebar
                            filtros={recursos.all.filters}
                            history={history}
                            location={location}
                        />
                    </GenericSideBar>
                    <div id="content" className={className.join(" ")}>
                        <VistaRecursosListaContent
                            location={location}
                            history={history}
                            match={match}
                            recursos={recursos.all}
                            authReducer={authReducer}
                            loading={recursos.loading}
                            handleGetRecursos={handleGetRecursos}
                            breadcrumbCurrent={breadcrumbCurrent}
                            breadcrumbPrevious={breadcrumbPrevious}
                            renderSearchInput={renderSearchInput}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
