import React from 'react'
import AudioPlayer from 'src/components/shared/AudioPlayer';
import { showDeveloperMessage } from 'src/helpers';

const AudioRecurso = (props) => {
    const { audios } = props

    React.useEffect(() => {
        showDeveloperMessage('audios', audios);
    }, [audios])
    
    const sortedAudios = audios.sort((a, b) =>
    {
        return a.order - b.order;
    });

    return (
        <div className="row">
            {
                sortedAudios.map((audio) => (
                    <AudioPlayer key={audio.id} audio={audio}/>
                ))
            }
        </div>
    )
}

export default AudioRecurso