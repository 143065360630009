import {
    GET_LIKED_RECURSOS,
    GET_LIKED_RECURSOS_SUCCESS,
    GET_LIKED_RECURSOS_FAILURE,
    GET_CLASSROOMS_ROLES,
    GET_CLASSROOMS_ROLES_SUCCESS,
    GET_CLASSROOMS_ROLES_FAILURE,
} from '../types';
import { UserTypes } from '../constants/types';

const INIT_STATE = {
    user: null,
    roles: null,
    likes: null,
    loggedIn: false,
    menu: [],
    loading: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UserTypes.LOADING:
            return {
                ...state,
                loading: true
            }
        case UserTypes.NOT_LOADING:
            return {
                ...state,
                loading: false
            }
        case UserTypes.FETCH:
            return {
                ...state,
                loggedIn: false
            }
        case UserTypes.FETCH_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        case UserTypes.FETCH_FAILURE:
            return {
                ...state,
                loggedIn: false,
                user: {}
            }
        case UserTypes.SET_MENU:
            return {
                ...state,
                menu: action.payload
            }
        case GET_CLASSROOMS_ROLES:
            return {
                ...state,
                loading: true
            }
        case GET_CLASSROOMS_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                loading: false
            }
        case GET_CLASSROOMS_ROLES_FAILURE:
            return {
                ...state,
                roles: action.payload,
                loading: false
            }
        case GET_LIKED_RECURSOS:
            return {
                ...state,
                loading: true
            }
        case GET_LIKED_RECURSOS_SUCCESS:
            return {
                ...state,
                likes: action.payload,
                loading: false
            }
        case GET_LIKED_RECURSOS_FAILURE:
            return {
                ...state,
                loading: false
            }

        case 'FAVORITOS':
            const { likes, folderId } = action.payload;
            const newState = { ...state, likes };
            const resourceIds = likes.map((like) => like.resource_id);

            if(folderId === 0)
            {
                newState.user.folderMisFavoritos.resources = resourceIds;
            }
            else
            {
                newState.user.likeFolders.find((lf) => +lf.id === +folderId).resources = resourceIds;
            }
            return newState;

        case 'FOLDERS':
        return {
            ...state,
            user: {
                ...state.user,
                likeFolders:action.payload
            }
        }
        default:
            return { ...state }
    }
}
