import React from "react";
import { Collapse } from 'reactstrap';
import downloadAsTxt from "src/util/downloadAsTxt";
import DownloadTranscript from 'src/assets/images/boton-descarga-transcripcion-icono-negro.svg';

export default function Transcripcion(props) {

    return (
        <Collapse isOpen={props.transcrIsOpen}>
            {(props.transcription)
            ?
                <>
                    <div className='podcast-transcript'>
                        <div className="main">
                            <h5 className="mb-3 mt-2"> Transcripción </h5>
                            <p className="hyphenate">{props.transcription}</p>
                        </div>
                    </div>
                    <div
                        className='downl-transcr-contain'

                    >
                        {props.transcription != "" &&
                        <div
                            className="downl-transcr-btn"
                            onClick={() => downloadAsTxt("transcripcion", props.transcription)}
                        >
                            <img src={DownloadTranscript}></img>
                        </div>}
                    </div>
                </>
            :
                <p className="no-transcr">No existe transcripción para este episodio.</p>
            }
        </Collapse>
    )
}



