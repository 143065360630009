import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import REATooltip from 'src/components/shared/REATooltip';
import ReactHtmlParser from 'html-react-parser';

import miniatura from 'src/assets/images/recursos/img-5.jpg';
import PinFeatured from './shared/PinFeatured';
import { RESOURCE_TYPES } from 'src/util/constantes';
import { LikeResourceButton } from './actions/like/LikeResourceButton';
// import ResourceActionBoxDetalle from '../detalle/ResourceActionBoxDetalle';

const LinkBusqueda = (props) => {
    const { categoria, id, history } = props;
    const location = useLocation();

    return <p
        className="tjt-propuesta"
        onClick={() =>
            id && history.push(
                location.search.length === 0 ? `?${categoria}=${id}` : `${location.search}&${categoria}=${id}`
            )
        }
    >
        {props.children ?? "Sin información"}
    </p>
}

const ResourceCard = ({ resource: recurso, ...props }) => {
    const history = useHistory();
    // const authReducer = useSelector((state) => state.authReducer);

    const rutaRecurso = (recurso) => {
        if (window.location.pathname == "/publicaciones") {
            return "publicaciones"
        } else {
            let ruta = recurso.tags.format[0].description
            return ruta === 'Colección' ? 'colecciones' : 'recurso'
        }
    }

    const propuestaPedagogica = (recurso) => {
        const propuesta = (recurso.tags.pedagogic_proposal??[])[0];

        return (
            <LinkBusqueda
                categoria="pedagogic_proposals"
                id={propuesta?.id}
                history={history}
            >
                {propuesta?.description}
            </LinkBusqueda>
        );
    }

    const recursoTipo = (recurso) => {
        const tipoRecurso = (recurso.tags.resource_type??[])[0];

        return (
            <LinkBusqueda
                categoria="resource_types"
                id={tipoRecurso?.id}
                history={history}
            >
                {tipoRecurso?.description}
            </LinkBusqueda>
        );
    }

    const resource_type = RESOURCE_TYPES[rutaRecurso(recurso).toUpperCase()] ?? 1;

    const getTags = (recurso) => {
        const rutaSeccion = window.location.pathname;
        return (
            recurso.tags.tags.map((element, index)=>{
                return(
                    <span
                        key={index}
                        className={`badge-tag-${recurso.resource_id} tjt-etiqueta-item`}
                        style={{ marginRight: "0.3rem", whiteSpace:"normal" }}
                        onClick={(e) => history.push(`${rutaSeccion}?tag_resources=${element.id}`)}
                    >
                        #{element.description}
                    </span>
                )
            })
        )
    }

    useEffect(() => {
        const collection = document.getElementsByClassName("texto-truncate");

        for (let ele of collection) {
            for (let index = 0; index < ele.children.length; index++) {
                if(index > 0 ){
                    ele.children[index].style="display:none"
                }
            }
        }
    }, []);

    return (
        <div name="recursosListados" className="recursos-items" style={{marginBottom:"10px"}}>
            <div className="container-tjt">
                {recurso.featured === 1 && <PinFeatured />}
                <div className="tjt-img-container">
                    {
                        recurso.photo !== "" ?
                            <img alt="imagen del recurso" src={recurso.photo} className="tjt-img"></img>
                        :   <img alt="imagen del recurso" src={miniatura} className="tjt-img"></img>
                    }
                </div>
                <div className="tjt-cuerpo">
                    { propuestaPedagogica(recurso) }
                    <div className="tjt-titulos">
                        <Link to={`/${rutaRecurso(recurso)}/${recurso.resource_id}`} id={`tjt-titulo-${recurso.resource_id}`} className="tjt-link">
                            <h3 className="tjt-title">
                            { ReactHtmlParser(recurso.title) }      
                            </h3>
                        </Link>
                        <REATooltip placement="right" target={`tjt-titulo-${recurso.resource_id}`} >
                            <div className="notMarginButton">{ ReactHtmlParser(recurso.title) }</div>
                        </REATooltip>
                        { recursoTipo(recurso) }
                    </div>
                    <div className="tjt-etiqueta" id={`tjt-etiqueta-${recurso.resource_id}`}>
                        <div className='tjt-etiqueta-container'>
                            { getTags(recurso) }
                        </div>
                    </div>
                    <div id={`tjt-copete-${recurso.resource_id}`} className="tjt-copete texto-truncate">
                        { ReactHtmlParser(recurso.subtitle) }
                    </div>
                    <REATooltip placement="right" target={`tjt-copete-${recurso.resource_id}`} >
                        <div className="notMarginButton">{ ReactHtmlParser(recurso.subtitle) }</div>
                    </REATooltip>
                    <div className="tjt-iconos">
                        <LikeResourceButton
                            resourceId={recurso.resource_id}
                            resourceType={resource_type}
                        />
                        {/* <ResourceActionBoxDetalle recurso={recurso} urlRecurso={`${window.location.origin}/recurso/${recurso.resource_id}`} authReducer={authReducer}/> */}
                    </div>
                </div>
            </div>
            {/* <div name="recursoBody" className="thumbnail card body-resource-height" style={{width:"100%"}}>


                

                <div className="card-header" style={{borderBottom: "0px"}}>
                    <SubMenu
                    show={show}
                    idRecurso={resource.resource_id}
                    history={history}
                    location={location}
                    />
                </div>
                <Link to={`/recurso/${resource.resource_id}`} id={`REATooltipExample-${resource.resource_id}`}>

                <div className="img-event">
                    <img className="group list-group-image img-fluid card-img-top" src={getDefaultImage(recurso)} alt="" style={{height: "inherit"}}/>

                        <IconRecurso format={resource.tags.format[0].id} claseIcon="icono" />
                </div>
                </Link>

                <div name="cardRecurso" className="caption card-body h-100">
                    <div className="overflow-header" style={{WebkitLineClamp:"2", textOverflow:"ellipsis", paddingRight:"1rem", height:"auto"}}>
                        <Link to={`/recurso/${resource.resource_id}`} id={`REATooltipExample-${resource.resource_id}`} className="recurso-title-link">
                            <div className="h5 group card-title inner list-group-item-heading mb-0">
                                { ReactHtmlParser(resource.title) }
                            </div>
                        </Link>
                        <REATooltip placement="right" target={`REATooltipExample-${resource.resource_id}`} >
                        <div className="notMarginButton">{ ReactHtmlParser(resource.title) }</div>
                        </REATooltip>
                    </div>
                    <small><strong style={{fontSize: '15px'}}>{(resource.tags.owners && resource.tags.owners.length > 0 ) ? resource.tags.owners[0].description: ''}</strong></small>
                    <div name="recursoBodyTexto" id={`REATooltipSubtitle-${resource.resource_id}`} className="group inner list-group-item-text texto-truncate">{ ReactHtmlParser(resource.subtitle) }</div>
                    <REATooltip placement="right" target={`REATooltipSubtitle-${resource.resource_id}`} >
                        <div className="notMarginButton">{ ReactHtmlParser(resource.subtitle) }</div>
                    </REATooltip>
                </div>
                <div className="card-footer" style={{marginTop: "-42px"}}>
                        <div className="col-md-12 mt-1">
                            <ResourceActionBoxDetalle recurso={recurso} urlRecurso={`${window.location.origin}/recurso/${resource.resource_id}`} authReducer={authReducer}/>
                        </div>
                </div>

                <div className="" style={{marginTop: "-42px"}}>
                    <div className="row mt-0 mb-1">
                        <div className={"col-md-12"}>

                            #{ 
                            getTags(recurso)
                            }
                            <div style={{with: "100%", textAlign: "center"}}>
                                <span onClick={(e) => {showOrHideTags('show', resource.resource_id)}} className={`showTags_${resource.resource_id}`} style={{cursor: "pointer", display: "none"}}>Ver mas...</span>
                                <span onClick={(e) => {showOrHideTags('hide', resource.resource_id)}} className={`d-none hideTags_${resource.resource_id}`} style={{cursor: "pointer"}}>Ver menos...</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default ResourceCard