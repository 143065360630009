import React, {useState} from "react";
import { Collapse } from 'reactstrap';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Episodio from "./Episode";

export default function SeasonNav({season}) {

    const [isExpanded, setIsExpanded] = useState(false);
    const toggle = () => setIsExpanded(!isExpanded);

    return (
    <>
        <div className={isExpanded ? "podcast-season active" : "podcast-season" }>
            <strong className='font-weight-bolder'>{season.title}</strong>
            <KeyboardArrowDownIcon
                onClick={toggle}
                className={isExpanded ? "season-arrow active" : "season-arrow"}
            />
        </div>
        <Collapse isOpen={isExpanded} >
            {season.episodes.map(
                (episode, i) => episode.resource.attachments[0] && <Episodio key={episode.id} episode={episode} orden={i+1} />
            )}
        </Collapse>
    </>
    )

}