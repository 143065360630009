import React, { useState } from 'react';
import FullscreenIcon from 'src/assets/images/JM_iconos-06_pantalla-compl.svg';
import GetAppIcon from 'src/assets/images/JM_iconos-07_descargar.svg';
import { INTERACTIVO, LIBRO_ELECTRONICO } from 'src/util/constantes';
import Aulas from '../../detalle/Aulas'
import { ROLES_AULAS } from 'src/util/constantes';
import { LikeResourceButton } from 'src/components/recursos/resources/actions/like/LikeResourceButton';
import { ShareResourceButton } from './share/ShareResourceButton';
import { ApiResourceButton } from './api/ApiResourceButton';
import { ResourceActionSeparator } from './ResourceActionSeparator';
import { ResourceActionBox } from './ResourceActionBox';
import { DownloadImageGalleryResourceButton } from './download/DownloadImageGalleryResourceButton';
import { DownloadDocumentAttachmentButton } from './DownloadDocumentAttachmentButton';
// import { saveAs } from 'file-saver';
// let zip = require('jszip')();

const ResourceActionBoxDetalle = (props) =>
{
  const { urlRecurso, recurso, authReducer, view } = props;
  const { resource_id } = recurso;
  const { user } = authReducer;
  
  const [files, setFiles] = useState([]);

  let urlPortalSplitted = (process.env.REACT_APP_BASE_URL).split('/');
  urlPortalSplitted = urlPortalSplitted.splice(0,urlPortalSplitted.length - 2);
  const urlPortalAPI = (urlPortalSplitted).join('/');
  const rolesPizarron = [ROLES_AULAS['Docente'], ROLES_AULAS['Docente sin aula']];

  const [isFullScreen, setIsFullScreen] = useState(document.fullscreenElement);

  document.addEventListener("fullscreenchange", function (event) {
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  });

  const vistaOk = () => {
    let url = process.env.REACT_APP_CALLBACK_OAUTH_MIS_DATOS;
    // console.log(url);
    if(window.location.href === url){
      return true;
    }

  }

  const fullScreen = (id = null) => {
    isFullScreen ? closeFullscreen() : openFullscreen(id);
  }

  const openFullscreen = (id = null) => {
    var elem = id ? document.getElementById(id) : document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  // const convertirBase64 = ( archivos ) => {
  //   let arrayArchivos = [];
  //   Array.from( archivos ).forEach( archivo => {
  //     let reader = new FileReader();
  //     reader.readAsDataURL( archivo );
  //     reader.onload = function() {
  //       let arrayAux = [];
  //       let base64 = reader.result;
  //       arrayAux = base64.split(',');
  //       arrayArchivos.push({base64: arrayAux[1], nombre: archivo.name})
  //     }
  //   })
  //   setFiles({...files, arrayArchivos});
  // }

  // const exportarZip = () => {
  //   const { arrayArchivos } = files;
  //   arrayArchivos.map( archivo => {
  //     zip.file( archivo.nombre, archivo.base64, {base64: true} );
  //   });
  //   zip.generateAsync( {Type: 'blob'} ).then(function(content){
  //     saveAs(content, "Galeria-Imagenes.zip")
  //   });
  //   zip = require('jszip')();
  // }

  return (
<>
    { vistaOk()?
    <>
        <LikeResourceButton
            resourceId={recurso.resource_id}
            resourceType={recurso.resource_type_id === 2 ? 2 : 1}
        />
    </>
    :
    <ResourceActionBox>

        { /* Like */ }
        <LikeResourceButton
            resourceId={recurso.resource_id}
            resourceType={recurso.resource_type_id === 2 ? 2 : 1}
        />

        { /* Share */ }
        {window.location.pathname !== '/recursos' &&
            <ShareResourceButton view={view} resourceId={recurso.resource_id}/>
        }
          {
            (
              process.env.REACT_APP_DEBUG_CLASSROOM === "true" ||
              (
                process.env.REACT_APP_PIZZARRON_ENABLED === "true" &&
                authReducer.user_logued && 
                authReducer.roles && 
                rolesPizarron.some((role) => authReducer.roles.includes(role)) && 
                window.location.href === `${window.location.origin}/recurso/${recurso.resource_id}`
              ) 
            ) && process.env.REACT_APP_PIZZARRON_ENABLED === "true" &&
            <Aulas recurso={recurso} title={recurso.title} urlRecurso={urlRecurso} idRecurso={recurso.resource_id} loggedIn={Boolean(authReducer.user_logued)} roles={authReducer.roles} />
          }

        { /* Separator */ }
        { view === 'detail' && <ResourceActionSeparator/> }

          {
            recurso.attachments &&
            recurso.attachments.length > 0 &&
            recurso.attachments[0].video_attachment_path &&
            false &&
              <a
                  href={recurso.attachments[0].video_attachment_path}
                  download={recurso.display_title}
                  className="svg-container--attachment"
              >
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns_xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 225 182" xml_space="preserve" className='svg-img'>
                  <g>
                    <path d="M175.1,174H55.9C43.3,174,33,163.7,33,151.1V130c0-2.8,2.2-5,5-5s5,2.2,5,5v21.1c0,7.1,5.8,12.9,12.9,12.9H175
                      c7.1,0,12.9-5.8,12.9-12.9V130c0-2.8,2.2-5,5-5s5,2.2,5,5v21.1C198,163.7,187.7,174,175.1,174z"/>
                  </g>
                  <g>
                    <path d="M115,156c-1.4,0-2.7-0.6-3.7-1.6L59,98.4c-1.4-1.4-1.7-3.6-1-5.4c0.8-1.8,2.6-3,4.6-3h21.6V39c0-2.8,2.2-5,5-5h51.4
                      c2.8,0,5,2.2,5,5v51h20.7c2,0,3.8,1.2,4.6,3s0.4,3.9-0.9,5.4l-51.4,56C117.7,155.4,116.4,156,115,156L115,156z M74.1,100l40.8,43.6
                      L155,100h-14.3c-2.8,0-5-2.2-5-5V44H94.3v51c0,2.8-2.2,5-5,5H74.1z"/>
                  </g>
                </svg>
              </a>
          }

          {
            (recurso.resources_format_id === INTERACTIVO && props.hasAttachments) && (
              <>
                <img 
                  src={FullscreenIcon} 
                  onClick={(event) => {
                    event.preventDefault();
                    fullScreen('iframeInteractivo');
                  }}
                  className={view !== 'detail' ? "icon-bar" : "icon-bar detail"}
                  style={{ cursor: 'pointer' }}
                  width="24px" height="24px"
                  title="Ir a pantalla completa" alt="Ir a pantalla completa"
                />
                <a
                  href={recurso.embebedFileURL}
                  download={recurso.display_title}
                  className={view !== 'detail' ? "icon-bar" : "icon-bar detail"}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Descargar"
                >
                  <img src={GetAppIcon} alt= "Descargar" width="24px" height="24px" />
                </a>
              </>
            )
          }

          {
            (recurso.resources_format_id === LIBRO_ELECTRONICO && props.hasAttachments) &&
            <>
              <img
                src={FullscreenIcon}
                onClick={(event) => {
                  event.preventDefault();
                  fullScreen('libroRecursoId');
                }} style={{ cursor: 'pointer' }}
                className={view !== 'detail' ? "icon-bar" : "icon-bar detail"}
                width="24px" height="24px"
                title="Ir a pantalla completa" alt="Ir a pantalla completa"
              />
              <a
                href={recurso.attachments.length > 0 ? recurso.attachments[0].url : '#REA'}
                download={recurso.display_title}
                className={view !== 'detail' ? "icon-bar" : "icon-bar detail"}
                target="_blank"
                rel="noopener noreferrer"
                title="Descargar"
              >
                <img src={GetAppIcon} alt="Descargar" width="24px" height="24px" />
              </a>
            </>
          }

        { /* Download image gallery */ }
        <DownloadImageGalleryResourceButton view={view} resource={recurso} hasAttachments={props.hasAttachments}/>

        { /* Documento adjunto */ }
        <DownloadDocumentAttachmentButton view={view} resource={recurso}/>

        { /* Resource api */ }
        <ApiResourceButton view={view} resourceId={recurso.resource_id}/>

    </ResourceActionBox>
    }
</>
  );
}

export default ResourceActionBoxDetalle;