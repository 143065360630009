
import axios from 'axios';
import { json as headersRea } from '../config/headers';
import { errorResponseHandler } from '../config/handlers';

const axiosInstance = axios.create({
   baseURL: `${process.env.REACT_APP_BASE_URL_API}/api/rea`,
   timeout: 60000,
   headers: headersRea,
   withCredentials: true
});

axiosInstance.interceptors.request.use(
   request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
) 

const requestHandler = (request) => {
   return request;
}

const successHandler = (response) => response;
const errorHandler = errorResponseHandler;

export default axiosInstance;
