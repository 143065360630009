import React from "react";
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useMisRecursos } from "../MisRecursosContext";

export const MisRecursosMoveLikeDialog = ({
    modalOpen,
    modalToggle,
    resource,
    addRecursoToFolder,
}) =>
{
    const {
        allFolders,
    } = useMisRecursos();

    const [ currentOption, setCurrentOption ] = React.useState("");

    React.useEffect(() =>
    {
        setCurrentOption("");
    }, [modalOpen]);

    const agregarRecursoALista=()=>
    {
        addRecursoToFolder(resource, currentOption);
    }

    const filteredFolders = allFolders.filter((folder) =>
    {
        if(!folder.resources.some(like => like === resource.resource_id))
        {
            return true;
        }
        return false;
    });

    return (
<>
<Modal isOpen={modalOpen} toggle={modalToggle}>
    <ModalBody style={{padding:"2rem 2rem"}}>
        <h3 className="mb-3 modal-title">MOVER RECURSO</h3>
        <label className="attachedLabel">Nombre de lista</label>
        <Input
            type="select"
            name="select"
            placeholder="Seleccione una lista..."
            style={{height:'auto'}}
            value={currentOption}
            onChange={(e)=>
            {
                setCurrentOption(e.target.value);
            }}
        >
            <option disabled value="">Seleccione una lista...</option>
            {
                filteredFolders.map((folder) =>
                {
                    return <option key={folder.id} value={folder.id}>{folder.title.length > 30 ? `${folder.title.slice(0,30)}...` : folder.title}</option>
                })
            }
        </Input>
        <span style={{ fontSize:12 }}>*Si no ves listas, es porque este recurso ya existe en todas</span>
    </ModalBody>
    <ModalFooter>
        <Button
            color='secondary'
            onClick={modalToggle}
        >
            Cancelar
        </Button>
        <Button
            disabled={currentOption === ""}
            color='primary'
            className="btn-conectar-igualdad"
            onClick={()=>
            {
                if(currentOption !== "")
                {
                    agregarRecursoALista()
                    modalToggle()
                }
            }}
        >
            Mover
        </Button>
    </ModalFooter>
</Modal>
</>
    )
}
