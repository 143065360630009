import React from "react";
import { Link } from "react-router-dom";

const routesToLabels = {
    "/": "Inicio",
    "/recursos": "Recursos",
    "/publicaciones": "Publicaciones"
};

const Breadcrumb2 = ({currentPosition, previousPositions}) =>
{
    return (
<nav aria-label="breadcrumb" id="breadCrumb">
    <ol className="breadcrumb px-0">
        { previousPositions.map((position) => {
            if(position === "/home") position = "/";
            return (
            <li key={position} className="breadcrumb-item">
                <Link to={position}>
                    { routesToLabels[position] }
                </Link>
            </li>
        )}) }
        <li className="breadcrumb-item active" aria-current="page">
            { routesToLabels.hasOwnProperty(currentPosition) 
                ? routesToLabels[currentPosition]
                : currentPosition
            }
        </li>
    </ol>
</nav>
    )
}
export default Breadcrumb2;
