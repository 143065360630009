import React, { useState, useEffect } from 'react';
// import { NotificationManager } from 'react-notifications';

import { capitalize } from '@material-ui/core';

import { MoodleService } from "src/axios";
import { ROLES_AULAS } from 'src/util/constantes';

import iconModdle from '../../../assets/images/JM_iconos-04_aula.svg'
import ModalMoodle from './aulas/ModalMoodle';
import Aula from './aulas/Aula';

const Pizarron = ({ onClick }) => {
    return (
        <a
            href="#REA"
            title="Agregar a las Aulas Virtuales"
            className="icon-bar detail"
            onClick={onClick}
        >
            <img src={iconModdle} alt="Moddle icon" width="24px" height="24px" />
        </a>
    )
}


const Aulas = ({ idRecurso, title, urlRecurso, loggedIn, recurso, roles }) => {
    const { display_title: recursoTitle } = recurso;

    const [modalOpen, setModalOpen] = useState(false);
    const modalToggle = () => setModalOpen(!modalOpen);

    const [courses, setCourses] = useState(null);
    const [error, setError] = useState(false);
    const isDocenteConAulas = process.env.REACT_APP_DEBUG_CLASSROOM === "docente" ? true : roles && roles.includes(ROLES_AULAS['Docente']);

    useEffect(() => {
        if(isDocenteConAulas) {
            MoodleService.getClassroomUser()
                .then(response => response.data)
                .then(function(courses) {
                    if(Object.values(courses).some(courses => courses && courses.aulas.length > 0))
                        setCourses(courses);
                    else
                        setError(true);
                })
                .catch(function(error){
                    setError(true);
                });
        }
    }, [isDocenteConAulas]);

    function handleClick(event) {
        event.preventDefault();
        modalToggle();
/*         if (!(loggedIn)) {
            NotificationManager.warning("Debes estar registrado y/o logueado para agregar a favoritos")
        } else {
            modalToggle();
        } */
    }

    return (
        <React.Fragment>
            <Pizarron
                onClick={handleClick}
            />
            <ModalMoodle
                error={error}
                conAulas={isDocenteConAulas}
                loading={!Boolean(courses)}
                recursoTitle={recursoTitle}
                modalOpen={modalOpen}
                modalToggle={modalToggle}
            >
                <ul className="list-group">
                    {
                        Boolean(courses) &&
                        Object.entries(courses)
                        .reduce(
                            (carry, [nivelName, nivel]) => (
                                [...carry, nivel ? nivel.aulas.map((aula) => ({...aula, nivel: nivelName, title: `${capitalize(nivelName)} - ${aula.title}`})) : []]
                            ), [])
                        .reduce((carry, aula) => [...carry, ...Object.values(aula)], [])
                        .map((aula) => {
                            return (
                                <li key={aula.id} className="list-group-item">
                                    <Aula
                                        idRecurso={idRecurso}
                                        titleRecurso={recursoTitle}
                                        urlRecurso={urlRecurso}
                                        copete={recurso.subtitle}
                                        aula={aula}
                                        type={aula.nivel}
                                        modalToggle={modalToggle}
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
            </ModalMoodle>
        </React.Fragment>
    )
}

export default Aulas
