import React from "react";
import GetAppIcon from 'src/assets/images/JM_iconos-07_descargar.svg';

export const DownloadDocumentAttachmentButton = ({view, resource}) =>
{
    if(resource.download_document_attachment_url)
    {
        return (
            <a
                href={resource.download_document_attachment_url}
                download={resource.display_title}
                className={view !== 'detail' ? "icon-bar" : "icon-bar detail"}
                rel="noopener noreferrer"
                title="Descargar adjunto"
            >
                <img src={GetAppIcon} alt="Descargar" width="28px" height="28px" />
            </a>
        )
    }

    return <></>
}
