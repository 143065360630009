import React from "react";
import { PopoverCard } from "./PopoverCard";

export const PopoverCard1 = () =>
{
    return (
<PopoverCard name="Popover1">
    &#8226; Agregá nuevas listas con el <i className="fa fa fa-plus-square-o ml-2"/><br />
    &#8226; Hacé doble click sobre el nombre para modificarlo.<br />
    &#8226; Arrastrá una lista hacia arriba o hacia abajo para modificar su orden.<br />
    &#8226; Eliminá una lista con la x<br /><br />
</PopoverCard>
    )
}
