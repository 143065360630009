import React from "react";
import Pagination from "react-js-pagination";
import BarraBusqueda from "src/components/recursos/barraBusqueda";
import FiltrosAplicadosRecursos from "src/components/recursos/filtrosAplicados";
import ListadoRecursos from "src/components/recursos/listadoRecursos";
import Loading from "src/components/shared/loading";
import Breadcrumb2 from "../shared/Breadcrumb2";
import { useGenericSideBar } from "../sidebar/GenericSideBar";

export function VistaRecursosListaContent({
    loading,
    match,
    history,
    location,
    recursos,
    authReducer,
    handleGetRecursos,
    breadcrumbCurrent,
    breadcrumbPrevious,
    renderSearchInput,
})
{
    const { handlers } = useGenericSideBar();

    if(loading)
        return <Loading fullWidth={false} />

    return (
<>
<div {...handlers}>
    <Breadcrumb2
        currentPosition={breadcrumbCurrent}
        previousPositions={breadcrumbPrevious}
    />
    { renderSearchInput &&
    <BarraBusqueda
        location={location}
        history={history}
        detalle={true}
    />}
    <FiltrosAplicadosRecursos
        filtros={recursos.filters}
        history={history}
        location={location}
        match={match}
    />
    <ListadoRecursos
        recursos={recursos.data}
        authReducer={authReducer}
        history={history}
        location={location}
        match={match}
    />
    {
        recursos.data ?
            <Pagination
                activePage={recursos.current_page}
                itemsCountPerPage={Number(recursos.per_page)}
                totalItemsCount={recursos.total}
                onChange={(value) => {
                    handleGetRecursos(value, location.search)
                }}
                itemClass="page-item"
                linkClass="page-link"
            />
            :
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <p>No se han encontrado resultados para su búsqueda</p>
            </div>
    }
</div>
</>
    )
}
