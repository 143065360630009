import React from 'react';
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <a className="carousel-control-next" onClick={onClick} href="#REA">
            <span className="carousel-control-next-icon dark" aria-hidden="true"
                style={{ width: "80%" }}></span>
            <span className="sr-only">Next</span>
        </a>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <a className="carousel-control-prev" href="#REA" role="button"
            data-slide="prev" onClick={onClick}>
            <span className="carousel-control-prev-icon dark" aria-hidden="true"
                style={{ width: "80%" }}></span>
            <span className="sr-only">Previous</span>
        </a>
    );
}

const GaleriaRecurso = (props) => {
    const { images } = props
    const sortedImages = images.sort((a, b) =>
    {
        return a.order - b.order;
    });
    return (
        <Slider
            className='carousel-galeria'
            dots={true}
            infinite={true}
            speed={600}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={false}
            arrows={true}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            adaptiveHeight={true}
        >
            {
                sortedImages.map((image) => {
                    return (
                        <div className="item aux-class" style={{ position: "relative!important" }} key={image.id}>
                            <img key={image.id} src={image.url} className="img-fluid ml-auto mr-auto" alt="" />
                            {
                                (image.title || image.description) &&
                                <div className="carousel-title-description">
                                    <h5>{image.title && image.title}</h5>
                                    <h6>{image.description && image.description}</h6>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </Slider>
    )
}

export default GaleriaRecurso