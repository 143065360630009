export const attachEvent = (obj, evt, callback = () => {}) => {
    if (obj.addEventListener) {
        obj.addEventListener(evt, callback, false);
    } else if (obj.attachEvent) {
        obj.attachEvent('on' + evt, callback)
    }
}

export const removeEvent = (obj, evt, callback = () => {}) => {
    if (obj.addEventListener) {
        obj.addEventListener(evt, callback, false);
    } else if (obj.attachEvent) {
        obj.removeEventListener('on' + evt, callback)
    }
}
