import React from "react";

export default function AudioPlayerControlsProgressTime({duration, currentTime})
{
    const calculateTime = (secs) =>
    {
        if(isNaN(secs))
            secs = 0;

        const minutes = Math.floor(secs/60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    }

    return (
<div className="audioplayer-time">
    <div className="audioplayer-time-current">{ calculateTime(currentTime) }</div>
    <div className="audioplayer-time-duration">{ calculateTime(duration) }</div>
</div>
    )
}
