import React from 'react';

import AudioPlayerButtonDownloadTranscription from './audio-player/AudioPlayerButtonDownloadTranscription';
import AudioPlayerButtonDownloadAudio from './audio-player/AudioPlayerButtonDownloadAudio';
import AudioPlayerButtonExpandCollapseTranscription from './audio-player/AudioPlayerButtonExpandCollapseTranscription';
import AudioPlayerControlsPlayButton from './audio-player/AudioPlayerControlsPlayButton';
import AudioPlayerControlsVolume from './audio-player/AudioPlayerControlsVolume';
import AudioPlayerControlsProgressBar from './audio-player/AudioPlayerControlsProgressBar';
import AudioPlayerControlsProgressTime from './audio-player/AudioPlayerControlsProgressTime';

const AudioPlayer = ({ audio }) =>
{
    const [canPlay, setCanPlay] = React.useState(false);
    const [duration, setDuration] = React.useState(0);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [currentVolume, setCurrentVolume] = React.useState(1);
    const [transcriptionOpen, setTranscriptionOpen] = React.useState(false);

    const playingRef = React.useRef(false);
    const audioPlayerRef = React.useRef();
    const progressBarRef = React.useRef();
    const progressBarButton = React.useRef();
    const volumeBarRef = React.useRef();
    const volumeBarButtonRef = React.useRef();
    const animationRef = React.useRef();
    const audioClassStates = React.useRef();

    React.useEffect(() =>
    {
        if(duration > 0)
            setCanPlay(true);
    }, [duration, setCanPlay]);

    const playAudio = (event, audio_id) =>
    {
        event.preventDefault();
        if(!canPlay)
            return;

        const btnPlay = document.getElementById(`btn-play-${audio_id}`)
        btnPlay.classList.toggle('audioplayer-playing')

        //var isPlaying = audioPlayerRef.current.currentTime > 0 && !audioPlayerRef.current.paused && !audioPlayerRef.current.ended && audioPlayerRef.current.readyState > audioPlayerRef.current.HAVE_CURRENT_DATA;
        //if(!isPlaying)
        if(!playingRef.current)
        {
            if(audioPlayerRef.current.ended)
            {
                const newCurrentTime = 0;
                audioPlayerRef.current.currentTime = newCurrentTime;
                progressBarButton.current.value = newCurrentTime;
                progressBarRef.current.style.setProperty('width', `${newCurrentTime / duration * 100}%`);
                setCurrentTime(newCurrentTime);
            }

            setTimeout(() =>
            {
                audioPlayerRef.current.play().then(() =>
                {
                })
                .catch((error) =>
                {
                    console.error(error);
                });
            }, 0);
            animationRef.current = requestAnimationFrame(whilePlaying);
        }
        else
        {
            audioPlayerRef.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }

    const whilePlaying = () =>
    {
        if(progressBarButton.current.value == duration)
        {
            audioPlayerRef.current.pause();
            cancelAnimationFrame(animationRef.current);
            audioClassStates.current.classList.remove('audioplayer-playing');
        }
        else
        {
            progressBarRef.current.value = audioPlayerRef.current.currentTime;
            setCurrentTime(progressBarRef.current.value);
            progressBarRef.current.style.setProperty('width', `${progressBarRef.current.value / duration * 100}%`);
            progressBarButton.current.value = progressBarRef.current.value;
            animationRef.current = requestAnimationFrame(whilePlaying);
        }
    }

    const changeRange = () =>
    {
        audioPlayerRef.current.currentTime = progressBarButton.current.value;
        progressBarRef.current.style.setProperty('width', `${progressBarButton.current.value / duration * 100}%`);
        setCurrentTime(progressBarButton.current.value);
    }

    const muteVolume = (event, audio_id) =>
    {
        event.preventDefault();
        setCurrentVolume(audioPlayerRef.current.volume * 100);
        audioPlayerRef.current.muted = !audioPlayerRef.current.muted;
        const btnPlay = document.getElementById(`btn-play-${audio_id}`);
        if(audioPlayerRef.current.muted)
        {
            btnPlay.classList.add('audioplayer-mute');
            volumeBarRef.current.style.setProperty('width', '0%');
        }
        else
        {
            btnPlay.classList.remove('audioplayer-mute')
            volumeBarRef.current.style.setProperty('width', `${currentVolume}%`);
        }
    }

    const volumeControl = (event, audio_id) =>
    {
        event.preventDefault();
        audioPlayerRef.current.volume = volumeBarButtonRef.current.value / 100;
        volumeBarRef.current.style.setProperty('width', `${volumeBarButtonRef.current.value}%`);
        if(audioPlayerRef.current.volume == 0)
        {
            audioClassStates.current.classList.add('audioplayer-mute')
        }
        else
        {
            audioClassStates.current.classList.remove('audioplayer-mute')
        }
    }

    return (
        <React.Fragment>
            <div className="col-12 reproductor-panel">
                <p className="ml-2" style={{ margin: 0, fontFamily: "Encode Sans", fontWeight: 500}}>{audio.description}</p>
                <div className='audio-btn-container'>
                    <AudioPlayerButtonDownloadAudio audio={audio}/>
                    <AudioPlayerButtonExpandCollapseTranscription
                        audio={audio}
                        onToggleTranscription={e => setTranscriptionOpen(prev => !prev)}
                    />
                </div>
            </div>

            <div className="col-12 reproductor-container">
                <audio
                    ref={audioPlayerRef}
                    src={audio.url}
                    controlsList="sound"
                    controls
                    className="audio-responsive"

                    onPause={(e) =>
                    {
                        console.log("onPause");
                        console.log(e);
                        playingRef.current = false;
                    }}
                    onPlaying={(e) =>
                    {
                        console.log("onPlaying");
                        console.log(e);
                        playingRef.current = true;
                    }}
                    onLoadedMetadata={(e) =>
                    {
                        console.log("onLoadedMetadata");
                        console.log(e);
                        setDuration(e.target.duration);
                    }}>
                </audio>

                <div id={`btn-play-${audio.id}`} className={(() =>
                {
                    const className = [ "audioplayer" ];
                    if(!audioPlayerRef.current)
                        className.push("audioplayer-disabled");
                    return className.join(" ");
                })()} ref={ audioClassStates }>

                    <AudioPlayerControlsPlayButton
                        audioRef={audioPlayerRef}
                        onClick={(e) =>
                        {
                            playAudio(e, audio.id);
                        }}
                    />
                    <AudioPlayerControlsProgressBar
                        audioRef={audioPlayerRef}
                        progressBarRef={progressBarRef}
                        progressBarButton={progressBarButton}
                        max={duration}
                        changeRange={changeRange}
                    />
                    <AudioPlayerControlsProgressTime
                        duration={duration}
                        currentTime={currentTime}
                    />
                    <AudioPlayerControlsVolume
                        volumeBarRef={volumeBarRef}
                        volumeBarButtonRef={volumeBarButtonRef}
                        audio={audio}
                        muteVolume={muteVolume}
                        volumeControl={volumeControl}
                    />
                </div>

                <AudioPlayerButtonDownloadTranscription audio={audio} open={transcriptionOpen}/>
            </div>
        </React.Fragment>
    )
}

export default AudioPlayer
