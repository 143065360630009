import React from "react";
import CollectionResourceCard from "./CollectionResourceCard";

export default function CollectionResourceCards({ collection, resource_id })
{
    return (
<div className="noticias-grid col-12">
{
    Object.values(collection.component_grid).map((row, rowIndex) =>
    (
        <div key={rowIndex} className="row">
        {
            Object.values(row).map((card, columnIndex)=>
            {
                let columnClassName = null;
                switch(card.columns)
                {
                    case 1:
                        columnClassName = "mt-4 col-12 col-lg-3";
                        break;
                    case 2:
                        columnClassName = "mt-4 col-12 col-lg-6";
                        break;
                    case 3:
                        columnClassName = "mt-4 col-12 col-lg-9";
                        break;
                    case 4:
                        columnClassName = "mt-4 col-12 col-lg-12";
                        break;
                    default:
                        columnClassName = "mt-4 col-12";
                }
                return <div key={columnIndex} className={columnClassName}><CollectionResourceCard card={card} resource_id={resource_id} /></div>
            })
        }
        </div>
    ))
}
</div>
    )
}
