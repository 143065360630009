import {
    GET_RECURSOS,
    GET_RECURSOS_SUCCESS,
    GET_RECURSOS_FAILURE,
    GET_RECURSO_BY_ID,
    GET_RECURSO_BY_ID_SUCCESS,
    GET_RECURSO_BY_ID_FAILURE,
    GET_RECURSOS_COMPARTIDOS,
    GET_RECURSOS_COMPARTIDOS_SUCCESS,
    GET_RECURSOS_COMPARTIDOS_FAILURE,
    // LIKE_RECURSO,
    // LIKE_RECURSO_SUCCESS,
    // LIKE_RECURSO_FAILURE,
    GET_LIKED_RECURSOS,
    GET_LIKED_RECURSOS_SUCCESS,
    GET_LIKED_RECURSOS_FAILURE
} from '../types';
import { ResourcesService, MoodleService } from 'src/axios';
import { ResourceTypes } from '../constants/types';
// import { RESOURCE_TYPES } from 'src/util/constantes';

export const ActionCreators = {
    setLikes: (likes) => ({ type: ResourceTypes.LIKES, payload: likes }),
    setLikeFolders: (folders) => ({ type: ResourceTypes.LIKES, payload: folders }),
};

export const getAllRecursos = (page=1, filters="", rows=21) => async (dispatch) =>{
    dispatch({type: GET_RECURSOS})
    try {
        const query = new URLSearchParams({ page: page, rows: rows });
        const result = await ResourcesService.allResources((filters && filters.substring(1) + "&") + query.toString())
        dispatch({type: GET_RECURSOS_SUCCESS, payload:result.data})
    } catch (error) {
        dispatch({type: GET_RECURSOS_FAILURE})
        console.log("Error al obtener recursos: ", error)
    }
}

export const getAllRecursosDocentes = (page=1, filters="", rows=21) => async (dispatch) =>
{
    dispatch({type: GET_RECURSOS})
    try
    {
        const query = new URLSearchParams({ page: page, rows: rows, publicacion: true });
        const result = await ResourcesService.allResources((filters && filters.substring(1) + "&") + query.toString())
        result.data.filters.active = result.data.filters.active.filter((f) =>
        {
            return f.key !== "audiences";
        });
        dispatch({type: GET_RECURSOS_SUCCESS, payload: result.data})
    }
    catch (error)
    {
        dispatch({type: GET_RECURSOS_FAILURE})
        console.log("Error al obtener recursos: ", error)
    }
}

export const getRecursoById = (recursoId) => async (dispatch) =>{
    dispatch({type: GET_RECURSO_BY_ID})
    try {
        const result= await ResourcesService.oneResource(recursoId)
        
        dispatch({type: GET_RECURSO_BY_ID_SUCCESS, payload:result.data})
    } catch (error) {
        if(error.response && error.response.status === 404){
            const {data}=error.response
            data.recursoId=recursoId
            dispatch({type: GET_RECURSO_BY_ID_FAILURE, payload:data})
        }else{
            dispatch({type: GET_RECURSO_BY_ID_FAILURE, payload:null})
        }
        
        console.log("Error al obtener UN RECURSO: ", error)
    }
}

export const enviarLikedRecurso = (recursoId) => async dispatch => {
    // dispatch({type: LIKE_RECURSO})
    try {
        // const result = await RecursosService.enviarLikedRecurso(recursoId);
        // dispatch({
        //     type: LIKE_RECURSO_SUCCESS
        // })
    } catch(error) {
        // dispatch({
        //     type: LIKE_RECURSO_FAILURE
        // })
    }
}

export const getLikedRecursos = (likedResources) =>async   dispatch => {
    dispatch({type: GET_LIKED_RECURSOS})
    try {
        let recursos=[]
        for(let i=0; i < likedResources.length; i++){
            try {
                let result;
                switch (likedResources[i].resource_type_id) {
                    case 1:
                        result = await ResourcesService.oneResource(likedResources[i].resource_id);
                        break;
                    case 2:
                        result = await ResourcesService.oneCollection(likedResources[i].resource_id);
                        break;
                    default:
                        console.error('Tipo de recurso inválido', likedResources[i].resource_type_id);
                        break;
                }

                if (!result) {
                    continue;
                }

                result.data.id = result.data.resource_id
                recursos.push(result.data)
            } catch (error) {
                continue;
            }
        }

        dispatch({
            type: GET_LIKED_RECURSOS_SUCCESS,
            payload:recursos
        })
    } catch(error) {
        dispatch({
            type: GET_LIKED_RECURSOS_FAILURE
        })
    }
}

export const setFavoritos = (likes, folderId) => async (dispatch) =>
{
    dispatch({
        type:"FAVORITOS",
        payload: {
            likes,
            folderId,
        }
    });
}

export  const setFolders = (data) =>async   dispatch => {
    dispatch({type:"FOLDERS", payload:data})
}

const resourceType = {
    url: 'recurso',
    assign: 'tarea',
};

export const getRecursosCompartidos = () => async (dispatch) => {
    dispatch({type: GET_RECURSOS_COMPARTIDOS})
    try {

        const result = await MoodleService.getSharedResources();
        const all = Array.prototype.concat.apply([],
            Object.values(result.data)
            .map(({modtype, ...resource}) => (
                {type: resourceType[modtype], ...resource}
            ))
        );

        dispatch({
            type: GET_RECURSOS_COMPARTIDOS_SUCCESS,
            payload: all
        })
    } catch(error) {
        dispatch({
            type: GET_RECURSOS_COMPARTIDOS_FAILURE,
            payload: []
        })
    }
}
