import React from "react";
import { Link } from "react-router-dom";

export default function LinkToResourcesWithFilter({children, style, className, filters, filterName, filterValue})
{
    if(!filters)
        return <></>
    return <Link to={`/recursos?${filters[filterName]}=${filterValue}`} style={style} className={className}>
        { children }
    </Link>
}
