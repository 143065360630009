import {
    GET_RECURSOS,
    GET_RECURSOS_SUCCESS,
    GET_RECURSOS_FAILURE,
    GET_RECURSO_BY_ID,
    GET_RECURSO_BY_ID_SUCCESS,
    GET_RECURSO_BY_ID_FAILURE,
    GET_RECURSOS_COMPARTIDOS,
    GET_RECURSOS_COMPARTIDOS_SUCCESS,
    GET_RECURSOS_COMPARTIDOS_FAILURE,
    // LIKE_RECURSO_SUCCESS,
    // LIKE_RECURSO_FAILURE,
    // LIKE_RECURSO
} from '../types';

const INIT_STATE = {
    all: null,
    compartidos: null,
    detalle_recurso:null,
    error_detalle:null,
    loading:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_RECURSOS:
            return{
                ...state,
                detalle_recurso:null,
                loading: true
            }
        
        case GET_RECURSOS_SUCCESS:
            return{
                ...state,
                all: action.payload,
                loading: false
            }
        
        case GET_RECURSOS_FAILURE:
            return{
                ...state,
                loading: false
            }
        case GET_RECURSO_BY_ID:
            return{
                ...state,
                loading: true
            }
        
        case GET_RECURSO_BY_ID_SUCCESS:
            return{
                ...state,
                detalle_recurso: action.payload,
                loading: false
            }
            
        case GET_RECURSO_BY_ID_FAILURE:
            return{
                ...state,
                error_detalle:action.payload,
                loading: false
            }

        case GET_RECURSOS_COMPARTIDOS:
            return{
                ...state,
                loading: true
            }
        
        case GET_RECURSOS_COMPARTIDOS_SUCCESS:
            return{
                ...state,
                compartidos: action.payload,
                loading: false
            }
            
        case GET_RECURSOS_COMPARTIDOS_FAILURE:
            return{
                ...state,
                compartidos: action.payload,
                loading: false
            }

        // case LIKE_RECURSO:
        //     return{
        //         ...state,
        //         loading: true
        //     }
        
        // case LIKE_RECURSO_SUCCESS:
        //     return{
        //         ...state,
        //         loading: false
        //     }
        
        // case LIKE_RECURSO_FAILURE:
        //     return{
        //         ...state,
        //         loading: false
        //     }
        default: return { ...state };
    }
}
