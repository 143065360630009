import React from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PodcastAudioPlayer from "./player/PodcastAudioPlayer";
import REATooltip2 from "src/components/shared/REATooltip2";

export default function Main(props) {

    const {sinopIsOpen, toggleSinops, episode, orden, setisPlaying, isMobile} = props;

    return (
        <div className="main">
            <REATooltip2 TooltipText={episode.title} >
                <h5 className="ep-title">
                    Episodio {orden}: <strong>"{episode.title}"</strong>
                </h5>
            </REATooltip2>
            <REATooltip2 TooltipText={episode.synopsis.slice(0,251)} >
                <p className={sinopIsOpen ? "expanded-sinops" : 'collapsed-sinops'}>
                    {episode.synopsis}
                </p>
            </REATooltip2>
            {episode.synopsis.length>100
                ?<strong className="leer-mas">
                    {sinopIsOpen ? 'Leer menos' : 'Leer mas'}
                    <ArrowDropDownIcon
                        className={sinopIsOpen ? "arrow-collapse" : "arrow-expand"}
                        onClick={() => toggleSinops()}
                    />
                 </strong>
                :<strong></strong>
            }
            {!isMobile &&
            <PodcastAudioPlayer
                setisPlaying={setisPlaying}
                key={episode.resource.attachments.id}
                audio={episode.resource.attachments[0]}
                episodeID={episode.id}
            />}
        </div>
    )
}