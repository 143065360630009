import React from "react";
import { useContext } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { ResourcesService } from "src/axios";
import { getDataUser } from 'src/redux/actions/authActions';

const LikeResourceContext = React.createContext(undefined);

const mapStateToProps = ({ authReducer }) => {
    return {
    };
};
const mapDispatchToProps = {
    getDataUser,
}

export const LikeResourceContextProvider = connect(mapStateToProps, mapDispatchToProps)(({children, user, resourceId, resourceType, getDataUser}) =>
{
    const likeFolders = (() =>
    {
        let like_folders = [];

        if(user?.folderMisFavoritos)
            like_folders = [user.folderMisFavoritos];
        if(user?.likeFolders)
            like_folders = [...like_folders, ...user.likeFolders];

        return like_folders;
    })();

    const [ isLiked, setIsLiked ] = React.useState(false);
    const [ likeInProgress, setLikeInProgress ] = React.useState(false);

    React.useEffect(() =>
    {
        setIsLiked(user?.likes?.some((element) => element.resource_id === resourceId) ?? false);
    }, [user, resourceId]);

    const handleLike = async (idRecurso, like, folder = 0, resource_type) =>
    {
        let response;
        try
        {
            if(like)
            {
                response = await ResourcesService.unlikeResource(idRecurso, folder)
                NotificationManager.success("Recurso quitado de Favoritos")
            }
            else
            {
                response = await ResourcesService.likeResource(idRecurso, folder, { resource_type_id: resource_type ?? 1 })
                NotificationManager.success("Recurso agregado a Favoritos")
            }

            await getDataUser();
        }
        catch (error)
        {
            NotificationManager.success("No se pudo modificar el recurso")
        }
        return response;
    }

    const like = (folderId) =>
    {
        setLikeInProgress(true);

        let likedAtNow = false;
        if(folderId === undefined)
        {
            folderId = 0;
            likedAtNow = isLiked;
        }
        else
        {
            likedAtNow = likeFolders.some((folder) => folder.id === folderId && folder.resources.includes(resourceId));
        }

        handleLike(resourceId, likedAtNow, folderId, resourceType)
        .then((response) =>
        {
            // Los likes totales vienen en la response, si aparece al menos una vez es porque tiene like
            const likes = response?.data;

            console.log("likes")
            console.log(likes)

            if(!likes)
            {
                NotificationManager.error('Sesión finalizada, no se puede dar me gusta');
                window.location.reload(true);
            }

            setIsLiked(likes.some((_like) => _like.resource_id === resourceId));
        })
        .finally(() =>
        {
            setLikeInProgress(false);
        });
    }

    return <LikeResourceContext.Provider value={{
        resourceId,
        like,
        isLiked,
        likeFolders,
        likeInProgress,
    }}>
        { children }
    </LikeResourceContext.Provider>
});

export const useLikeResource = () =>
{
    return useContext(LikeResourceContext);
}
