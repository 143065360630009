import React from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { HeartIcon } from "src/components/base/HeartIcon";
import { LoadingSpinner } from "src/components/base/LoadingSpinner";
import { LikeResourceContextProvider, useLikeResource } from "./LikeResourceContext";
import { LikeResourceDialog } from "./LikeResourceDialog";

const mapStateToProps = ({ authReducer }) => {
    return {
        user: authReducer.user,
        loggedIn: authReducer.loggedIn,
    };
};
const mapDispatchToProps = {
}

export const LikeResourceButton = connect(mapStateToProps, mapDispatchToProps)(({user, loggedIn, resourceId, resourceType}) =>
{
    return (
<>
<LikeResourceContextProvider
    user={user}
    resourceId={resourceId}
    resourceType={resourceType}
>
    <LikeResourceButtonInner user={user} loggedIn={loggedIn}/>
</LikeResourceContextProvider>
</>
    )
})

const LikeResourceButtonInner = ({user, loggedIn}) =>
{
    const { isLiked, like, likeInProgress } = useLikeResource();
    const [ modalOpen, setModalOpen ] = React.useState(false);

    function handleClick(event)
    {
        event.preventDefault();

        if(!loggedIn)
        {
            NotificationManager.warning("Debes estar registrado y/o logueado para agregar a favoritos")
        }
        else
        {
            // Si no tiene carpetas creadas le damos like directo sin abrir el modal
            if(user.likeFolders?.length > 0)
                setModalOpen(true);
            else
                like();
        }
    }

    return (
<>
    <div className="swap" onClick={handleClick}>
        { likeInProgress && <LoadingSpinner/> }
        { !likeInProgress && <HeartIcon isLiked={isLiked}/> }
    </div>
    <LikeResourceDialog user={user} isOpen={modalOpen} onClose={() =>
    {
        setModalOpen(false);
    }}/>
</>
    )
}
