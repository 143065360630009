import React from "react";
import { Link, useHistory } from "react-router-dom";
import IconRecurso from "src/components/shared/iconRecurso";
import { MisRecursosLikeListItemDropdown } from "./MisRecursosLikeListItemDropdown";
import { UncontrolledCollapse } from "reactstrap";
import ReactHtmlParser from 'html-react-parser';
import { buildResourceRoute } from "src/util/buildResourceRoute";
import { getDefaultImage } from "src/helpers/defaultImage";

export const MisRecursosLikeListItem = (props) =>
{
    const {
        recurso,
        handleAddRecursoFolder,
        draggableProvided,
        draggableSnapshot,
    } = props;
    const history = useHistory();


    return (
    <li
        data-id={recurso.resource_id}
        ref={draggableProvided?.innerRef}
        {...draggableProvided?.draggableProps}
        {...draggableProvided?.dragHandleProps}
        className={"favorite-like-item" + (draggableSnapshot?.isDragging ? " dragging" : "")}
    >
        <div className="list-group-item">
            <div className="row">
                <div className="col-2 col-md-1 mt-1">
                    <IconRecurso format={recurso.resources_format_id} claseIcon="icono-recursos" />
                </div>
                <div className="col-8 col-md-5">
                    <Link to={Object.keys(recurso).includes('collection') ? `/colecciones/${recurso.resource_id}` : `/recurso/${recurso.resource_id}`} className="listTitle recurso-link">{recurso.display_title}</Link>
                </div>
                <div className="col-md-4 ">
                    {
                        recurso.subjects.map((subject, index) => (
                            <span key={subject.id + index} className="success listSubtitle" style={{ fontSize: 15 }}>{subject.description}{index !== (recurso.subjects ?? []).length - 1 && " - "}</span>
                        ))
                    }
                </div>
                <div className="col-12 col-md-1">
                    <a id={`toggler-${recurso.resource_id}`} href="#REA" className="listVer">
                        <i className="fa fa-eye"/>
                    </a>
                </div>
                <div className="col-4 col-md-1 listAdicionales">
                    <MisRecursosLikeListItemDropdown
                        recurso={recurso}
                        addRecursoToFolder={handleAddRecursoFolder}
                    />
                </div>
            </div>
        </div>
        <div className="list-group-item hiddenRow" style={{ borderBottomWidth: 0 }}>
            <UncontrolledCollapse toggler={`toggler-${recurso.resource_id}`} tag="div">
                <div className="item col-12 col-md-6 col-xl-3 list-group-item pt-4">
                    <div className="thumbnail card shadow-none" style={{ width: "100%" }}>
                        <div className="img-event">
                            <img className="group list-group-image img-fluid card-img-top" src={getDefaultImage(recurso)} alt="" />
                            <IconRecurso format={recurso.resources_format_id} claseIcon="icono" />
                        </div>
                        <div className="caption card-body py-0">
                            <h5 className="h5 group card-title inner list-group-item-heading mt-0 text-dark-grey">
                                {recurso.display_title}
                            </h5>
                            <br/>
                            <small>
                                {recurso.resourceFormat && recurso.resourceFormat.description} {recurso.category && `| ${recurso.category.description}`}
                            </small>
                            <div className="group inner list-group-item-text">
                                {ReactHtmlParser(recurso.subtitle)}
                            </div>
                            <button
                                className="btn btn-sm btn-secondary ver-completo-btn float-right small"
                                onClick={(event) =>
                            {
                                event.preventDefault();
                                history.push(buildResourceRoute(recurso));
                            }}>
                                Ver recurso completo
                            </button>
                        </div>
                    </div>
                </div>
            </UncontrolledCollapse>
        </div>
    </li>
    )
}
