import React, {useEffect, useRef} from "react";

export default function PodcastAudioVolControl({volumeBarRef, volumeBarButtonRef, volumeControl, episodeID})
{
    const [showVol, setShowVol] = React.useState(false)
    const toogleShowVol = () => showVol ? setShowVol(false) : setShowVol(true)

    const refForOutsideClick = useRef(null);

    function HideOnClickOutside(ref) {
        /* function hides element if user clicks outside the reference */
        function handleClickOutside(event) {
            (!ref.current.contains(event.target)) && setShowVol(false);
        }
        useEffect(() => {
            // Bind function to event listener
            document.addEventListener("mousedown", handleClickOutside);
            // Unbind the event listener when component did unmount
            return () => {document.removeEventListener("mousedown", handleClickOutside)};
        }, []);
    }
    HideOnClickOutside(refForOutsideClick);

    return (
        <div ref={refForOutsideClick} className="pc-player-vol-wrapper">
            <div className="audioplayer-volume-button" onClick={toogleShowVol}>
                <span/>
            </div>
            <div
                className={
                showVol
                ? "audioplayer-volume-bar vertical show"
                : "audioplayer-volume-bar vertical"
                }
            >
                <div className="audioplayer-volume-adjust" ref={volumeBarRef} style={{width:'100%'}}></div>
                <input
                    ref={volumeBarButtonRef}
                    max="100"
                    type="range"
                    className="audioplayer-volume-adjust-input"
                    onChange={(e) => volumeControl(e, episodeID)}
                />
            </div>
        </div>
    )
}
