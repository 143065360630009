import React from 'react';
import { useEffect } from 'react';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Loading from 'src/components/shared/loading';
import { GenericSideBar, useGenericSideBar } from 'src/components/sidebar/GenericSideBar';
import { showDeveloperMessage } from 'src/helpers';
import { VistaRecursosDetalleContent } from './VistaRecursosDetalleContent';
import { VistaRecursosDetalleSidebar } from './VistaRecursosDetalleSidebar';

export function VistaRecursosDetalle(props)
{
    const {
        recursos,
        authReducer,
        handleGetRecursos,
        breadcrumbCurrent,
        breadcrumbPrevious,
        renderSearchInput,
    } = props;

    const { show } = useGenericSideBar();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const { detalle_recurso: recurso, error_detalle: error_recurso } = recursos;

    useEffect(() =>
    {
        recurso && showDeveloperMessage("<<recurso>>", recurso);
    }, [recurso]);

    if (error_recurso !== null && error_recurso.recursoId === match.params.recursoId)
    {
        return <Redirect to="/not-found" />
    }

    if (recurso === null || recurso.resource_id.toString() !== match.params.recursoId)
    {
        return <Loading fullWidth={true} />
    }

    const className = ["pt-0"];
    if(show)
        className.push("collapsed");

    return (
<>
<div className="container-fluid px-0">
    <div className="wrapper" id="wrapper">
        <GenericSideBar title="Ficha técnica">
            <VistaRecursosDetalleSidebar
                history={history}
                recurso={recurso}
            />
        </GenericSideBar>
        <div id="content" className={className.join(" ")}>
            <div className="content-body">
                <VistaRecursosDetalleContent
                    location={location}
                    history={history}
                    match={match}
                    recurso={recurso}
                    error_recurso={error_recurso}
                    authReducer={authReducer}
                    loading={recursos.loading}
                    handleGetRecursos={handleGetRecursos}
                    breadcrumbCurrent={breadcrumbCurrent}
                    breadcrumbPrevious={breadcrumbPrevious}
                    renderSearchInput={renderSearchInput}
                />
            </div>
        </div>
    </div>
</div>
</>
    )
}
