import React from "react";
import { /* useEffect */ } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

import BarraBusqueda from "src/components/recursos/barraBusqueda";
import AudioRecurso from "src/components/recursos/detalle/audioRecurso";
import GaleriaRecurso from "src/components/recursos/detalle/galeriaRecurso";
import InteractivoRecurso from "src/components/recursos/detalle/interactivoRecurso";
import LibroRecurso from "src/components/recursos/detalle/libroRecurso";
import VideoRecursoSeasons from "src/components/recursos/detalle/videoRecursoSeasons";
import Loading from "src/components/shared/loading";
import { dateHuman, /* showDeveloperMessage */ } from "src/helpers";
import { AUDIO, COLECCION, GALERIA_IMAGENES, INTERACTIVO, LIBRO_ELECTRONICO, VIDEO, PODCAST } from "src/util/constantes";
import ReactHtmlParser from 'html-react-parser';
import ResourceActionBoxDetalle from "src/components/recursos/resources/actions/ResourceActionBoxDetalle";
import VideoRecurso from "src/components/recursos/detalle/videoRecurso";
import VideoSeasonBox from "./video/VideoSeasonBox";
import Breadcrumb2 from "../shared/Breadcrumb2";
import SeasonNav from "./podcasts/SeasonNav";

export function VistaRecursosDetalleContent(props)
{
    const { 
        recurso,
        match, 
        location, 
        history, 
        authReducer, 
        error_recurso,
        breadcrumbCurrent,
        breadcrumbPrevious,
        renderSearchInput,
    } = props;
    // const queryString = new URLSearchParams(location.search);
    // const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = new URLSearchParams(location.search);
    // console.log(queryParams, queryParams.has('goBack_to'));

    
    if (error_recurso !== null && error_recurso.recursoId === match.params.recursoId)
    {
        return <Redirect to="/not-found" />
    }

    if (recurso === null || recurso.resource_id.toString() !== match.params.recursoId)
    {
        return <Loading fullWidth={true} />
    }

    const hasAttachments = recurso.attachments.length > 0;

    const vaciadoEtiquetaTitulo = (string) =>
    {
        const etiqueta1 = string.replaceAll("<p>", "");
        const limpio = etiqueta1.replaceAll("</p>", "");
        return limpio
    }

/* 
    const reproducir = (episodio) => {
        // setToUrlVideo(episodio.file)
        const eleccion = document.getElementById(`season-${episodio.id}`)
        const episodes = document.querySelectorAll(`.btn-episode #${episodio.id}`)
        for (const item of episodes) {
            item.classList.remove('selected')
        }
        if(eleccion.classList.contains('selected')){
            eleccion.classList.remove('selected')
        } else {
            eleccion.classList.add('selected')
        }
    }
 */
    return (
<>
    <Breadcrumb2
        currentPosition={recurso.display_title.length > 60 ? `${recurso.display_title.substring(0, 60)}...` : recurso.display_title}
        previousPositions={["/home", breadcrumbCurrent]}
    />
    { renderSearchInput &&
    <BarraBusqueda
        location={location}
        history={history}
        detalle={false}
    />}
    {queryParams.has('goBack_to') && (
        <div className="back-container" style={{ textAlign: 'right', marginBottom: '1rem' }} >
            <a role="button" className="btn btn-success btn-back-col" href={`/colecciones/${queryParams.get('goBack_to')}`}>Volver a Colecciones</a>
        </div>
    )}
    <div id="productos" className="row view-group mx-2">
        <div className="col-12 col-xl-10 offset-xl-1">
            <h2 className="text-dark-grey mb-0 recurso-title-no-transform" style={{fontWeight: 'unset'}}>
                {ReactHtmlParser(recurso.title)}
            </h2>
            {(recurso.resources_format_id === VIDEO && (recurso.seasons && recurso.seasons !== null && recurso.serie.id !== null ))  &&
                <>
                    <h4 className="mt-3 fw-bold">Este episodio pertenece a la serie "{ReactHtmlParser(vaciadoEtiquetaTitulo(recurso.serie.title))}"</h4>
                    <div className="mt-3 secondary">{ReactHtmlParser(recurso.serie.description)}</div>
                </>
            }
            <h6>{ReactHtmlParser(recurso.subtitle)}</h6>
            <div className="row mt-4 mb-4">
                <div className="col-md-6 mt-2">
                    <ResourceActionBoxDetalle authReducer={authReducer} recurso={recurso} urlRecurso={window.location.href}  view={'detail'} hasAttachments={hasAttachments}/>
                </div>
                <div className="col-md-6 text-right mt-3">
                    <p className="small"><strong>Editor/a responsable:</strong> {recurso.owners ? recurso.owners.length > 0 ? recurso.owners[0].description : '' : ''} {dateHuman(recurso.publication_date)/*  convertDate(recurso.publication_date, 'YYYY-MM-DD HH:mm:ss', 'D[/]MM[/]YYYY') */}</p>
                </div>
            </div>
            <div className="col-md-12 text-justify mt-5">
                {recurso.body && ReactHtmlParser(recurso.body)}
            </div>
            {/* <hr /> */}
            <div className="row">
                <div className="col-md-12 text-justify">
                    {   
                        /* TODO: 
                            REFACTORIZAR ESTO PARA QUE NO PEGUE ATTACHMENT VACIO SI TIENE IFRAME,
                            FUNCTIONA CON VIDEO, SEGURO VA A ROMPER CON OTRA COSA 
                        */
                    }
                    {
                        (recurso.resources_format_id === VIDEO && (!recurso.seasons || recurso.seasons === null))  &&
                        !recurso.embebedCod &&
                            <VideoRecurso
                                videos={recurso.attachments}
                                title={recurso.display_title}
                            />
                    }
                    {
                        recurso.resources_format_id === GALERIA_IMAGENES &&
                        hasAttachments &&
                        <GaleriaRecurso
                            images={recurso.attachments}
                        />
                    }
                    {
                        recurso.resources_format_id === LIBRO_ELECTRONICO &&
                        hasAttachments &&
                        <LibroRecurso
                            libros={recurso.attachments}
                        />
                    }
                    {
                        recurso.resources_format_id === AUDIO &&
                        hasAttachments &&
                        <AudioRecurso
                            audios={recurso.attachments}
                        />
                    }
                    {
                        recurso.resources_format_id === INTERACTIVO &&
                        hasAttachments &&
                        <InteractivoRecurso
                            interactivos={recurso.attachments}
                        />
                    }
                    {
                        recurso.resources_format_id === COLECCION &&
                        hasAttachments &&
                        <></>
                    }
                </div>
            </div>
            {
                recurso.resources_format_id === PODCAST &&
                <div className="podcast">
                    <h4 className="mt-3 mb-4 font-weight-bolder">
                    {recurso.podcast_data.seasons.length > 1 ? "Temporadas" : "Temporada" }
                    </h4>
                    {recurso.podcast_data.seasons.map((season) => 
                        <SeasonNav key={season.id} season={season}/>
                    )}
                </div>                       
            }          
            {
                
                (recurso.resources_format_id === VIDEO && (recurso.seasons && recurso.seasons !== null)) &&
                    <VideoRecursoSeasons
                        seasons={recurso.seasons[0]}
                        title={recurso.display_title}
                        history={history}
                        recurso={recurso}
                    />
            }
            {
                /*si hay contenido embebido, acá se renderiza*/
                recurso.embebedCod !== null &&
                <div className="embed-container" dangerouslySetInnerHTML={{__html: recurso.embebedCod}}></div>
            }

            {
                recurso.attachments.length > 0 && recurso.attachments[0].video_attachment_path && recurso.resources_format_id === VIDEO &&
                <a
                    href={recurso.attachments[0].video_attachment_path}
                    download={recurso.display_title}
                    className="btn btn-lg w-100 font-18 mt-3 btn-descarga text-white"
                >
                    DESCARGAR {recurso.attachments[0].video_attachment_filename}
                </a>
            }
            
            { recurso.resources_format_id === VIDEO &&
            <div>
                { (recurso.seasons ?? [[]])[0].map((season) => <VideoSeasonBox key={season.id} resource={recurso} season={season}/>) }
            </div>
            }
        </div>
        {/* SE saca por el momento */}
        {/* <div className="container p-0">
            <div className="col-12">
                <div className="home-demo">

                    <CarouselUltimosRecursos history={history}/>
                </div>
            </div>
        </div> */}
    </div>
</>
    )
}
