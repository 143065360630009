import { getDomain } from "./url";

export function setCookie(name, value = "", days, domain = getDomain()) {
    let expires = "";

    if(days) {
        const date = new Date(Date.now() + (days * 24 * 60 * 60 * 1000));
        expires = date.toUTCString();
    }

    document.cookie = `${name}=${value}; expires=${expires}; domain=${domain}; path=/`;
}

export function getCookie(name) {
    const nameEQ = name + "=";
    const cookieArray = document.cookie.split('; ');

    for (const cookie of cookieArray) {
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }

    return "";
}

export const hasCookie = (cName) => Boolean(getCookie(cName) !== "");
export const cleanCookie = (cName, domain = getDomain()) => setCookie(cName, "", -5, domain);
