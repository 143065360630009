import React, { useEffect } from 'react';
import { redirectOAuthRegister } from 'src/helpers/auth';

const Registro = (props) => {
	useEffect(() => {
		setTimeout(() => {
			redirectOAuthRegister()
		}, 3000)
	}, []);

	return (
		<div className="mt-1 ml-1">
			<p>Estás siendo redirigido a una URL externa...</p>
		</div>
	);
}

export default Registro;
