import React from "react";
// import { UncontrolledTooltip } from "reactstrap";
import REATooltip from "../REATooltip";
import MenuIcon from "./MenuIcon";

export default function AudioPlayerButtonExpandCollapseTranscription({audio, onToggleTranscription})
{
    return (
<button
    type=""
    id={`btn-transcript-${audio.id}`}
    className="audio-btn"
    onClick={() => onToggleTranscription(audio.id)}
>
    {/* <img src={Transcript} alt="Download icon" width="55px" height="55px" /> */}
    <MenuIcon/>
    <REATooltip placement="right" target={`btn-transcript-${audio.id}`} >
        <div className="notMarginButton">Ver Transcripción</div>
    </REATooltip>
</button>
    )
}
