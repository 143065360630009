import React from "react";

export default function AudioPlayerControlsPlayButton({audioRef, onClick})
{
    return (
<div className="audioplayer-playpause" title="" onClick={(e) =>
{
    if(audioRef.current)
        onClick(e);
}}>
    <span/>
</div>
    )
}
