import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
// import ReactJWPlayer from 'react-jw-player';
// import SubMenuVideoSeason from './subMenuVideoSeason';

const Reproductor = (props) => {
    const { video } = props;
    const location = useLocation();

    useEffect(() => {
        const tagVideo = document.getElementById("tagVideo");
        if(location.search==='?play=auto') tagVideo.play();
    });

    return (
           <>
                <div className="row">
                <div className="col-md-12">
                    <div className="embed-responsive embed-responsive-4by3">

                        <video id="tagVideo" className="embed-responsive-item" controls poster={video.preview_video_path}>
                            <source id="rpVideo" src={video.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                       
                    </div>
                </div>
              </div>
           </>
    )

}


export default Reproductor