
import axios from 'axios';
import { json as headersPortal } from '../config/headers';
import { errorResponseHandler } from '../config/handlers';

const axiosInstance = axios.create({
   baseURL: `${process.env.REACT_APP_BASE_URL}`,
   timeout: 60000,
   headers: headersPortal
});

axiosInstance.interceptors.request.use(
   request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
   response => successHandler(response),
   error => errorHandler(error)
);

const requestHandler = (request) => {
   return request;
}

const errorHandler = errorResponseHandler;
const successHandler = (response) => response;

export default axiosInstance;
