export const Types = {
    USER_LOADING: 'USER_LOADING',
    USER_NOT_LOADING: 'USER_NOT_LOADING',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
    ADD_ACCOUNT: 'ADD_ACCOUNT',
    SET_CURRENT_ACCOUNT: 'SET_CURRENT_ACCOUNT',
    ADD_TOKEN: 'ADD_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN',
}


export const UserTypes = {
    LOADING: 'USER_LOADING',
    NOT_LOADING: 'USER_NOT_LOADING',
    FETCH: "GET_USER",
    FETCH_SUCCESS: "USER_FETCH_SUCCESS",
    FETCH_FAILURE: "USER_FETCH_FAILURE",
    SET_MENU: "SET_USER_MENU",
    LIKES: "USER_SET_LIKES_AND_FOLDERS"
}

export const ResourceTypes = {
    FETCH_ALL: "GET_RECURSOS",
    FETCH_ALL_SUCCESS: "GET_RECURSOS_SUCCESS",
    FETCH_ALL_FAILURE: "GET_RECURSOS_FAILURE",
    FETCH_BY_ID: "GET_RECURSO_BY_ID",
    FETCH_BY_ID_SUCCESS: "GET_RECURSO_BY_ID_SUCCESS",
    FETCH_BY_ID_FAILURE: "GET_RECURSO_BY_ID_FAILURE",
    LIKES: "SET_LIKES",
    LIKE_FOLDERS: "SET_LIKE_FOLDERS",
}