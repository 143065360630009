import React, { useState, Fragment } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import { NotificationManager } from 'react-notifications';

import { Select, CircularProgress, FormControl, MenuItem } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { MoodleService } from 'src/axios';

const Aula = (props) => {
    const { titleRecurso, aula, copete, type } = props
    const [sections, setSections] = useState(null);
    const [collapse, setCollapse] = useState(false);

    const getSections = async (type, idAula) => {
        try {
            const result = await MoodleService.getSectionsAula(idAula, type);
            setSections(result.data);
        } catch (error) {
            NotificationManager.error("Ha ocurrido un error. Intente nuevamente")
        }
    }


    const getUrl = async (idSection, isResource) => {
        try {
            const query = new URLSearchParams({
                section: idSection,
                externalurl: window.location.href,
                name: titleRecurso,
                subtitle: encodeURIComponent(copete) 
            });
            const result = await isResource ? MoodleService.getUrlModdleResource(type, aula.id, query.toString()) : MoodleService.getUrlModdleTask(type, aula.id, query.toString());
			window.open(result.data, "_blank")
        } catch (error) {
            NotificationManager.error("Ha ocurrido un error. Intente nuevamente")
        }
    }


    return (
        <Fragment>
            <Row style={{ cursor: "pointer" }} onClick={() => {
                if (!collapse) {
                    getSections(type, aula.id)
                }
                setCollapse(!collapse)
            }}>
                <Col md={1} style={{ textAlign: "center", paddingRight: 0, paddingLeft: 0 }}>
                    {
                        collapse ?
                            <ArrowDropUpIcon />
                            :
                            <ArrowDropDownIcon />
                    }
                </Col>
                <Col md={11} style={{ alignItems: "center", display: "flex" }} id={`toggler-aula-${aula.id}`} >
                    {aula.title}
                </Col>

            </Row>

            <Collapse isOpen={collapse} style={{ padding: "1rem 0px" }}>
                {
                    sections === null
                        ?
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            {
                                sections.map(section => {
                                    return (
                                        <div className="mt-1 mb-2">
                                            <div className="row">
                                                <div className="col-6 offset-1">
                                                    {section.name}
                                                </div>
                                                <div className="col-4">
                                                    <FormControl fullWidth>
                                                        <Row>
                                                            <Col sm={4}>como: </Col>
                                                            <Col sm={8}>
                                                                <Select
                                                                    fullWidth
                                                                    defaultValue=""
                                                                    onChange={(e) => {
                                                                        if (e.target.value === 1) {
                                                                            getUrl(section.section, true);
                                                                            props.modalToggle()
                                                                        } else {
                                                                            getUrl(section.section, false);
                                                                            props.modalToggle()
                                                                        }
                                                                    }}
                                                                >
                                                                    <MenuItem value={1}>Recurso</MenuItem>
                                                                    <MenuItem value={2}>Tarea</MenuItem>
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div style={{ backgroundColor: "#ddd", height: 1 }} className="mt-2"></div>
                                        </div>
                                    )
                                })
                            }
                        </>
                }
            </Collapse>
        </Fragment>
    )
}

export default Aula
