import CryptoJS from 'crypto-js';

export const base64URL = (string) => string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
export const generateCodeVerifier = () => generateRandomString(128);
export const generateCodeChallenge = (code_verifier) => base64URL(CryptoJS.SHA256(code_verifier));

function generateRandomString(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}