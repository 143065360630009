import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, TabContent, TabPane, Nav/* , NavItem, NavLink */ } from 'reactstrap';

import Loading from 'src/components/shared/loading';

const ModalMoodle = ({ loading, conAulas, error, modalOpen, modalToggle, recursoTitle, ...props }) => {
    return (
        <Modal isOpen={modalOpen} toggle={modalToggle} scrollable={true} size='lg'>
            <ModalBody>
                <h3>Compartir "{recursoTitle}" en:</h3>
                {
                    (error) && (
                        <p>Las aulas virtuales están en mantenimiento en este momento. Pronto estarán disponibles para ingresar, compartir recursos y mucho más.</p>
                    )
                }
                {
                    (!error && (!conAulas)) && (
                        <p>Para usar esta función tenés que tener aulas virtuales en la Plataforma Juana Manso. Para información sobre cómo solicitarlas, accedé a <a href={`${process.env.REACT_APP_JUANAMANSO}/aulasvirtuales`} target="_blank" rel="noopener noreferrer">Aulas virtuales</a></p>
                    )
                }
                {(!error && conAulas) && (
                    <>
                        <Nav className="nav-pills mb-3" tabs />
                        {loading ? (
                             <TabContent>
                                <Loading/>
                            </TabContent>
                        ) : (
                            <TabContent>
                                <TabPane>
                                    {props.children}
                                </TabPane>
                            </TabContent>
                        )}
                        
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color='secondary' onClick={modalToggle}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalMoodle
