import React from 'react'
import { useQuery } from 'src/hooks/useQuery';
import { FilterTag } from './FilterTag';

const FiltrosAplicadosRecursos = (props) => {
    const { filtros } = props;
    const query = useQuery();

    return (
        <div className="row mb-3 mt-0 mx-2">
            <div className="col-md-12">

                {
                    filtros.active.map((categoria, index) => {
                        let filterApply = (categoria?.elements ?? []).find(element => element.id.toString() === query.get(categoria.key)) ?? ((categoria?.elements ?? [])[0] ?? categoria);
                        return (
                            <FilterTag key={categoria.key} name={categoria.key} >
                                {filterApply && filterApply.display_name}
                            </FilterTag>
                        );
                    })
                }

                { query.get("keyword") && query.get("keyword") !== "" && (
                    <FilterTag name={"keyword"} >
                        {query.get("keyword")}
                    </FilterTag>
                ) }

            </div>
        </div>
    )
}

export default FiltrosAplicadosRecursos