import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { HeartIcon } from "src/components/base/HeartIcon";
import { LoadingSpinner } from "src/components/base/LoadingSpinner";
import { useLikeResource } from "./LikeResourceContext";

export const LikeResourceDialog = ({user, isOpen, onClose}) =>
{
    const { like, likeFolders, resourceId, likeInProgress } = useLikeResource();

    return (
<>
    {/* Modal Carpetas */}
    <Modal isOpen={isOpen} toggle={onClose}>
        <ModalBody style={{ padding: "2rem 2rem" }}>
        <h3 className="mb-3 modal-title">Agregar/quitar recurso a listas</h3>
        {user &&
        <div className="row mt-4 px-3" >
            <ul className="list-group w-100">
                {likeFolders.map((folder) => {
                    return (
                    <li key={folder.id} className="list-group-item">
                        <div className="row">
                            <div className="col-md-9 lh-36">
                                {folder.title ?? "Mis favoritos"}
                            </div>
                            <div className="col-md-3 lh-36">
                                <div className="swap" onClick={(e) =>
                                {
                                    like(folder.id);
                                }}>
                                    { likeInProgress && <LoadingSpinner/> }
                                    { !likeInProgress && <HeartIcon isLiked={folder.resources.includes(resourceId)} id={folder.id}/> }
                                </div>
                            </div>
                        </div>
                    </li>
                )})}
            </ul>
        </div>}
        </ModalBody>
        <ModalFooter>
            <Button color='secondary' onClick={onClose}>
                Cerrar
            </Button>
        </ModalFooter>
    </Modal>
</>
    )
}
