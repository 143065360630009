import { 
    GET_CLASSROOMS, GET_CLASSROOMS_SUCCESS, GET_CLASSROOMS_FAILURE,
    GET_CLASSROOMS_ROLES, GET_CLASSROOMS_ROLES_SUCCESS, GET_CLASSROOMS_ROLES_FAILURE,
} from "../types"
import { MoodleService } from "src/axios"
import { isLoggedIn } from "src/helpers/auth";

export const getAulasUser = () => async dispatch => {
    dispatch({
        type: GET_CLASSROOMS
    });

    try {
        const result = await MoodleService.getClassroomUser();

        dispatch({
            type: GET_CLASSROOMS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        dispatch({
            type: GET_CLASSROOMS_FAILURE,
            payload: 'Las aulas virtuales no están disponibles en este momento. Pronto podrás volver a ingresar.'
        });
    }
}

export const getRolesUser = () => async dispatch => {
    dispatch({
        type: GET_CLASSROOMS_ROLES
    });

    try {
        if(isLoggedIn()){
            const result = await MoodleService.getRolesUser();

            dispatch({
                type: GET_CLASSROOMS_ROLES_SUCCESS,
                payload: result.data
            });
        } else {
            dispatch({
                type: GET_CLASSROOMS_ROLES_FAILURE,
                payload: []
            });
        }
    } catch (error) {
        dispatch({
            type: GET_CLASSROOMS_ROLES_FAILURE,
            payload: []
        });
    }
}
