import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "src/hooks/useQuery";

export const FilterTag = (props) =>
{
    const {
        name: filterName,
        children,
    } = props;

    const query = useQuery();
    const history = useHistory();

    return (
<a
    href={"?" + query}
    className="badge badge-primary"
    style={{ marginRight: "0.5rem" }}
    onClick={(event) =>
    {
        event.preventDefault();
        query.delete(filterName);
        history.push("?" + query);
    }}
>
    { children }

    <i className="fa fa-close fa-small mx-1"></i>
</a>
    )
}
