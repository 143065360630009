import React from 'react';

// import Download from 'src/assets/images/boton-descargar-fondo-celeste-icono-blanco.svg'
import Download from 'src/assets/images/audio-btn-download.svg'

const DownloadIcon = () => {
    return (
        <img src={Download} alt="Descargar" width="55px" height="55px" />
    );
}

export default DownloadIcon