import React from "react";

export const ResourceActionBox = ({children}) =>
{
    return (
<div className='resource-action-box'>
    { children }
</div>
    )
}
