import React, { useState, useRef } from "react";
import { Form, Input } from "reactstrap";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { soportaDictadoPorVoz } from "../../helpers";
import { NotificationManager } from "react-notifications";

const BarraBusqueda = (props) =>
{
    const { location, history } = props;
    const [input, setInput] = useState("");
    const [layout, setLayout] = useState("default");
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [microfono, setMicrofono] = useState(false);
    const keyboard = useRef();

  const onChange = (input) => {
    if (input.length < 201) {
      setInput(input);
    }
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();

    if (button === "{enter}") {
      handleSubmit();
    }
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    if (input.length < 201) {
      setInput(input);
      if (keyboard.current !== undefined) keyboard.current.setInput(input);
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("keyword", input);
    history.push(`/recursos?${queryParams.toString()}`);
  };

  const micro = () => {
    try {
      if (!microfono) {
        let SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        let recognition = new SpeechRecognition();
        recognition.lang = "es-ES";
        recognition.continuous = false;
        recognition.interimResults = true;
        let texto = "";
        recognition.onresult = function (event) {
          for (let i = event.resultIndex; i < event.results.length; i++) {
            if (event.results[i].isFinal) {
              texto += event.results[i][0].transcript;
              setInput(texto);
              onChangeInput({
                target: {
                  value: texto,
                },
              });
            }
          }
        };
        recognition.onspeechend = function () {
          setMicrofono(false);
        };
        NotificationManager.info("Escuchando...");
        recognition.start();
        setMicrofono(true);
      }
    } catch (error) {
      console.error("Este navegador no soporta dictado por voz");
    }
  };

/* 
  const changeViewToGrid = () => {
    document
      .getElementsByName("recursosListados")
      .forEach((r) => r.classList.remove("list-group-item"));
    document
      .getElementsByName("recursoBody")
      .forEach((r) => r.classList.add("body-resource-height"));
    document
      .getElementsByName("recursoBodyTexto")
      .forEach((r) => r.classList.remove("overflow-text-list"));
    document
      .getElementsByName("recursoBodyTexto")
      .forEach((r) => r.classList.add("overflow-text"));
    document
      .getElementsByName("cardRecurso")
      .forEach((r) => r.classList.add("h-100"));
    document
      .getElementsByName("cardRecurso")
      .forEach((r) => r.classList.remove("h-70"));
  };

  const changeViewToList = () => {
    document
      .getElementsByName("recursosListados")
      .forEach((r) => r.classList.add("list-group-item"));
    document
      .getElementsByName("recursoBody")
      .forEach((r) => r.classList.remove("body-resource-height"));
    document
      .getElementsByName("recursoBodyTexto")
      .forEach((r) => r.classList.add("overflow-text-list"));
    document
      .getElementsByName("recursoBodyTexto")
      .forEach((r) => r.classList.remove("overflow-text"));
    document
      .getElementsByName("cardRecurso")
      .forEach((r) => r.classList.remove("h-100"));
    document
      .getElementsByName("cardRecurso")
      .forEach((r) => r.classList.add("h-70"));
  };
 */


  return (
    <Form onSubmit={handleSubmit} id="formBarraBusquedaRecursos">
      <nav className="navbar navbar-expand-lg navbar-light bg-light mb-3">
        <div className="container-fluid">
          <div className="col-md-12">
            {/* <div className="row"> */}
            <div className="">
              {/* <div className="col-md-1 mt-1 col-2"></div> */}
              {/* <div className="col-md-9 mb-0 col-10"> */}
              <div className="">
                <div>
                  <div className="bubble mt-0" style={{ padding: "0px" }}>
                    <div className="input-group input-group-lg">
                      <Input
                        type="text"
                        value={input}
                        id="searchKeywordValue"
                        className="form-control"
                        style={{ border: "none" }}
                        placeholder="Hola, ¿en qué te puedo ayudar hoy?"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={onChangeInput}
                        maxLength={200}
                      />
                      <cite id="lacita"></cite>
                      <div className="input-group-append">
                        <button
                          id="btn-search"
                          className="btn btn-outline-primary d-none d-sm-block"
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        <button
                          id="btn-keyboard"
                          className="btn btn-outline-primary d-none d-sm-block"
                          type="button"
                          onClick={() => setShowKeyboard(!showKeyboard)}
                        >
                          <i className="fa fa-keyboard-o"></i>
                        </button>
                        {soportaDictadoPorVoz() && (
                          <button
                            className="btn btn-outline-primary btn-grab"
                            type="button"
                            data-grab="not"
                            id="procesar"
                            onClick={micro}
                          >
                            <i className="fa fa-microphone"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {showKeyboard && (
                    <Keyboard
                      keyboardRef={(r) => (keyboard.current = r)}
                      layoutName={layout}
                      onChange={onChange}
                      onKeyPress={onKeyPress}
                      layout={{
                        default: [
                          "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                          "{tab} q w e r t y u i o p [ ] \\",
                          "{lock} a s d f g h j k l ñ ; ' {enter}",
                          "{shift} z x c v b n m , . / {shift}",
                          ".com @ {space}",
                        ],
                        shift: [
                          "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                          "{tab} Q W E R T Y U I O P { } |",
                          '{lock} A S D F G H J K L Ñ : " {enter}',
                          "{shift} Z X C V B N M < > ? {shift}",
                          ".com @ {space}",
                        ],
                      }}
                      display={{
                        "{bksp}": "Borrar",
                        "{enter}": "Enter",
                        "{lock}": "Bloq Mayus",
                        "{space}": " ",
                        "{shift}": "shift",
                        "{tab}": "tab",
                      }}
                    />
                  )}
                </div>
              </div>
              {/* {
                                detalle &&
                                <div className="col-md-2 mt-0 d-none d-sm-block">
                                    <div className="btn-group float-right">
                                        <button className="btn btn-dark btn-lg" id="list" type="button"
                                            onClick={() => changeViewToList()}
                                        >
                                            <i className="fa fa-list" aria-hidden="true"></i>
                                        </button>
                                        <button className="btn btn-dark" id="grid" type="button"
                                            onClick={() => changeViewToGrid()}
                                        >
                                            <i className="fa fa-th-large" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            } */}
            </div>
          </div>
        </div>
      </nav>
    </Form>
  );
};

export default BarraBusqueda;
