import React from "react";
// import { stripHTML } from "src/helpers/string";
import HTMLReactParser from "html-react-parser";

export default function CollectionResourceCard({ card, resource_id })
{
    const cardURL = card.url ? new URL(card.url) : null;

    const redirectUrl =
        cardURL == null
        ? null
        : (window.location.hostname === cardURL.hostname || cardURL.origin === process.env.REACT_APP_BASE_FE_URL
            ? window.location.origin + new URL(card.url.replace('recursos', 'recurso')).pathname + `?goBack_to=${resource_id}`
            : card.url)
        ;

    const title = () =>
    {
        return <h4>{ HTMLReactParser(card.title) }</h4>
    }
    const paragraph = () =>
    {
        if(!card.subtitle)
            return <></>
        return <div>{ HTMLReactParser(card.subtitle) }</div>
    }
    const image = () =>
    {
        return <img src={card.image} alt={HTMLReactParser(card.title)}/>
    }
    const cardBody = () =>
    {
        return (
        <div className="card-body">
        { card.style === 'style_a' && <>
            { title() }
            { paragraph() }
        </> }
        { card.style === 'style_b' && <>
            { image() }
            { title() }
            { paragraph() }
        </> }
        { card.style === 'style_c' && <>
            { title() }
            { image() }
            { paragraph() }
        </> }
    </div>
        )
    }

    return (
        <>
<div className="card">
    { redirectUrl &&
    <a
        href={redirectUrl}
        style={{ flexGrow: 1, color: 'black', textDecoration: 'none' }}
    >
        { cardBody() }
    </a>}
    { !redirectUrl &&
    <>
        { cardBody() }
    </>}
</div>
</>
    )
}
