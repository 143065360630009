import backoffice from '../api/backoffice';

const fetchJson = (route) => backoffice.get(`${route}.json?${Date.now()}`).then((res) => res.data);

const BackofficeService = {
    fetchHeaderMenu: () => (
        fetchJson('/header/menu')
    ),
    fetchHeaderLogo: () => (
        fetchJson('/header/logo')
    ),
    fetchFooterMenu: () => (
        fetchJson('/footer/menu')
    ),
    fetchFooterLogo: () => (
        fetchJson('/footer/logo')
    ),
    fetchFooterLogoCentro: () => (
        fetchJson('/footer/logo_centro')
    ),
    fetchFooterText: () => (
        fetchJson('/footer/texto')
    ),
    fetchContactText: () => (
        fetchJson('/contact/contact')
    ),
    fetchUserMenu: () => (
        fetchJson('/user/menu')
    ),
    fetchVisitorRegister: () => (
        fetchJson('/visitor_register/index')
    ),
};

export {BackofficeService as default};