import React from "react";
import { Link } from "react-router-dom";
import { MisRecursosLikeListItemDropdown } from "./MisRecursosLikeListItemDropdown";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MisRecursosLikeListItem } from "./MisRecursosLikeListItem";
import { reorderList } from "src/util/reorderList";
import { useMisRecursos } from "./MisRecursosContext";

export const MisRecursosLikeList = ({
    likes,
    recursos,
    activeFolderId,
    addRecursoToFolder,
}) =>
{
    const {
        updateLikeList,
        changePositionLike,
    } = useMisRecursos();

    const handleAddRecursoFolder = (recursoId, lista) =>
    {
        addRecursoToFolder(recursoId, lista, activeFolderId)
    }

    if(activeFolderId === null)
    {
        return (
<ul className="list-group w-100">
    {
        recursos.compartidos && recursos.compartidos.map(recurso => (
            <li key={"aula-" + recurso.id} >
                <div className="list-group-item background-item-favoritos" style={{ cursor: "pointer" }}>
                    <div className="row">
                        <div className="col-8 col-md-3">
                            <Link to={Object.keys(recurso).includes('collection') ? `/colecciones/${recurso.resource_id}` : `/recurso/${recurso.resource_id}`} className="listTitle recurso-link">{recurso.name}</Link>
                        </div>
                        <div className="col-md-2">
                            Aula <span style={{ textTransform: "capitalize" }}>{recurso.aula}</span>
                        </div>
                        <div className="col-md-2">
                            <span>{recurso.shared_at}</span>
                        </div>
                        <div className="col-md-2">
                            <span>{recurso.type.toUpperCase()}</span>
                        </div>
                        <div className="col-md-2">
                            {recurso.coursetitle}
                        </div>
                        <div className="col-4 col-md-1 listAdicionales">
                            <MisRecursosLikeListItemDropdown
                                recurso={recurso}
                            />
                        </div>
                    </div>
                </div>
            </li>
        ))
    }
</ul>
        )
    }

    return (
    <DragDropContext onDragEnd={(result) =>
    {
        // dropped outside the list
        if(!result.destination)
        {
            return;
        }

        //
        // Actualizar lista de likes
        //
        updateLikeList(reorderList(
            likes,
            result.source.index,
            result.destination.index
        ));

        //
        // Change position like
        //
        const resourceId = result.draggableId;
        const newPosition = result.destination.index;
        changePositionLike(resourceId, newPosition);
    }}>
        <Droppable droppableId="likes" >
        { (droppableProvided) =>
        (
            <ul
                className="list-group w-100"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
            >
            {
                likes.map((recurso, index) =>
                {
                    console.log("recurso.resource_id", recurso.resource_id);
                    return (
                    <Draggable
                        key={recurso.resource_id}
                        draggableId={"" + recurso.resource_id}
                        index={index}
                    >
                    {(draggableProvided, draggableSnapshot) =>
                    (
                        <MisRecursosLikeListItem
                            key={recurso.resource_id}
                            recurso={recurso}
                            handleAddRecursoFolder={handleAddRecursoFolder}
                            draggableProvided={draggableProvided}
                            draggableSnapshot={draggableSnapshot}
                        />
                    )}
                    </Draggable>
                )})
            }
                { droppableProvided.placeholder }
            </ul>
        )}
        </Droppable>
    </DragDropContext>
    )
}
