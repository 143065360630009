import React from "react";
import { NotificationManager } from "react-notifications";
import { ResourcesService } from "src/axios";
import { devComponentName } from "src/util/devComponentName";

const MisRecursosContext = React.createContext(undefined);
MisRecursosContext.displayName = devComponentName("MisRecursosContext");

export const MisRecursosContextProvider = ({
    children,
    user,
    recursos,
    selectedFolderId,

    selectFolder,
    likeFolders,
    sharedFolders,
    createFolder,
    renameFolder,
    deleteFolder,
    unlikeResource,
    deleteModdleResource,

    updateLikeList,
    updateFolderList,
}) =>
{
    const allFolders = [
    {
        ...user.folderMisFavoritos,
        title: "Mis favoritos",
    }, ...likeFolders];

    const activeFolder = allFolders.find((f) => f.id === selectedFolderId);

    const activeFolderId =  (!activeFolder) ? null : activeFolder.id;

    const changePositionLike = async(resourceId, newPosition) =>
    {
        try
        {
            await ResourcesService.cambiarOrdenDeRecurso(resourceId, activeFolderId, newPosition)
        }
        catch (error)
        {
            NotificationManager.error("No se pudo cambiar el orden del recurso.")
        }
    }

    const changePositionFolder = async(folderId, newPosition) =>
    {
        console.log("folderId", folderId)
        console.log("newPosition", newPosition)
        try
        {
            await ResourcesService.cambiarOrdenDeCarpeta(folderId, newPosition);
        }
        catch (error)
        {
            NotificationManager.error("No se pudo cambiar el orden de la lista.")
        }
    }

    return <MisRecursosContext.Provider value={{
        recursos,
        likeFolders,
        sharedFolders,
        createFolder,
        renameFolder,
        deleteFolder,
        unlikeResource,
        deleteModdleResource,

        selectFolder,
        allFolders,
        activeFolder,
        activeFolderId,
        selectedFolderId: selectedFolderId,

        updateLikeList,
        updateFolderList,
        changePositionLike,
        changePositionFolder,
    }}>
        { children }
    </MisRecursosContext.Provider>
}

export const useMisRecursos = () =>
{
    return React.useContext(MisRecursosContext);
}
