import portal from '../api/portal';
import rea from '../api/rea';

const ResourcesService = {
    // substring a filters porque siempre viene "?nombre_del_filtro=valor_del_filtro"

    /**
     * @method allResources 
     * @params id: Number, query: URLSearchParams.toString()
     * @description Obtiene todos los recursos, soportando paginación, limit y filters
     */
    allResources: (query = '') => portal.get(`resource/list${query ? `?${query}` : query}`),
    /**
     * @method oneResource 
     * @params id: Number
     * @description Obtiene un recurso
     */
    oneResource: (id) => portal.get(`resource/get/${id}`),
    /**
     * @method oneCollection 
     * @params id: Number
     * @description Obtiene una collecion de recurso
     */
    oneCollection: (id) => portal.get(`resource/getcollection/${id}`),
    /**
     * @method likeResource 
     * @params resourceId: Number, folder?: Number
     * @description Likea un recurso
     */
    likeResource: (resourceId, folder = null, data = {}) => (
        rea.post(`/like/${resourceId}/${folder}`, data)
    ),
    /**
     * @method unlikeResource 
     * @params resourceId: Number, folder?: Number
     * @description Quita like a un recurso
     */
    unlikeResource: (resourceId, folder = null) => (
        rea.delete(`/like/${resourceId}/${folder}`)
    ),
    /**
     * @method crearCarpetaRecursos
     * @description Elimina un recurso compartido en moodle
     */
    crearCarpetaRecursos : (title) => rea.post(`/like/folder`, {title}),

    cambiarNombreCarpetaRecursos : (folderId, title) => rea.patch(`/like/folder/${folderId}?title=${title}`),

    cambiarOrdenDeCarpeta : (folderId, position) => rea.patch(`/like/folder/${folderId}`, { new_position: position }),

    eliminarCarpetaRecursos : (folderId) => rea.delete(`/like/folder/${folderId}`),

    cambiarCarpetaDeRecurso : (recursoId, new_folder_id, oldFolderId = "") => (
        rea.patch(`/like/${recursoId}${oldFolderId !== "" ? `/${oldFolderId}` : ""}`, { new_folder_id: new_folder_id })
    ),

    cambiarOrdenDeRecurso : (recursoId, folderId = '', position) => (
        rea.patch(`/like/${recursoId}${folderId ? `/${folderId}` : ''}`, { new_position: position })
    ),

    getCollectionResources : (recursoId) => portal.get(`/resource/getcollection/${recursoId}`),
};

export { ResourcesService as default }
