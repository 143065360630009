import React from "react";

const PopoverContext = React.createContext(undefined);

export const PopoverContextProvider = ({children, name, popoverNames}) =>
{
    const [ popoverIndex, setPopoverIndex ] = React.useState(null);

    const currentPopover = (popoverIndex >= 0 && popoverIndex < popoverNames.length)
        ? popoverNames[popoverIndex]
        : null
        ;

    React.useEffect(() =>
    {
        if (!localStorage.getItem(`no_mostrar_tutorial_${name}`))
        {
            setPopoverIndex(0);
        }
    }, [])

    return (
<PopoverContext.Provider value={{
    next: () =>
    {
        setPopoverIndex(popoverIndex + 1);
    },
    close: () =>
    {
        setPopoverIndex(null);
    },
    ready: () =>
    {
        setPopoverIndex(null);
        localStorage.setItem(`no_mostrar_tutorial_${name}`, "1")
    },
    isLast: popoverIndex === popoverNames.length - 1,
    currentPopover,
}}>
    { children }
</PopoverContext.Provider>
    )
}

export const usePopover = () =>
{
    return React.useContext(PopoverContext);
}
