import React from "react";

export default function AudioPlayerControlsVolume({volumeBarRef, volumeBarButtonRef, audio, muteVolume, volumeControl})
{
    return (
<>
<div className="audioplayer-volume-button" title="" onClick={(e) => muteVolume(e, audio.id)}>
    <span/>
</div>
<div className="audioplayer-volume-bar" id={`btn-mute-${audio.id}`}>
    <div className="audioplayer-volume-adjust" ref={volumeBarRef} style={{ width: '100%' }}></div>
    <input
        ref={volumeBarButtonRef}
        max="100"
        type="range"
        className="audioplayer-volume-adjust-input"
        onChange={(e) => volumeControl(e, audio.id)}
    />
</div>
</>
    )
}
