import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useSwipeable } from 'react-swipeable';

export function GenericSideBar({title, children})
{
    const { open, showSideBar } = useGenericSideBar();
    const className = [ 'side-bar' ];
    if(open)
        className.push("active");

    return (
<>
<nav id="sidebar" className={className.join(" ")}>
    <GenericSideBarButton showSideBar={showSideBar}/>
    <div className='side-bar-container'>
        <div className="sidebar-header mb-3">
            <h6 className="text-uppercase font-weight-bold">{title}</h6>
        </div>
        { children }
    </div>
</nav>
</>
    )
}

export function GenericSideBarButton({showSideBar})
{
    const { open, setOpen } = useGenericSideBar();

    const collapse = useCallback(() =>
    {
        setOpen(!open);
    },
    [open, setOpen]);

    useEffect(() =>
    {
        if (showSideBar && showSideBar.dir === "Left" && open)
        {
            collapse();
        }
        if (showSideBar && showSideBar.dir === "Right" && !open)
        {
            collapse();
        }
    }, [showSideBar, open, collapse]);

    return (
<button
    type="button"
    id="sidebarCollapse"
    className={(() =>
    {
        const className = [ "navbar-btn", "btn-menu" ];
        if(open)
            className.push("active");
        return className.join(" ");
    })()}
    onClick={() => collapse()}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    {/* <span></span>
    <span></span> */}
</button>
    )
}

const GenericSideBarContext = React.createContext();

export function GenericSideBarContextProvider({children})
{
    const [ open, setOpen ] = React.useState();
    const [showSideBar, setShowSideBar] = React.useState(false);
    const handlers = useSwipeable({ onSwiped: (eventData) => setShowSideBar(eventData) });

    return <GenericSideBarContext.Provider value={{
        open,
        setOpen,
        show: open,
        setShow: setOpen,
        handlers,
        showSideBar: showSideBar,
    }}>
        {children}
    </GenericSideBarContext.Provider>
}

export function useGenericSideBar()
{
    return React.useContext(GenericSideBarContext);
}
