import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DatosAbiertos from '../components/institucionales/datosabiertos';
import {
    getAllRecursos
} from '../redux/actions/recursosAction'
import Loading from '../components/shared/loading';

const DatosAbiertosContainer = props => {
    const { history, location, match, recursos, getAllRecursos } = props;

    useEffect(() => {
        getAllRecursos()
    }, [getAllRecursos])

    if(recursos.all === null){
        return <Loading />
    }
    return (
        <DatosAbiertos
            history={history}
            location={location}
            match={match}
            recursos={recursos.all}
        />
    );
    
}

const mapStateToProps = ({recursos}) => {
    return {
        recursos,
    };
};

const mapDispatchToProps = {
    getAllRecursos
}

export default connect(mapStateToProps, mapDispatchToProps)(DatosAbiertosContainer);
