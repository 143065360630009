export default async function downloadAsTxt(fileName, data)
{
    if(window.showSaveFilePicker !== undefined)
    {
        const pickerOptions = {
            suggestedName: `${fileName}.txt`,
            types: [
                {
                    accept: { 'text/plain': ['.txt'] },
                },
            ],
        };

        const fileHandle = await (window).showSaveFilePicker(pickerOptions);

        if (fileHandle) {
            const writableFileStream = await fileHandle.createWritable();
            if (writableFileStream)
            {
                const taBlob = new Blob([data], { type: 'text/plain' });
                await writableFileStream.write(taBlob);
                await writableFileStream.close();
            }
        }
    }
    else
    {
        const blob = new Blob([data], { type: 'text/plain' });
        const downloadUrl = window.URL.createObjectURL(blob);

        const element = document.createElement('a');
        element.href = downloadUrl;
        element.download = `${fileName}.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}
