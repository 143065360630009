import React from 'react'
import { VIDEO, GALERIA_IMAGENES, LIBRO_ELECTRONICO, AUDIO, TEXTO, INTERACTIVO, COLECCION, PODCAST } from '../../util/constantes'

const IconRecurso = ({format, claseIcon}) => {
    return (
        <>
            {
                format === VIDEO &&
                <div className={`${claseIcon} videos shadow`}></div>
            }
            {
                format === GALERIA_IMAGENES &&
                <div className={`${claseIcon} galerias shadow`}></div>
            }
            {
                format === LIBRO_ELECTRONICO &&
                <div className={`${claseIcon} libros shadow`}></div>
            }
            {
                format === AUDIO &&
                <div className={`${claseIcon} dialogos shadow`}></div>
            }
            {
                format === TEXTO &&
                <div className={`${claseIcon} textos shadow`}></div>
            }
            {
                format === INTERACTIVO &&
                <div className={`${claseIcon} juegos shadow`}></div>
            }
            {
                format === COLECCION &&
                <div className={`${claseIcon} coleccion shadow`}></div>
            }
            {
                format === PODCAST &&
                <div className={`${claseIcon} icono_podcast shadow`}></div>
            }
        </>
    )
}

export default IconRecurso

