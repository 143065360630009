import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import eliminarP from "src/util/eliminarP";
import ReactHtmlParser from 'html-react-parser';

export default function VideoSeasonBoxChapter({resource, season, chapter})
{
    const history = useHistory();

    return (
    // <button id="episode" className="btn-lg" type="button" role="link">
<>
    <button id={`season-${chapter.id}`} key={chapter.id} className={`btn-lg btn-episode${chapter.resource_id === resource.resource_id ? " selected" : ""}${season.id === resource.season_id ? "" : " collapsed"}`} type="button" role="link" /* onClick={() => reproducir(episodios)} */>
        {/* <a href="#REA" className=''> */}
        <div className='btn-details-container'>
            <div className="episode-details" onClick={(event) => { event.preventDefault(); history.push(`/recurso/${chapter.resource_id}`) }}>
                <div className='flecha-container'>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns_xlink="http://www.w3.org/1999/xlink" width="1920" height="1080" viewBox="0 0 1920 1080">
                        <defs>
                        <clipPath id="clip-Web_1920_1">
                            <rect width="1920" height="1080"/>
                        </clipPath>
                        </defs>
                        <g id="Web_1920_1" data-name="Web 1920 – 1" clip-path="url(#clip-Web_1920_1)">
                        <rect width="1920" height="1080" fill="#fff"/>
                        <g id="Grupo_1519" data-name="Grupo 1519" transform="translate(58 44.623)">
                            <line id="Línea_43" data-name="Línea 43" x2="10.419" y2="8.591" fill="none" stroke="#000" stroke-linecap="round" stroke-width="3"/>
                            <line id="Línea_44" data-name="Línea 44" y1="8.408" x2="10.419" transform="translate(0 8.591)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="3"/>
                        </g>
                        </g>
                    </svg> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#000000" viewBox="0 0 256 256">
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline points="96 48 176 128 96 208" fill="none" className='svg-arrow-episode' stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></polyline>
                    </svg>
                    {/* <span className='primero'></span>
                    <span className='segundo'></span> */}
                </div>

                <span id={`episode-title-${chapter.id}`} className="episode-title">
                    {ReactHtmlParser(eliminarP(chapter.title))}

                </span>
                <UncontrolledTooltip placement="right" target={`episode-title-${chapter.id}`} >
                    <div className="notMarginButton">{ ReactHtmlParser(chapter.title) }</div>
                </UncontrolledTooltip>
            </div>
            <span className='espacio-minutos'>
                {chapter.duration}'
            </span>
            <a href={chapter.download_url}>
                <div className='svg-container'>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns_xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 225 182" xml_space="preserve" className='svg-img'>
                        <g>
                        <path d="M175.1,174H55.9C43.3,174,33,163.7,33,151.1V130c0-2.8,2.2-5,5-5s5,2.2,5,5v21.1c0,7.1,5.8,12.9,12.9,12.9H175
                            c7.1,0,12.9-5.8,12.9-12.9V130c0-2.8,2.2-5,5-5s5,2.2,5,5v21.1C198,163.7,187.7,174,175.1,174z"/>
                    </g>
                    <g>
                        <path d="M115,156c-1.4,0-2.7-0.6-3.7-1.6L59,98.4c-1.4-1.4-1.7-3.6-1-5.4c0.8-1.8,2.6-3,4.6-3h21.6V39c0-2.8,2.2-5,5-5h51.4
                            c2.8,0,5,2.2,5,5v51h20.7c2,0,3.8,1.2,4.6,3s0.4,3.9-0.9,5.4l-51.4,56C117.7,155.4,116.4,156,115,156L115,156z M74.1,100l40.8,43.6
                            L155,100h-14.3c-2.8,0-5-2.2-5-5V44H94.3v51c0,2.8-2.2,5-5,5H74.1z"/>
                    </g>
                    </svg>
                </div>
            </a>
        </div>
    </button>
</>
    )
}
