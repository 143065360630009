import { avisoDeSalida, isMobile } from "./env";

export const getDomain = (url, event) => (
    window.location.hostname.replace('www.','').split('.').slice(-3).join('.')
);

export const originUrl = (url) => (
    (new URL(url)).origin
);

export const sameOrigin = (href) => (
    (href instanceof URL ? href.origin : href).split('/')[2].split(':')[0].split('.').splice(-3,3).join('.') === window.location.origin.split('/')[2].split(':')[0].split('.').splice(-3,3).join('.')
);

export const cleanUrl = (url) => (
    url ? (url instanceof URL ? url.href : (typeof url === "string" ? url : '')).replace('#REA','') : ""
);

export const redirect = (url) => (
    window.location.href = url
);

export const redirectConAviso = (urlString, event) => {
    if(!urlString.startsWith('http')) return;

    const url = new URL(urlString);
    if(url && !sameOrigin(url)) {
        if(isMobile() && !window.confirm(avisoDeSalida)) {
            event.preventDefault();
        }
    }
}

/* 
export const getDomain = (url, event) => {
    var local = window.location.hostname.replace(/^[^.]+\./g, "");
    var jmanso = "http://recursos.juanamanso.edu.ar".replace(/^[^.]+\./g, "");
    var educar = "https://.educ.ar".replace(/^[^.]+\./g, "");
    var prefix = /^https?:\/\//i;
    var domain = /^[^/:]+/;
    url = url.replace(prefix, "");

    if (url.charAt(0) === "/") {
            url = window.location.hostname + url;
    }
    var match = url.match(domain);
    if (match) {
            var urlClick = match[0].replace(/^[^.]+\./g, "");

            if (urlClick.includes(jmanso) || urlClick.includes(educar) || urlClick.includes(local)) {
            } else {
                    if(!isMobile()){
                            return;
                    }
                    if (window.confirm('Al dejar este espacio es posible que comiences a consumir datos de tu plan de conectividad.')) {
                            return;
                    } else {
                            event.preventDefault();
                    }
            }
    }

}
 */