import {
    GET_HEADER,
    GET_HEADER_SUCCESS,
    GET_HEADER_FAILURE
} from '../types';

const INIT_STATE = {
    menu: null,
    logo: null,
    loading: true
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_HEADER:
            return {
                ...state,
                loading: true
            }
        case GET_HEADER_SUCCESS:
            return {
                ...state,
                menu: action.payload.menu,
                logo: action.payload.logo,
                loading: false
            }
        case GET_HEADER_FAILURE:
            return {
                ...state,
                menu: null,
                logo: null,
                loading: false
            }
        default: return { ...state };
    }
}
