import {
    GET_FOOTER,
    GET_FOOTER_SUCCESS,
    GET_FOOTER_FAILURE
} from '../types';

const INIT_STATE = {
    menu: null,
    logo: null,
    logo_centro: null,
    text: null,
    loading: true
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FOOTER:
            return {
                ...state,
                loading: true
            }
        case GET_FOOTER_SUCCESS:
            return {
                ...state,
                menu: action.payload.menu,
                logo: action.payload.logo,
                logo_centro: action.payload.logo_centro,
                text: action.payload.text,
                loading: false
            }
        case GET_FOOTER_FAILURE:
            return {
                ...state,
                menu: null,
                logo: null,
                text: null,
                loading: false
            }
        default: return { ...state };
    }
}
