import React from 'react';
import iconodialogos from '../../assets/css/iconos/dialogos.svg';
import BreadCrumb from '../shared/breadcrumb';

const Rea = props => {
    const { history, match} = props;

    return (
        <div className="main_content">
            <div className="container-fluid">
                <div className="col-xl-8 offset-xl-2 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={'Qué es REA'} previousPositions={['Inicio']}/>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 text-center mb-5">
                            <h1>Qué es REA</h1>
                            <p>Creemos en la educación publica y gratuita y de esta forma colaboramos con cientos de miles de recursos para tu formación totalmente gratuitos.</p>
                        </div>
				    </div>
			    </div>
			</div>
			<div className="col-xl-10 offset-xl-1 col-12 mb-5 mt-5">
				<div className="container">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-6 text-justify">
								<p>Rea (Recursos Educativos Nacionales) es una iniciativa de Educar SE para dar solución a los nuevos tiempos y formas de aprendizaje que se avecinan, permitiendo el uso de los recursos digitales tanto para alumnos y docentes brindando una plataforma de interoperabilidad flexible y acorde a los estándares.</p>
								<p>En esta plataforma no solo podrás buscar y consumir los distintos recursos educativos, ya sean auditos, textos, vídeos u otros tipos de recursos digitales, sino también, incorporarlos para armar tus propias bibliotecas y si sos docente, tus propias clases.</p>
								<p>REA ofrece tanto a alumnos y docentes la posibilidad de utilizar los recursos en otras plataformas educativas, llamadas LMS, como también consumirlas desde otras aplicaciones ya que usamos protocolos abiertos y estándares para el consumo. En la sección <a href="#REA" onClick={(event) => {event.preventDefault(); history.push('/datosabiertos')}}>Protocolos</a>, tendrás la información adecuada para poder consumir los recursos desde otras plataformas, y sino, simplemente podes descargar los recursos y/o verlos desde la web.</p>
								<p>Creemos en la educación publica y gratuita y de esta forma colaboramos con cientos de miles de recursos para tu formación totalmente gratuitos.</p>
							</div>
							<div className="col-md-6 col-12">
								<div className="card-rea">
								<div className="row">
									<div className="col-12">
										<img src={iconodialogos} alt="Dialogos"/>
									</div>
								</div>
							</div>
							<div className="row">
									<div className="col-12">
										<p className="font-italic font-weight-bold">La navegación por este portal para datos moviles es gratuita, no sera cobrada por tu operadora de de celular en el ámbito de argentina, por lo cual no estas consumiendo recursos tuyos.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
 		</div>	
    );
}

export default Rea;
