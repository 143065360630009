import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RegistroComponent from 'src/components/auth/Registro';
import { isInMaintenanceMode } from 'src/helpers';
import { redirects } from 'src/routes';
import CheckForMaintenanceMode from './CheckForMaintenanceMode';

const RoutesMiddleware = ({ renderComponent: RenderComponent, ...props }) => (
<CheckForMaintenanceMode>
    <Switch>
        <Route exact path="/registro" component={RegistroComponent} />

        { /* Rutas de redirección */ }
        {
            !isInMaintenanceMode() && redirects && redirects.map((route, key) => (
                <Route exact key={key} path={`/${route.path}`} render={() => route.redirect} />
        ))}

        { /* Rutas normales */ }
        <Route path="/*" render={(routeProps) => props.children(routeProps)} />
    </Switch>
</CheckForMaintenanceMode>
)

export default RoutesMiddleware;
