import {
} from '../types';

const INIT_STATE = {
    aux: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        default: return { ...state };
    }
}
