import React from "react";
import { MisRecursosCreateFolderDialog } from "./dialog/MisRecursosCreateFolderDialog";

export const MisRecursosLikeFolderListHeader = () =>
{
    const [ newFolderDialogOpen, setNewFolderDialogOpen ] = React.useState(false);

    return (
<>
<div className="favorite-resource-list-header" id="Popover1">
    <h5 className="ml-1 pl-2" style={{flexGrow: 1}}>
        Mis Listas
    </h5>
    <div className="favorite-resource-list-add-button" onClick={() => setNewFolderDialogOpen(true)}>
        <i className="fa fa-plus-square-o"/>
    </div>
</div>
<hr className="mt-1 mb-3" />

<MisRecursosCreateFolderDialog
    open={newFolderDialogOpen}
    onClose={() => setNewFolderDialogOpen(false)}
/>

</>
    )
}
