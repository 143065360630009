import React from 'react';
import { Row, Col } from 'reactstrap';
import { useGenericSideBar } from 'src/components/sidebar/GenericSideBar';

export default function CategoriaFiltrosItem({filtro, categoria, history, location, alwaysVisible})
{
    const { setOpen } = useGenericSideBar();

    return (
<li className="term-item toggleable" style={alwaysVisible ? {} : { display: "none" }}
    onClick={() =>
    {
        setOpen(false);
        history.push(location.search.length === 0 ? `?${categoria.key}=${filtro.id}` : `${location.search}&${categoria.key}=${filtro.id}`)
    }}
>
    <Row className="mb-2">
        <Col sm="9" style={{ width: "60%" }}>
            <label className="container-ch mb-0">{filtro.display_name}</label>
        </Col>
        <Col sm="2" style={{ alignSelf: "center", width: "30%" }}>
            <span className="badge badge-secondary float-right">{filtro.total}</span>
        </Col>
    </Row>
</li>
    )
}
