import React from "react";
// import { UncontrolledTooltip } from "reactstrap";
import DownloadIcon from "./DownloadIcon";
import REATooltip from "../REATooltip";

export default function AudioPlayerButtonDownloadAudio({audio})
{
    function downloadAudio(event)
    {
        event.preventDefault();

        const element = document.createElement('a');
        element.href = audio.url;
        element.click();
    }

    return (
<button type="" id='btn-download' className="audio-btn" onClick={downloadAudio}>
    <DownloadIcon/>
    <REATooltip placement="right" target={`btn-download`} >
        <div className="notMarginButton">Descargar</div>
    </REATooltip>
</button>
    )
}
