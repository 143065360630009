const envDevelop = () => process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development';
const envProduction = () => (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production') || process.env.NODE_ENV === 'production';

const envProd = envProduction;
const envDev = envDevelop;

const isInMaintenanceMode = () => process.env.REACT_APP_MATENIMIENTO === "true";

const isDevice = (devices) => Boolean((navigator.userAgent || navigator.vendor || window.opera).match(devices));

const isMobile = () => isDevice(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);
const isIPhone = () => isDevice(/iPhone|iPad|iPod/i);

/**
 * Function to return error console for only developers
 */
function showDeveloperError(...messages) {
    if (envDevelop()) {
        var args = Array.prototype.slice.call(messages);
        console.error.apply(console, args);
    }
}

/**
 * Function to return message in console for only developers
 */
 function showDeveloperMessage(...messages) {
    if (envDevelop()) {
        var args = Array.prototype.slice.call(messages);
        console.log.apply(console, args);
    }
}

export {
    envDevelop,
    envDev,
    envProduction,
    envProd,
    isInMaintenanceMode,
    isMobile,
    isIPhone,
    showDeveloperError,
    showDeveloperMessage
}

export const soportaDictadoPorVoz = () => (
    navigator.userAgent.indexOf("Chrome") !== -1 && navigator.userAgent.indexOf("Edg") === -1
);

export const avisoDeSalida = "Al dejar este espacio es posible que comiences a consumir datos de tu plan de conectividad. ¿Deseas continuar?";

export const mostrarAvisoDeSalida = (target) => (e) => {
    if(isMobile()){
        e.preventDefault();
        if (window.confirm(avisoDeSalida)) {
                window.open(e.currentTarget.href, target);
        }
    }
}

export const getBrowserInfo = function () {
    var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
/*         if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    } */
};
