import React from "react";
import { Modal, ModalBody, Input, ModalFooter, Button } from 'reactstrap';
import { useMisRecursos } from "../MisRecursosContext";

export const MisRecursosCreateFolderDialog = ({folderToEdit, open, onClose}) =>
{
    const {
        createFolder,
        renameFolder,
    } = useMisRecursos();

    const [ nameValue, setNameValue ] = React.useState("");

    React.useEffect(() =>
    {
        if(!open)
            setNameValue("");
        else
            setNameValue(folderToEdit == null ? "" : folderToEdit.name);
    }, [open]);

    return (

<Modal isOpen={open} toggle={onClose}>
    <ModalBody style={{ padding: "2rem 2rem" }}>
        <h3 className="mb-3 modal-title">
            {folderToEdit ? "Editando" : "Creando"} Lista
        </h3>
        <label className="attachedLabel">
            Nombre de lista
        </label>
        <Input
            type="text"
            name="nameLista"
            style={{ height: 'auto' }}
            value={nameValue}
            onChange={(e) =>
            {
                setNameValue(e.target.value);
            }}
        >
        </Input>
    </ModalBody>
    <ModalFooter>

        <Button
            color='secondary'
            onClick={onClose}
        >
            Cancelar
        </Button>

        <Button
            color='primary'
            className="btn-conectar-igualdad"
            onClick={() =>
            {
                if(nameValue !== "")
                {
                    if(!folderToEdit)
                        createFolder(nameValue);
                    else
                        renameFolder(folderToEdit.id, nameValue);

                    onClose()
                }
            }}
        >
            {folderToEdit ? "Editar" : "Crear"} Lista
        </Button>
    </ModalFooter>
</Modal>
    )
}
