import React from "react";
import { convertDate } from "src/helpers";
import LicenceImage from "../base/images/LicenceImage";
import LinkToResourcesWithFilter from "../base/LinkToResourcesWithFilter";

const fichaFields = [
    { name: 'pedagogic_proposals', display: 'Eje PNIDE', className: "detalle-propuesta" },
    { name: 'levels', display: 'Niveles / Ciclos' },
    { name: 'subjects', display: 'Área / Disciplina' },
    { name: 'resourceType', display: 'Tipo de Recursos' },
    { name: 'categoryOfResource', display: 'Tipo de Propuesta' },
    { name: 'pedagogic_offer', display: 'Propuesta pedagógica' },
    { name: 'tags', display: 'Etiquetas' },
    { name: 'audiences', display: 'Persona destinataria' },
    { name: 'authors', display: 'Autores' },
    // { name: 'contributors', display: 'Otros Contribuyentes' },
    // { name: 'source', display: 'Fuente' },
]

export function VistaRecursosDetalleSidebar(props)
{
    const {
        recurso,
        history,
    } = props;
    const { filters } = recurso;
  
    const redirectByFilter = (e, filter, id) =>
    {
        e.preventDefault();

        if(filters)
            history.push(`/recursos?${filters[filter]}=${id}`);
    }

    return (
<div className="sidebar-body sb-b-recurso">
    <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Publicado el:</span><br />{convertDate(recurso.publication_date, 'YYYY-MM-DD HH:mm:ss', 'D [de] MMMM [del] YYYY', true)}</p>
    <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Última modificación el:</span><br />{convertDate(recurso.updated_at, 'YYYY-MM-DD HH:mm:ss', 'D [de] MMMM [del] YYYY', true)}</p>
    <hr />

    {/* Eje PNIDE */}
    { recurso.pedagogic_proposal_id != null &&
    <p style={{ lineHeight: "17px" }} className="detalle-propuesta">
        <span className="small font-weight-light">Eje PNIDE:</span>
        <br/>
        <LinkToResourcesWithFilter filters={filters} filterName="pedagogic_proposals" filterValue={recurso.pedagogic_proposal_id}>
            {recurso.pedagogic_proposal_description}
        </LinkToResourcesWithFilter>
        <br/>
    </p>
    }

    {/* Niveles/Ciclos */}
    {
        recurso.levels.length > 0 &&
        <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Niveles / Ciclos:</span><br />
        {
            recurso.levels.map(level => {
                if(level.subLevel && level.subLevel.length > 0){
                    level.subLevel.sort((a,b) => a.id - b.id)
                    return (
                        <React.Fragment key={level.id}>
                            {/* <a href="#REA" onClick={(e) => { redirectByFilter(e, 'levels', level.id) }}>{level.description}</a><br /> */}
                            <span className="recurso-item" href="#REA">{level.description}</span><br />
                            {
                                level.subLevel && level.subLevel.length > 0 ? level.subLevel.map(sublevel => {
                                    return(
                                        <React.Fragment key={sublevel.id}>
                                            {/* <a href="#REA" className="small font-weight-bold" style={{marginLeft: "5%"}} onClick={(e) => { redirectByFilter(e, 'levels', level.id) }}>{sublevel.description}</a><br /> */}

                                            <LinkToResourcesWithFilter filters={filters} filterName="levels" filterValue={sublevel.id}>
                                                {sublevel.description}
                                            </LinkToResourcesWithFilter>
                                           {/*  <span className="small font-weight-bold recurso-item" style={{marginLeft: "5%"}} href="#REA">{sublevel.description}</span> */}<br />
                                        </React.Fragment>
                                    )}
                                ):
                                <></>
                            }
                        </React.Fragment>
                    )
                }else{

                    return(
                        <React.Fragment key={level.id}>
                            {/* <a href="#REA" onClick={(e) => { redirectByFilter(e, 'levels', level.id) }}>{level.description}</a><br /> */}
                            <LinkToResourcesWithFilter filters={filters} filterName="levels" filterValue={level.id}>
                                {level.description}
                            </LinkToResourcesWithFilter>
                            {/* <span className="recurso-item" href="#REA">{level.description}</span> */}
                            <br />
                        </React.Fragment>
                    )
                }
            
            })
        }
        </p>
    }

    {/* Área / disciplina */}
    {
        recurso.subjects.length > 0 &&
        <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Área / disciplina</span><br />
            {
                recurso.subjects.map(subject => {
                    return (
                        <React.Fragment key={subject.id}>
                            <LinkToResourcesWithFilter filters={filters} filterName="subjects" filterValue={subject.id}>
                                {subject.description}
                            </LinkToResourcesWithFilter>
                            <br/>
                        </React.Fragment>
                    )
                })
            }
        </p>
    }

    {/* Tipo de recursos */}
    {
        (recurso.resourceType && recurso.resourceType.id)  &&
        <p style={{ lineHeight: "16px" }}>
            <span className="small font-weight-light">Tipo de recursos:</span>
            <br/>
            <LinkToResourcesWithFilter filters={filters} filterName="resource_types" filterValue={recurso.resourceType.id}>
                {recurso.resource_type_description}
            </LinkToResourcesWithFilter>
            <br/>
        </p>
    }
    {
        (recurso.category_id && recurso.category_description)  &&
        <p style={{ lineHeight: "16px" }}>
            <span className="small font-weight-light">Tipo de propuesta:</span>
            <br/>
            <LinkToResourcesWithFilter filters={filters} filterName="category" filterValue={recurso.category_id}>
                {recurso.category_description}
            </LinkToResourcesWithFilter>
            <br/>
        </p>
    }

    {/* Propuesta Pedagógica */}
    {
        recurso.pedagogic_offer_id &&
        <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Propuesta Pedagógica:</span><br />
            <LinkToResourcesWithFilter filters={filters} filterName="pedagogic_offerings" filterValue={recurso.pedagogic_offer_id}>
                {recurso.pedagogic_offer_description}
            </LinkToResourcesWithFilter>
            {/* <React.Fragment key={recurso.pedagogic_offer_id}>
                <a href="#REA" onClick={(e) => { redirectByFilter(e, 'pedagogic_offers', recurso.pedagogic_offer_id) }}>{recurso.pedagogic_offer_description}</a><br />
            </React.Fragment> */}
        </p>
    }


    {/* Etiquetas */}
    {
        recurso.tags.length > 0 &&
        // <p style={{ lineHeight: "16px" }} className="mb-0"><span className="small font-weight-light">Etiquetas:</span><br />
        <div style={{ lineHeight: "16px", marginBottom: '1rem' }}><span className="small font-weight-light">Etiquetas:</span><br/>
        <div className='contenedor-etiquetas'>
            {
                recurso.tags.map(tag => {
                    return (
                        <LinkToResourcesWithFilter
                            key={tag.id}
                            style={{
                                whiteSpace:"normal",
                                marginRight: "0.3rem",
                            }}
                            filters={filters}
                            filterName="tags"
                            filterValue={tag.id}
                        >
                            #{tag.description}
                        </LinkToResourcesWithFilter>
                    )
                })
            }
        </div>
        </div>
    }

    {/* Persona destinataria */}
    {
        recurso.audiences.length > 0 &&
        <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Persona destinataria:</span><br />
            {
                recurso.audiences.map(audience => {
                    return (
                        <React.Fragment key={audience.id}>
                            {/* <a href="#REA" onClick={(e) => { redirectByFilter(e, 'audiences', audience.id) }}>{audience.description}</a><br /> */}
                            <span className="recurso-item" href="#REA">{audience.description}</span><br />
                        </React.Fragment>
                    )
                })
            }
        </p>
    }

    {/* Autores */}
    {
        recurso.authors.length > 0 &&
        <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Autor/es:</span><br />
            {
                recurso.authors.map(author => {
                    return (
                        <React.Fragment key={author.id}>
                            {/* <a href="#REA"  onClick={(e) => { redirectByFilter(e, 'authors', author.id) }}>{author.description}</a><br /> */}
                            <span className="recurso-item" href="#REA">{author.description}</span><br />
                        </React.Fragment>
                    )
                })
            }
        </p>
    }

    {/* Licencia */}
    <p style={{ lineHeight: "16px" }} className="mb-0 licencia">
        <span className="small font-weight-light">Licencia:</span><br />
        <LicenceImage/>
    </p>


    {/* {
        recurso.contributors.length > 0 &&
        <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Otros contribuyentes:</span><br />
            {
                recurso.contributors.map(contributor => {
                    return (
                        <Fragment key={contributor.id}>
                            <a href="#REA" onClick={(e) => { redirectByFilter(e, 'contributors', contributor.id) }}>{contributor.description}</a><br />
                        </Fragment>
                    )
                })
            }
        </p>
    } */}

    {/* {
        recurso.source &&
        <p style={{ lineHeight: "16px" }}><span className="small font-weight-light">Fuente:</span><br /> {recurso.source}</p>
    } */}

</div>
        )
}
