import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore } from './redux/store';

import "./assets/css/bootstrap.css";
import "./assets/css/custom.css";
import "./assets/css/conectarigualdad.css";
import "./assets/css/iconos.css";
import "fontsource-roboto"
import '../node_modules/font-awesome/css/font-awesome.min.css';

import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

import 'react-notifications/lib/notifications.css';
import './assets/css/project.css';

import './styles/index.scss';

import AppContainer from './containers/AppContainer';
import RoutesMiddleware from './middleware/RoutesMiddleware';

const App = () => (
	<Provider store={configureStore()}>
		<Router>
			<RoutesMiddleware>
				{(props) => (
					<AppContainer {...props}/>
				)}
			</RoutesMiddleware>
		</Router>
	</Provider>
);

export default App;
