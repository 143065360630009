import React from 'react';
import { connect } from 'react-redux';
import Rea from '../components/institucionales/rea';

const ReaContainer = props => {
    const { history, match } = props;

    return (
        <Rea
            history={history}
            match={match} 
        />
    );
}

const mapStateToProps = () => {
    return {
        
    };
};

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ReaContainer);