import React from 'react';
import ResourceCard from './resources/ResourceCard';

const ListadoRecursos = (props) => {
    const { recursos }=props

/* 
    const showOrHideTags = (transition, id) => {

        var elementShow = document.getElementsByClassName(`showTags_${id}`);
        var elementHide = document.getElementsByClassName(`hideTags_${id}`);
        var badgesTags = document.getElementsByClassName(`badge-tag-${id}`);

       if(transition === 'show')
       {
         for (let index = 0; index < badgesTags.length; index++) {
             const element = badgesTags[index]; 

             if(element){
                const elHide = element.classList.contains('d-none');
                if(elHide){
                    element.classList.remove('d-none');
                    element.classList.add('d-active');
                }
            }

         }  
         elementShow[0].classList.add('d-none');
         elementHide[0].classList.remove('d-none');
       }else{

         for (let index = 0; index < badgesTags.length; index++) {
            const element = badgesTags[index]; 
            if(element){
                const elHide = element.classList.contains('d-active');
                if(elHide){
                    element.classList.remove('d-active');
                    element.classList.add('d-none');
                }
            }
         }   
         elementShow[0].classList.remove('d-none');
         elementHide[0].classList.add('d-none');
       }
    }
 */

    return (
        <div id="products" className="recursos">
            {
                recursos && recursos.length > 0 ?
                recursos.map(recurso => {
                    return (
                        <ResourceCard resource={recurso} key={recurso.resource_id} />
                    )
                }) : (
                    <div name="recursosListados" className="item col-12" style={{marginBottom:"10px"}}>
                        <h2>No se encontraron resultados.</h2>
                        <p>Te sugerimos que revises la ortografía o utilices términos más generales.</p>
                    </div>
                )
            }
        </div>

    )
}

export default ListadoRecursos