// import BackofficeService from '../../services/backofficeService';
import { BackofficeService } from 'src/axios';
import {
    GET_HEADER,
    GET_HEADER_SUCCESS,
    GET_HEADER_FAILURE,
    GET_FOOTER,
    GET_FOOTER_SUCCESS,
    GET_FOOTER_FAILURE,
    GET_CONTACT,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAILURE,
    GET_TAG_BACKOFFICE,
    GET_TAG_BACKOFFICE_SUCCESS,
    GET_TAG_BACKOFFICE_FAILURE,
    GET_USERMENU_BACKOFFICE,
    GET_USERMENU_BACKOFFICE_SUCCESS,
    GET_USERMENU_BACKOFFICE_FAILURE
} from '../types';

export const getHeader = () => async (dispatch) => {
    dispatch({ type: GET_HEADER })
    try {
        const menu        = await BackofficeService.fetchHeaderMenu();
        const logo        = await BackofficeService.fetchHeaderLogo();

        dispatch({
            type: GET_HEADER_SUCCESS,
            payload: {
                menu: menu,
                logo: logo
            }
        });
    } catch (error) {
        dispatch({ type: GET_HEADER_FAILURE })
        console.log("Error al obtener la data del header: ", error)
    }
}

export const getFooter = () => async (dispatch) => {
    dispatch({ type: GET_FOOTER })
    try {
        const menu        = await BackofficeService.fetchFooterMenu();
        const logo        = await BackofficeService.fetchFooterLogo();
        const logo_centro = await BackofficeService.fetchFooterLogoCentro();
        const text        = await BackofficeService.fetchFooterText();

        dispatch({
            type: GET_FOOTER_SUCCESS,
            payload: {
                menu: menu,
                logo: logo,
                logo_centro: logo_centro,
                text: text.text
            }
        })
    } catch (error) {
        dispatch({ type: GET_FOOTER_FAILURE })
        console.log("Error al obtener la data del footer: ", error)
    }
}

export const getContact = () => async (dispatch) => {
    dispatch({ type: GET_CONTACT })
    try {
        const result = await BackofficeService.fetchContactText()
        dispatch({
            type: GET_CONTACT_SUCCESS,
            payload: result.data.contact
        })
    } catch (error) {
        dispatch({ type: GET_CONTACT_FAILURE })
        console.log("Error al obtener la data del CONTACTO: ", error)
    }
}

export const getVisitorRegister = () => async (dispatch) => {
    dispatch({ type: GET_TAG_BACKOFFICE })
    try {
        const result = await BackofficeService.fetchVisitorRegister()
        dispatch({
            type: GET_TAG_BACKOFFICE_SUCCESS,
            payload: result.data
        })
    } catch (error) {
        dispatch({ type: GET_TAG_BACKOFFICE_FAILURE })
        console.log("Error al obtener el tag de registro de visitas: ", error)
    }
}

export const getUserMenuBackoffice = () => async (dispatch) => {
    dispatch({ type: GET_USERMENU_BACKOFFICE })
    try {
        const result = await BackofficeService.fetchUserMenu();
        dispatch({
            type: GET_USERMENU_BACKOFFICE_SUCCESS,
            payload: result
        })
    } catch (error) {
        dispatch({ type: GET_USERMENU_BACKOFFICE_FAILURE })
        console.log("Error al obtener la data del tag de registro de visitas: ", error)
    }
}