import React, { useEffect, /* useState */ } from 'react';

import { connect } from 'react-redux';
import { /* NotificationManager */ } from 'react-notifications';

import { getDataUser } from '../redux/actions/authActions';
import { getRolesUser } from '../redux/actions/classroomsActions';
import { /* ResourcesService */ } from 'src/axios';
import {
    getAllRecursosDocentes,
    getRecursoById
} from '../redux/actions/recursosAction'

import { VistaRecursosLista } from '../components/recursos/VistaRecursosLista';
import { VistaRecursosDetalle } from '../components/recursos/VistaRecursosDetalle';
import { GenericSideBarContextProvider } from 'src/components/sidebar/GenericSideBar';

const PublicacionesContainer = props => {
    const { location, match, /* history, */ recursos, authReducer, getAllRecursosDocentes, getRecursoById, getRolesUser } = props;

    const handleGetRecursos = (page, filters = "") => {
        props.getAllRecursosDocentes(page, filters);
        window.scrollTo(0, 0);
    }

    useEffect(() =>
    {
        if (match.path === "/publicaciones" && location.search === "")
        {
            getAllRecursosDocentes();
        }
        else if(match.path === "/publicaciones" && location.search !== "")
        {
            getAllRecursosDocentes(1, location.search);
        }
        else
        {
            getRecursoById(match.params.recursoId);
            getRolesUser();
        }
    }, [match.path, location.search, match.params.recursoId, getAllRecursosDocentes, getRecursoById, getRolesUser])

    return (
<GenericSideBarContextProvider>
    { (match.path === "/publicaciones/:recursoId") || (match.path === "/publicaciones/:recursoId/:recursoTitulo")
        ? <VistaRecursosDetalle
            recursos={recursos}
            authReducer={authReducer}
            handleGetRecursos={handleGetRecursos}
            breadcrumbCurrent={"/publicaciones"}
            breadcrumbPrevious={["/home"]}
            renderSearchInput={false}
        />
        : <VistaRecursosLista
            recursos={recursos}
            authReducer={authReducer}
            handleGetRecursos={handleGetRecursos}
            breadcrumbCurrent={"/publicaciones"}
            breadcrumbPrevious={["/home"]}
            renderSearchInput={false}
        />
    }
</GenericSideBarContextProvider>
    )
}

const mapStateToProps = ({ recursos, authReducer }) => {
    return {
        recursos,
        authReducer
    };
};

const mapDispatchToProps = {
    getAllRecursosDocentes,
    getRecursoById,
    getDataUser,
    getRolesUser
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicacionesContainer);
