import React from 'react';
import { ApiResourceButton } from './api/ApiResourceButton';
import { ShareResourceButton } from './share/ShareResourceButton';
import { ResourceActionSeparator } from './ResourceActionSeparator';
import { LikeResourceButton } from './like/LikeResourceButton';
import { ResourceActionBox } from './ResourceActionBox';

const ResourceActionBoxColeccion = ({ resource_id, ...props }) =>
{
    const view = 'detail'

    return(
<ResourceActionBox>

    { /* Like */ }
    <LikeResourceButton
        resourceId={Number(resource_id)}
        resourceType={2}
    />

    { /* Share */ }
    <ShareResourceButton view={view} resourceId={resource_id} isCollection/>

    { /* Separator */ }
    <ResourceActionSeparator/>
    
    { /* Resource api */ }
    <ApiResourceButton view={view} resourceId={resource_id} isCollection={true}/>

</ResourceActionBox>
    );
}

export default ResourceActionBoxColeccion;
