import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../shared/loading'
import BreadCrumb from '../shared/breadcrumb'
import { MoodleService } from 'src/axios'
import { hasCookie } from 'src/helpers/cookie'

const MisAulas = (props) => {
    const [aulas, setAulas] = useState(null)
    const { history, match, authReducer, getDataUser } = props
    // const { user } = props.authReducer

    const getAulasUser = () => {
        // fetch(`${process.env.REACT_APP_REGISTER_URL}/api/integracion/oauth/moodle/${user.cuil}`, {
        MoodleService.getAulas().then(results => {
            if(results.data && results.data.length > 0){
                setAulas(results.data)
            }else{
                window.location.href=`${process.env.REACT_APP_REGISTER_URL}/auth/new`
            }
        })
    }

    useEffect(() => {
        if(hasCookie('logged_in')){
            if(authReducer.user === null){
                getDataUser()
            }else{
                getAulasUser()
            }
        }
    }, [authReducer.user_logued, authReducer.user, getDataUser])

    window.location = `${process.env.REACT_APP_BASE_URL_OAUTH}/oauth/authorize?client_id=${process.env.REACT_APP_GESTION_AULAS_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_GESTION_AULAS_REDIRECT_URL)}&response_type=code&scope=`;

    if (aulas === null) {
        return (
            <Loading />
        )
    }
    return (
        // ${process.env.REACT_APP_REGISTER_URL}/auth/new
        <div className="main_content">

            <div className="container-fluid">
                <div className="col-xl-8 offset-xl-2 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={"Mis Aulas"} previousPositions={["Inicio"]} />
                    <div className="row mb-5">
                        <div className="col-md-12 col-12">
                            <h2>Aulas virtuales</h2>

                            <div className="row mt-4 px-3">
                                {
                                    aulas.map(aula => {
                                        return (
                                            <div className="col-md-6 col-12 mt-3" key={aula.nombre}>
                                                <a href={`${aula.url}`}>
                                                    <div className="card bg-card jm3" >
                                                        <div className="card-body" style={{ height: "200px" }}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h4 className="font-weight-bold lh-30">{aula.nombre}</h4>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <p className="font-weight-light max-ch">
                                                                    {
                                                                        (aula.nombre.toLowerCase().indexOf("primaria") !== -1 || aula.nombre.toLowerCase().indexOf("secundaria") !== -1 || aula.nombre.toLowerCase().indexOf("inicial") !== -1) &&
                                                                        "Ingresá a tus aulas"
                                                                    }
                                                                    {
                                                                        aula.nombre.toLowerCase().indexOf("sistema") !== -1 &&
                                                                        "Administrá tus instituciones y aulas"
                                                                    }
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="icono-home dialogos" style={{ backgroundPosition: "center initial" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ authReducer }) => {
    return {
        authReducer
    };
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MisAulas);
