import React from "react";
import { PopoverCard } from "./PopoverCard";

export const PopoverCard2 = () =>
{
    return (
<PopoverCard name="Popover2" placement="bottom" extraClassName="widthPopper">
    &#8226; Arrastrá un recurso hacia arriba o hacia abajo para modificar su orden.<br />
    &#8226; Visualizá el recurso completo con la opción "Ver" en <i className="fa fa fa-ellipsis-v ml-2"/><br />
    &#8226; Cambiá un recurso de lista con la opción "Mover" en <i className="fa fa fa-ellipsis-v ml-2"/><br />
    &#8226; Borrá un recurso con la opción "Eliminar" en <i className="fa fa fa-ellipsis-v ml-2"/><br /><br />
</PopoverCard>
    )
}
