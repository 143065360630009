import { combineReducers } from "redux";
import allReducer from "./allReducer";
import recursosReducer from "./recursosReducer";
import authReducer from "./authReducer";
import headerReducer from "./headerReducer";
import footerReducer from "./footerReducer";
import aulasReducer from "./aulasReducer";


const rootReducer = combineReducers({
    all: allReducer,
    recursos: recursosReducer,
    authReducer: authReducer,
    header: headerReducer,
    footer: footerReducer,
    aulasUser: aulasReducer
});

export default rootReducer;
