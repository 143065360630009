import React from "react"
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import HTMLReactParser from "html-react-parser";

import { ResourcesService } from "src/axios";
import Loading from "../shared/loading";
import BreadCrumb from "src/components/shared/Breadcrumb2";
import BarraBusqueda from 'src/components/recursos/barraBusqueda';

import ResourceActionBoxColeccion from "../recursos/resources/actions/ResourceActionBoxColeccion";
import { getDataUser } from "src/redux/actions/authActions";

import { GenericSideBarContextProvider } from "../sidebar/GenericSideBar";
import prettifyDate from "src/util/prettifyDate";
import LicenceImage from "../base/images/LicenceImage";
import CollectionDataSheet from "./CollectionDataSheet";
import CollectionResourceCard from "./CollectionResourceCard";
import CollectionResourceCards from "./CollectionResourceCards";
import eliminarP from "src/util/eliminarP";
import { RESOURCE_TYPES } from "src/util/constantes";
import { showDeveloperMessage } from "src/helpers";
import { stripHTML } from "src/helpers/string";

const NoticiasCollection = ({ location, history, match, props }) =>
{
  const [ dataCollection, setDataState ] = React.useState();
  const params = useParams();

  const dispatch = useDispatch();

  React.useEffect( () => {
    ResourcesService.getCollectionResources(params.recursoId)
    //  Obtiene data de la response
    .then( (r) => r.data)
    //  setea el estado de dataCollection
    .then( (data) => { 
      setDataState(data);
      showDeveloperMessage('Coleccion', data);
    })
  },[params.recursoId])
  
  //  Chequea que data collection no sea nulo, si es nulo se queda cargando
  if(!dataCollection) {
    return <Loading />;
  }

  const { collection, 
          subjects, 
          tags, 
          audiences, 
          authors, 
          levels, 
          publication_date, 
          created_at,
          pedagogic_proposal_description } = dataCollection;

  // console.log(publication_date);

    // const elementos = Object.values(dataCollection).map((a) => {
    //   console.log(a)
    // })

  // console.log('dataCollection', dataCollection);

  const rawTitle = stripHTML(dataCollection.title);

  return (
    <>
      <GenericSideBarContextProvider>
        <main role="main" className="flex-shrink-0">
          <div className="container">
              <div className="container-fluid">
                  <div className="col-12" style={{ marginLeft: '-30px' }}>
                    <BreadCrumb
                      currentPosition={rawTitle.length > 60 ? `${rawTitle.substring(0, 60)}...` : rawTitle}
                      previousPositions={['/home', '/recursos']}
                    />
                  </div>
              </div>
              <div className="row">
                <div className="col-12 component-container">
                  <BarraBusqueda
                    location={location}
                    history={history}
                    detalle={true}
                  /> 
                  <div className="collection-container">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="collection-title" style={{textTransform: 'none'}}>{ HTMLReactParser(dataCollection.title) }</h2>
                        {
                          collection.cover_image
                            ? <img className="collection-image" src={collection.cover_image} alt="Img Colecciones"/>
                            : <div></div>
                        }
                        <p className="collection-copete">
                          { HTMLReactParser(eliminarP(dataCollection.subtitle)) }
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 iconos-container">
                        <div className="m-2 iconos-container" >
                          <ResourceActionBoxColeccion resource_id={params.recursoId}/>
                        </div>
                      </div>
                    </div>                    
                    <div className="row">
                      <CollectionResourceCards collection={collection} resource_id={dataCollection.resource_id} />
                    </div>
                    <div className="row">
                      <div className="col-md-8 col-12 mx-auto mt-5">
                        <CollectionDataSheet dataCollection={dataCollection}/>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </main>
      </GenericSideBarContextProvider>
    </>
  )
}

export default NoticiasCollection;
