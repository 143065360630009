import React from "react";
import { MenuItem } from '@material-ui/core';

export const ShareResourceButtonMenuItem = ({children, ...props}) =>
{
    return (
<MenuItem className='p-0 p-0'>
    <a
        target="_blank"
        rel="noopener noreferrer"
        className="pr-1 pl-1"
        style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '6px',
        }}
        {...props}
    >
        {children}
    </a>
</MenuItem>
    )
}
