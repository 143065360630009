import HTMLReactParser from "html-react-parser";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import eliminarP from "src/util/eliminarP";
import REATooltip from "../REATooltip";
import TranscriptIcon from "./TranscriptIcon";
import { unescapeHtml } from "src/util/unescapeHtml";

export default function AudioPlayerButtonDownloadTranscription({audio, open})
{
    const downloadTranscript = (event) =>
    {
        event.preventDefault();

        const s = audio.transcription;
        const fileName = audio.file.split('.')[0] + ".txt";

        const cleaned = unescapeHtml(s.replace(/<\/?[^>]+(>|$)/g, ''));
        const blob = new Blob([cleaned], {type: 'text/plain'});
        const downloadUrl = window.URL.createObjectURL(blob);

        const element = document.createElement('a');
        element.href = downloadUrl;
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const className = ["transcription-box"];
    
    if(!open)
        className.push("hide");

    return (
<div className={className.join(" ")}>
    <div>
        {audio.transcription
            ? HTMLReactParser(audio.transcription)
            : "No existe transcripción para este audio."
        }
    </div>
    {audio.transcription &&
    <button
        id={`btn-download-transcription-${audio.id}`}
        disabled={audio.transcription === null}
        className='audio-btn-transcript'
        onClick={downloadTranscript}
    >
        <TranscriptIcon/>
        <REATooltip placement="right" target={`btn-download-transcription-${audio.id}`} >
            <div className="notMarginButton">Descargar Transcripción</div>
        </REATooltip>
    </button> }
</div>
    )
}
