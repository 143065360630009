import React from 'react';
import { Link } from 'react-router-dom';

const LinkComponent = ({ href, ...props }) => {
    const location = window.location;

    const hostname = location.hostname;
    const protocol = location.protocol;
    const url = (href ?? '').split('/');

    return (
        protocol === url[0] && hostname === url[2] ? (
            <Link 
                to={`/${url.slice(3, url.length).join('/')}`}
                {...props}
            >
                {props.children}
            </Link>
        ) : (
            <a
                rel="noopener noreferrer"
                style={{ cursor: "pointer", ...(props.style ?? {}) }}
                href={href}
                {...props}
            >
                {props.children}
            </a>
        )
    );
};


export default LinkComponent;
