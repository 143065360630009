import React from "react";

export const MisRecursosLikeFolderListItem = ({isActive, folder, onRename, onSelect, onDelete, draggableProvided}) =>
{
    const className = [ "favorite-resource-list-item" ];
    if(isActive)
        className.push("active");
    
    return (
<li
    data-id={folder.id}
    className={className.join(" ")}
    onClick={(e) =>
    {
        onSelect(folder.id, folder.title);
    }}
    onDoubleClick={() =>
    {
        if(onRename)
            onRename(folder.id, folder.title);
    }}
    ref={draggableProvided?.innerRef}
    {...draggableProvided?.draggableProps}
    {...draggableProvided?.dragHandleProps}
>
    <div>
        <span className="favorite-resource-list-item-label">
            {folder.title}
        </span>

        { onDelete &&
        <span className="favorite-resource-list-item-cancel" onClick={() => onDelete(folder.id)} >
            <i className="fa fa-times"/>
        </span>
        }

        <span className="favorite-resource-list-item-badge badge badge-secondary">
            {(folder.resources ?? []).length}
        </span>
    </div>

</li>
    )
}
