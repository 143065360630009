import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { redirectOAuthLogin } from 'src/helpers/auth';
//import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Dropdown } from 'react-bootstrap';

// import { createListFromArray, ordernarXMenor } from '../../helpers';

const UserMenu = props => {
    const history = useHistory();
    const { user, loggedIn, menu: userMenu } = useSelector(state => state.authReducer);
    const location = useLocation();
    
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    function handleLogin(event) {
        event.preventDefault();
        redirectOAuthLogin();
    }

    function handleRouteClick(event, option) {
        event.preventDefault();

        const hostname = location.hostname;
        const protocol = location.protocol;
        const url = option.link.split('/');

        if(option.target === 'blank') {
            window.open(option.link, '_blank', 'noopener, noreferrer');
        } else {
            if(protocol === url[0] && hostname === url[2]) {
                const route = url.slice(3, url.length).join('/');
                history.push(`/${route}`);
            } else {
                window.location = option.link;
            }
        }
    }

    return (
        <>
        {
            (loggedIn)
            ?
            <Dropdown drop={window.innerWidth < 768 ? "up" : "down"} show={dropdownOpen} onToggle={toggle}>
                    <Dropdown.Toggle 
                        className="btn btn-dark btn-sm ml-2"
                        variant=""
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: '35px' }}
                    >
                        <i className="fa fa-user mr-1"></i>
                        <span 
                            className="text-white" 
                            style={{ fontFamily: 'Kanit, sans-serif', fontWeight: 600, color: "rgb(255, 255, 255) °important", fontSize: "0.875rem", maxWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {user.name}
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu 
                        flip
                        id="user-menu"
                        style={{ right: 0, left: 'auto' }}
                    >
                        {
                            (userMenu ?? []).map(option => {
                                return(
                                    <Dropdown.Item
                                        key={option.id}
                                        onClick={(event) => handleRouteClick(event, option)}
                                        as="button"
                                    >
                                        <div
                                            className="icono-user-panel"
                                            style={{ display: 'inline-block', backgroundImage: `url(${option.icon})` } }
                                        />
                                        <div style={{ display: 'inline-block' }}>
                                            {option.name}
                                        </div>
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            :
                <a
                    className="btn btn-dark btn-sm ml-2"
                    style={{ fontFamily: 'Kanit, sans-serif', fontWeight: 600 }}
                    href="#REA"
                    onClick={handleLogin}
                >
                    <i className="fa fa-user"></i> INGRESAR
                </a>
        }
        </>
    );
}

// fa-address-book fa-address-book fa-laptop fa-heart-o fa-sign-out

export default UserMenu
