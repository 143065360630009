import React from "react";
import { Button, Modal, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import classnames from 'classnames';

export const ApiResourceDialog = ({resourceId, idResponse, modalOpen, modalToggle}) =>
{
    const [ activeTab, setActiveTab ] = React.useState('1');
    const [ alertOpen, setAlertOpen ] = React.useState(false);

    const alertToggle = () => setAlertOpen(!alertOpen);

    const tabToggle = (tab) =>
    {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const copyToClipboard = (containerid) =>
    {
        if (document.selection)
        {
            const range = document.body.createTextRange();
            range.moveToElementText(document.getElementById(containerid));
            range.select().createTextRange();
            document.execCommand("copy");
        }
        else if (window.getSelection)
        {
            const range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            alertToggle();
            window.setTimeout(() =>
            {
                setAlertOpen(false);
            }, 3000);
        }
    }

    return (
<>
<Modal isOpen={modalOpen} toggle={modalToggle} scrollable={true} size='lg'>
    <ModalBody>
        <Nav className="nav nav-pills mb-3" tabs>
            <NavItem className="nav-item tab_buttons" style={{ cursor: "pointer" }}>
                <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() =>
                    {
                        tabToggle('1');
                    }}
                >
                API
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div id="apiModalContainer">
                    <h3>Request</h3>
                    <span style={{ backgroundColor: "#fcf6db" }}>{process.env.REACT_APP_BASE_URL + resourceId}</span>
                    <h3>Response</h3>
                    <textarea
                        readOnly="true"
                        rows="12"
                        style={{
                            width: "100%",
                            resize: "none",
                            backgroundColor: "#fcf6db",
                        }}
                    >
                        {idResponse}
                    </textarea>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                </TabPane>
        </TabContent>
    </ModalBody>
    <ModalFooter>
        <Alert color="success" isOpen={alertOpen} style={{ marginRight: "auto" }}>
            El código ha sido copiado correctamente
        </Alert>
        <Button
            color='primary'
            onClick={() => {
                copyToClipboard('apiModalContainer') }}
            >Copiar al portapapeles
        </Button>
        {' '}
        <Button
            color='secondary'
            onClick={modalToggle}
        >
            Cerrar
        </Button>
    </ModalFooter>
</Modal>
</>
    )
}
