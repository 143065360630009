import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import HomeUsuario from '../../components/usuarioDatos/homeusuario';
import Loading from '../../components/shared/loading';
import { getTokenUser } from '../../helpers/auth';
import { getUserMenuBackoffice } from '../../redux/actions/backofficeActions';
import { getDataUser } from '../../redux/actions/authActions';

const HomeUsuarioContainer = ({ location, history, user, ...props}) => {

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.get('code')) {
            getTokenUser(history, props.getDataUser, props.getUserMenuBackoffice)
        } else {
            window.location = `${process.env.REACT_APP_JUANAMANSO}/home-usuario`;
        }
    }, [location.search, history, props.getDataUser, props.getUserMenuBackoffice]);

    if (!user) {
        return <Loading />
    }

    return (
        <Redirect to={'recursos'} />
    );
/*     const { history, authReducer, location } = props;

    useEffect(() => {
        if (urlParams.get('code')) {
          getTokenUser(history, props.getDataUser, props.getUserMenuBackoffice)
        }else if(isLoggedIn()){
            if(authReducer.user === null){
                props.getDataUser();
                props.getUserMenuBackoffice();
            }
        }else{
            redirectOAuthLogin()
        }
    }, [authReducer.user_logued])

    if (authReducer.user == null) {
        return <Loading />
    }

    return <Redirect to={'recursos'} />; */
/* 
    return (
        <HomeUsuario
            history={history}
            user={authReducer.user}
        />
    ); */
}

const mapStateToProps = ({ authReducer }) => {
    return {
        user: authReducer.user
    };
};
const mapDispatchToProps = {
    getDataUser,
    getUserMenuBackoffice
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeUsuarioContainer);
