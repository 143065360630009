
export default function prettifyDate(stringDate)
{
    if(stringDate == null || stringDate == undefined)
    {
        return '';
    }
    const [ year, month, day ] = stringDate.split(" ")[0].split("-");
    const date = new Date(+year, +month + -1, day);
    const dateTimeFormat = new Intl.DateTimeFormat('es-AR',
    {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    return dateTimeFormat.format(date);
}
