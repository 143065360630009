import React from "react";

export default function AudioPlayerControlsProgressBar({ audioRef, progressBarRef, progressBarButton, max, changeRange })
{
    return (
<div className="audioplayer-bar">
    <div className='audioplayer-bar-bg'/>

    {/* <div className="audioplayer-bar-loaded"></div> */}
    <div
        ref={progressBarRef}
        className="audioplayer-bar-played"
    />
    <input
        disabled={audioRef.current === undefined}
        ref={progressBarButton}
        type='range'
        className="audioplayer-bar-played-input"
        onChange={changeRange}
        max={max}
        step="any"
    />
</div>
    )
}
