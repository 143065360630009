import React from "react";
import VideoSeasonBoxChapter from "./VideoSeasonBoxChapter";

export default function VideoSeasonBox({resource, season})
{
    const collapse = (season_id) =>
    {
        let season = document.getElementById(`season-${season_id}`);
        let seasonCollapse = document.getElementById(`seasonCollapse-${season_id}`);
        const episodes = document.getElementById(`season-id-${season_id}`);

        if (season.classList.contains("active"))
        {
            season.classList.remove("active");
            seasonCollapse.classList.remove("active");
            for (const episode of episodes.children)
            {
                episode.classList.remove("collapsed");
            }
        }
        else
        {
            season.classList.add("active");
            seasonCollapse.classList.add("active");
            for (const episode of episodes.children)
            {
                episode.classList.add("collapsed");
            }
        }
    };

    return (
<>
<div key={season.id} id={`season-id-${season.id}`}>
    <div className={`btn-lg btn-season${season.id === resource.season_id ? "" : " collapsed"}`} type="button" role="link">
        {/* <p className='season-titulo'>Videos</p> */}
        <div className={`btn-season-items${season.id === resource.season_id ? "" : " active"}`} id={`season-${season.id}`}>
            {season.title}
            {/* <button type="button" id="seasonCollapse" className="btn-season-open" onClick={() => collapse()}> */}
            <button type="button" id={`seasonCollapse-${season.id}`} className={`btn-season-open-container${season.id === resource.season_id ? "" : " active"}`}  onClick={() => collapse(season.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='btn-season-open' width="35" height="35" fill="#000000" viewBox="0 0 256 256">
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline points="96 48 176 128 96 208" fill="none" className='svg-arrow-collapse' stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"></polyline>
                    </svg>
                <div>
                    {/* <span></span>
                    <span></span> */}
                </div>
            </button>
        </div>
    </div>
    { season.chapters[0].map( chapter => {
        return <VideoSeasonBoxChapter key={chapter} resource={resource} season={season} chapter={chapter}/>
    })}
</div>
</>
    )
}
