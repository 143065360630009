import React, {useState} from 'react';
import iconoapi from '../../assets/images/JM_API.svg';
import iconooai from '../../assets/images/JM_OAI.svg';
import BreadCrumb from '../shared/breadcrumb';
import iconoangular from '../../assets/images/logo_angular.svg';
import iconophp from '../../assets/images/logo_php.svg';
import icononet from '../../assets/images/logo_net.svg';
import iconopython from '../../assets/images/logo_python.svg';
import iconoreact from '../../assets/images/logo_react.svg';
import iconojava from '../../assets/images/java.svg';
import iconoexcel from '../../assets/images/excel.png';
import { JAVA_CODE_DATOS_ABIERTOS, JS_CODE_DATOS_ABIERTOS, PHP_CODE_DATOS_ABIERTOS, PYTHON_CODE_DATOS_ABIERTOS, JSON_CODE_DATOS_ABIERTOS } from '../../util/constantes'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { copyToClipboard } from '../../helpers';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DatosAbiertos = props => {
	const { match, history, recursos } = props;

	const [activeTab, setActiveTab] = useState('angular');
	const [activeSubTab, setActiveSubTab] = useState('rest');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const toggle2 = tab => {
		if (activeSubTab !== tab) setActiveSubTab(tab);
	}

	const datosFiltros = recursos.filters.inactive;

	return (
		<div className="main_content">
			<div className="container-fluid">
                <div className="col-xl-10 offset-xl-1 col-12">

                    <BreadCrumb match={match} history={history} actualPosition={"PROTOCOLOS"} previousPositions={['Inicio']}/>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 text-center">
                            <h1 className="tc-5">EA - Portal de <span className="tc-2">Datos Abiertos</span></h1>
                            <h4 className="lh-30 mb-4 tc-3">Integrando repositorios digitales de recursos educativos abiertos con plataformas virtuales de aprendizaje</h4>
                            <p>REA (Recursos Educativos Argentinos), te permite consumir los distintos recursos mediante APIS. Los recursos están en 2 protocolos API REST desarrollada por Educar y OAI (Open Archives Initiative).</p>
                        </div>
                    </div>
			    </div>
			</div>
			<div className="col-xl-10 offset-xl-1 col-12">
				<div className="row mt-5">
					<div className="col-md-6">
						<div className="container">
							<div className="card-api mt-2">
								<div className="row">
									<div className="col-lg-6 col-12">
										<img src={iconoapi} alt="Icono API" />
									</div>
									<div className="col-lg-6 col-12">
										<h3>API</h3>
										<p>Nuestras APIs REST permiten integrar la librería completa de recursos de Juana Manso a tu sitio, permitiendo hacer busquedas por múltiples filtros.</p>
                                                                                <p>Documentación: </p>
										<a href={process.env.REACT_APP_BASE_URL.slice().replace('/rest', '/documentation')} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">API SWAGGER</a>
										{/* <a href={"https://api-recursos.juanamanso.edu.ar/api/documentation"} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">API SWAGGER</a> */}

									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="container">
							<div className="card-oai mt-2">
								<div className="row">
									<div className="col-lg-6 col-12">
										<img src={iconooai} alt="Icono OAI" />
									</div>
									<div className="col-lg-6 col-12">
										<h3>OAI</h3>
										<p>Esta API permite la importación total o parcial de metadatos de la librería de Recursos de Juana Manso, siguiendo el Protocolo de Cosecha de Metadatos de la Open Archives Initiative (OAI-PMH).</p>
										<a href={process.env.REACT_APP_BASE_URL.slice().replace('/rest/', '/rest').replace('/rest', '/oai-pmh?verb=Identify')} target="_blank" rel="noopener noreferrer" className="btn btn-success btn-sm">OAI Identificación</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 mt-3">
						<h4 className="text-center">Juego de datos</h4>
						<p className="large text-center">Aca tenés los datos base para cada uno de los recursos, en estos archivos vas a encontrar las tablas de clasificación de los recursos.</p>
						<div className="row mt-4 pb-5 ml-1">
							{
								datosFiltros.map(filtro => {
									filtro.elements.sort((a,b) => a.id - b.id)
									return(
										<div className="col-sm-2 col-6 text-center mb-3" style={{maxWidth: '13%'}}>
											<img src={iconoexcel} className="tech_logo" alt=""/>
											<span className="mb-1">{filtro.display_name}</span>
											<ExcelFile filename={filtro.display_name} element={<button className="btn btn-primary btn-sm">Descargar</button>}>
												<ExcelSheet data={filtro.elements} name={filtro.display_name.replace("/", "-")}>
													<ExcelColumn label="ID" value="id"/>
													<ExcelColumn label="Descripción" value="display_name"/>
												</ExcelSheet>
											</ExcelFile>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-10 offset-xl-1 col-12">
				<p className="large text-center">Podes integrar con cualquier lenguaje de programación por ejemplo:</p>
				<div className="row mt-5 pb-5">
					<div className="col-md-2 col-6 text-center mb-3">
						<img src={iconoangular} className="tech_logo mb-1" alt="Icono Angular" /><br />
						<span className="small font-weight-bold">INTEGRA CON</span>
						<h6 className="lh-15">ANGULAR</h6>
					</div>
					<div className="col-md-2 col-6 text-center mb-3">
						<img src={iconojava} className="tech_logo mb-1" alt="Icono Java" /><br />
						<span className="small font-weight-bold">INTEGRA CON</span>
						<h6 className="lh-15">JAVA</h6>
					</div>
					<div className="col-md-2 col-6 text-center mb-3">
						<img src={icononet} className="tech_logo mb-1" alt="Icono .Net" /><br />
						<span className="small font-weight-bold">INTEGRA CON</span>
						<h6 className="lh-15">.NET</h6>
					</div>
					<div className="col-md-2 col-6 text-center mb-3">
						<img src={iconophp} className="tech_logo mb-1" alt="Icono PHP" /><br />
						<span className="small font-weight-bold">INTEGRA CON</span>
						<h6 className="lh-15">PHP</h6>
					</div>
					<div className="col-md-2 col-6 text-center mb-3">
						<img src={iconopython} className="tech_logo mb-1" alt="Icono Python" /><br />
						<span className="small font-weight-bold">INTEGRA CON</span>
						<h6 className="lh-15">PYTHON</h6>
					</div>
					<div className="col-md-2 col-6 text-center mb-3">
						<img src={iconoreact} className="tech_logo mb-1" alt="Icono React" /><br />
						<span className="small font-weight-bold">INTEGRA CON</span>
						<h6 className="lh-15">REACT</h6>
					</div>
				</div>
			</div>
			<div className="col-xl-10 offset-xl-1 col-12">
				<div className="row my-4">
					<div className="col-md-12 text-center">
						<p className="large text-center">Ejemplos de implementación</p>
					</div>
				</div>
				<div className="row mb-5">
					<div className="col-md-12 col-12 px-5">

					<Nav className="nav nav-pills mb-3" >
							<NavItem className="nav-item tab_buttons">
								<NavLink
									className={activeSubTab === 'rest' ? 'active' : ''}
									onClick={() => { toggle2('rest'); }}
									style={{cursor:"pointer"}}
								>
									API REST
								</NavLink>
							</NavItem>
						</Nav>
						<Nav className="nav nav-pills mb-3" >
							<NavItem className="nav-item tab_buttons">
								<NavLink
									className={activeTab === 'angular' ? 'active' : ''}
									onClick={() => { toggle('angular'); }}
									style={{cursor:"pointer"}}
								>
									ANGULAR
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === 'java' ? 'active' : ''}
									onClick={() => { toggle('java'); }}
									style={{cursor:"pointer"}}
								>
									JAVA
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === 'php' ? 'active' : ''}
									onClick={() => { toggle('php'); }}
									style={{cursor:"pointer"}}
								>
									PHP
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === 'python' ? 'active' : ''}
									onClick={() => { toggle('python'); }}
									style={{cursor:"pointer"}}
								>
									PYTHON
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === 'react' ? 'active' : ''}
									onClick={() => { toggle('react'); }}
									style={{cursor:"pointer"}}
								>
									REACT
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={activeTab === 'json' ? 'active' : ''}
									onClick={() => { toggle('json'); }}
									style={{cursor:"pointer"}}
								>
									JSON
								</NavLink>
							</NavItem>
						</Nav>
						

						<div className="tab-content bg-dark" id="pills-tabContent" style={{ borderRadius: "10px" }}>
							<div className="tab-pane fade show active px-2 py-0 text-white" id="pills-angular" role="tabpanel" aria-labelledby="pills-home-tab">
								<div className="float-right">
									<button id="button1" className="btn btn-secondary btn-sm  my-2 mr-0 float-right" onClick={() => { copyToClipboard('div1') }}><i className="fa fa-copy"></i></button>
								</div>
								<figure className="highlight">
									<pre style={{ overflow: "initial" }}>
										<code className="language-html" data-lang="html">
											<div id="div1">
												<TabContent activeTab={activeSubTab} >
													<TabPane tabId="rest">
														<TabContent activeTab={activeTab}>
															<TabPane tabId="angular">
																{JS_CODE_DATOS_ABIERTOS}
															</TabPane>
															<TabPane tabId="java">
																{JAVA_CODE_DATOS_ABIERTOS}
															</TabPane>
															<TabPane tabId="php">
																{PHP_CODE_DATOS_ABIERTOS}
															</TabPane>
															<TabPane tabId="python">
																{PYTHON_CODE_DATOS_ABIERTOS}
															</TabPane>
															<TabPane tabId="react">
																{JS_CODE_DATOS_ABIERTOS}
															</TabPane>
															<TabPane tabId="json">
																{JSON_CODE_DATOS_ABIERTOS}
															</TabPane>
														</TabContent>
													</TabPane>
													<TabPane tabId="oai">
														<TabContent activeTab={activeTab}>
															<TabPane tabId="angular">
																{'\n'}En construcción...
															</TabPane>
															<TabPane tabId="net">
																{'\n'}En construcción...
															</TabPane>
															<TabPane tabId="java">
																{'\n'}En construcción...
															</TabPane>
															<TabPane tabId="php">
																{'\n'}En construcción...
															</TabPane>
															<TabPane tabId="python">
																{'\n'}En construcción...
															</TabPane>
															<TabPane tabId="react">
																{'\n'}En construcción...
															</TabPane>
															<TabPane tabId="json">
																{'\n'}En construcción...
															</TabPane>
														</TabContent>
													</TabPane>
												</TabContent>
											</div>
										</code>
									</pre>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DatosAbiertos;