/**
 * App.js Layout Start Here
 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import useEvent from 'src/hooks/useEvent';
import { getDataUser } from 'src/redux/actions/authActions';
import { getTokenUser, isLoggedIn, redirectOAuthLogin } from 'src/helpers/auth';
import {
   redirectConAviso,
   cleanUrl,
   showDeveloperMessage
} from 'src/helpers';

import NotFound from 'src/components/shared/notFound';
import Mantenimiento from 'src/components/mantenimiento/mantenimiento';
import ScrollToTop from 'src/components/shared/scroll';
import AppLayout from 'src/layouts/AppLayout';
import routes from 'src/routes';

const AppContainer = props => {
   const { history, location, match, userFetch } = props;
   const authMiddleware = process.env.REACT_APP_DEBUG_NO_LOGIN !== "true"

   useEvent((event) => {
      const url = cleanUrl(event.target.href ?? event.target.parentElement.href);
      if(url) redirectConAviso(url, event);
   }, [],);

   useEffect(() => {
      userFetch();
   }, [userFetch]);

   useEffect(() => {
      const urlParams = new URLSearchParams(location.search);

      const fetchToken = async () => {
         /* const token =  */await getTokenUser(urlParams);
         history.push(location.pathname);
      }

      if (urlParams.has('code')) {
         fetchToken();
      }

   }, [location, history, userFetch]);

   useEffect(() => {
      showDeveloperMessage('env', process.env);
   }, []);

   return (
      <>
         <NotificationContainer/>
         <AppLayout>
            <ScrollToTop />
				<Switch>
					{
						routes && routes.map((route, key) => {
                     if (authMiddleware) {
                        if(match.url === `/${route.path}` && route.auth && !isLoggedIn()) redirectOAuthLogin();
                     }

							return (
								<Route key={key} path={`/${route.path}`} component={route.component} exact />
							)
						})
					}
					<Route path="/mantenimiento" component={Mantenimiento} />
					<Route path="*" component={NotFound} />
				</Switch>
         </AppLayout>
      </>
   );
}

const mapStateToProps = ({authReducer}) => {
   return {
      auth: authReducer
   };
};

const mapDispatchToProps = {
   userFetch: getDataUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
