import React from 'react'
import conectarIgualdadImagen from "src/assets/images/CONECTAR-IGUALDAD_Color_SM_FondoTransparente.svg";

const Mantenimiento = (props) => {
    return (
        <div className="container-fluid" style={{marginTop: "9rem"}}>
            <div className="col-xl-8 offset-xl-2 col-12">
                <div className="card-login2 shadow-sm text-center" style={{ backgroundColor: '#9283BE' }}>
                <img src={conectarIgualdadImagen} className="d-none d-md-block mr-auto ml-auto" alt="Conectar Igualdad" style={{height:150, width:150}}/>
                    <p className="mt-0 text-white" style={{fontSize:20}}>Estamos realizando tareas de mantenimiento para ofrecerte una mejor experiencia.</p>
                    <p className="mt-0 text-white" style={{fontSize:20}}>Volveremos a estar en línea en breve.</p>
                </div>
            </div>
        </div>
    )
}

export default Mantenimiento
