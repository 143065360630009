import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import Loading from "../../components/shared/loading"
import { getDataUser } from '../../redux/actions/authActions';
import { getLikedRecursos, getRecursosCompartidos, setFavoritos, setFolders } from '../../redux/actions/recursosAction';
import { getUserMenuBackoffice } from '../../redux/actions/backofficeActions';
import { MoodleService, ResourcesService } from 'src/axios';
import { MisRecursosContextProvider } from 'src/components/usuarioDatos/mis-recursos/MisRecursosContext';
import { PopoverContextProvider } from 'src/components/usuarioDatos/mis-recursos/popovers/PopoverContext';
import { PopoverCard1 } from 'src/components/usuarioDatos/mis-recursos/popovers/PopoverCard1';
import { PopoverCard2 } from 'src/components/usuarioDatos/mis-recursos/popovers/PopoverCard2';
import Breadcrumb2 from 'src/components/shared/Breadcrumb2';
import { MisRecursosContent } from 'src/components/usuarioDatos/mis-recursos/MisRecursosContent';
import { MisRecursosLikeFolderListHeader } from 'src/components/usuarioDatos/mis-recursos/MisRecursosLikeFolderListHeader';
import { MisRecursosLikeFolderListContent } from 'src/components/usuarioDatos/mis-recursos/MisRecursosLikeFolderListContent';
import { RESOURCE_TYPES } from 'src/util/constantes';
import { showDeveloperMessage } from 'src/helpers';

const MisRecursosContainer = (props) =>
{
    const {
        authReducer,
        recursos,
        // getDataUser,
        // getLikedRecursos,
        getRecursosCompartidos,
    
        setFavoritos,
        setFolders: updateFolderList,
    } = props;
    const [loading, setLoading] = React.useState(false);
    const [ selectedFolderId, setSelectedFolderId ] = React.useState(0);

    const selectFolder = (folderId) =>
    {
        if(selectedFolderId !== folderId)
            setSelectedFolderId(folderId);
    }

    const updateLikeList = (likes) =>
    {
        setFavoritos(likes, selectedFolderId);
    }

    const unlikeRecurso = async (id, folder) =>
    {
        NotificationManager.info("Quitando recurso de favoritos...", null, 1000)
        setLoading(true)
        try
        {
            await ResourcesService.unlikeResource(id, folder)
            props.getDataUser()
            const likes = [...authReducer.likes].filter(r => r.resource_id !== id)
            updateLikeList(likes)
            NotificationManager.success("Recurso quitado de favoritos")
            setLoading(false)
        }
        catch (error)
        {
            NotificationManager.error("No se pudo actualizar el recurso")
            setLoading(false)
        }
    }

    const deleteModdleRecurso = async (id) =>
    {
        NotificationManager.info("Quitando recurso...", null, 1000)
        setLoading(true)
        try
        {
            await MoodleService.deleteResource(id)
            props.getDataUser();
            props.getRecursosCompartidos();
            NotificationManager.success("Recurso quitado");
            setLoading(false);
        }
        catch (error)
        {
            NotificationManager.error("No se pudo quitar el recurso")
            setLoading(false)
        }
    }

    const createFolder = async (title) =>
    {
        NotificationManager.info("Creando Carpeta...", null, 1000)
        setLoading(true)
        try
        {
            await ResourcesService.crearCarpetaRecursos(title)
            props.getDataUser()
            NotificationManager.success("Lista Creada")
            setLoading(false)
        }
        catch (error)
        {
            NotificationManager.error("No se pudo crear la Lista")
            setLoading(false)
        }
    }

    const addRecursoToFolder = async (recurso, folderId, oldFolderId) =>
    {
        const auxIdFolder = folderId === 0 ? null : folderId;
        const auxOldFolder= oldFolderId === 0 ? "" : oldFolderId;
        NotificationManager.info("Cambiando de Lista...", null, 1000)
        setLoading(true)
        try
        {
            await ResourcesService.cambiarCarpetaDeRecurso(recurso.resource_id, auxIdFolder, auxOldFolder)
            props.getDataUser()

            let aux = [...authReducer.likes].filter(r => r.resource_id !== recurso.resource_id)
            updateLikeList(aux);

            NotificationManager.success("Recurso cambiado de lista correctamente")
            setLoading(false)
        }
        catch (error)
        {
            NotificationManager.error("No se pudo cambiar de lista")
            setLoading(false)
        }
    }

    const deleteFolder = async (folderId) =>
    {
        NotificationManager.info("Eliminando Lista...", null, 1000)
        setLoading(true)
        try
        {
            await ResourcesService.eliminarCarpetaRecursos(folderId)
            NotificationManager.success("Lista eliminada correctamente")

            setSelectedFolderId(0);
            props.getDataUser();

            setLoading(false)
        }
        catch (error)
        {
            NotificationManager.error("No se pudo eliminar la lista")
            setLoading(false)
        }
    }

    const changeNameFolder = async (folderId, name) =>
    {
        NotificationManager.info("Cambiando nombre de Lista...", null, 1000)
        setLoading(true)
        try
        {
            await ResourcesService.cambiarNombreCarpetaRecursos(folderId, name)
            props.getDataUser()
            NotificationManager.success("Lista actualizada correctamente")
            setLoading(false)
        }
        catch (error)
        {
            NotificationManager.error("No se pudo cambiar el nombre de la lista")
            setLoading(false)
        }
    }

    const getRecursosFromFolder = async (folderId = null) =>
    {
        setLoading(true)

        if(folderId === null)
        {
            await props.getDataUser();
        }

        const { user: { folderMisFavoritos, likeFolders } } = authReducer;
        const folder = folderId === 0 ? folderMisFavoritos : likeFolders.find(lf => lf.id === folderId);

        const recursos = [];
        if(folder)
        {
        //
        // Obtener cada uno de los recursos que hay en la carpeta
        //
        for await(const resourceId of folder.resources)
        {
            // TODO optimizar
            const { resource_id, resource_type_id } = authReducer.user.likes.find((r) => r.resource_id === resourceId)
            try
            {
                let result;
                switch (resource_type_id) {
                    case RESOURCE_TYPES['RECURSOS']:
                        result = await ResourcesService.oneResource(resource_id);
                        break;
                    case RESOURCE_TYPES['COLECCIONES']:
                        result = await ResourcesService.oneCollection(resource_id);
                        break;
                    default:
                        break;
                }
                recursos.push(result.data);
            }
            catch (error)
            {
                console.log(error);
            }
        }

        }
        showDeveloperMessage('recursos', recursos);
        updateLikeList(recursos)

        setLoading(false);
    }

    React.useEffect(() =>
    {
        // console.log('Mount MisRecursosContainer', authReducer);

        if(!authReducer.user) return;

        /*
        if (process.env.REACT_APP_DEBUG_NO_LOGIN !== "true") {
            if(isLoggedIn()){
                if(!authReducer.user?.likes) return;

                getLikedRecursos(authReducer.user.likes.filter((like) => misFavoritos.resources.includes(like.resource_id)));
                getRecursosCompartidos();
            }
        }
        */
        if(authReducer.loggedIn)
        {
            getRecursosFromFolder(selectedFolderId);
            getRecursosCompartidos();
        }
    }, [authReducer.loggedIn, selectedFolderId])

    if(!props.authReducer.user)
    {
        if(props.authReducer.loading)
            return <Loading />
        return <></>
    }

    if (process.env.REACT_APP_DEBUG_NO_LOGIN !== "true")
    {
        if(props.authReducer.likes === null)
        {
            return <Loading />
        }
        if(props.recursos.compartidos === null)
        {
            return <Loading />
        }
    }

    const { user, likes, loading: loadingAuth } = authReducer;

    return (
<MisRecursosContextProvider
    user={user}
    recursos={recursos}
    selectedFolderId={selectedFolderId}
    likeFolders={authReducer.user?.likeFolders ?? []}
    sharedFolders={recursos.compartidos}

    selectFolder={selectFolder}
    createFolder={createFolder}
    renameFolder={changeNameFolder}
    deleteFolder={deleteFolder}
    unlikeResource={unlikeRecurso}
    deleteModdleResource={deleteModdleRecurso}

    updateLikeList={updateLikeList}
    updateFolderList={updateFolderList}
>
    <PopoverContextProvider name={"mis_recursos"} popoverNames={[
        "Popover1",
        "Popover2",
    ]}>
        <div className="main_content">
            <div className="container-fluid">
                <div className="col-xl-12 col-12">
                    <Breadcrumb2
                        currentPosition={"Mis Recursos"}
                        previousPositions={["/home"]}
                    />

                    <div className="row mb-5">

                        <div className="col-xl-2">
                            <div className='favorite-resource-list'>
                                <MisRecursosLikeFolderListHeader/>
                                <MisRecursosLikeFolderListContent
                                    activeKey={selectedFolderId}
                                    folderMisFavoritos={user.folderMisFavoritos}
                                />
                            </div>
                        </div>
                        <div className="col-md-10">
                            <MisRecursosContent
                                loading={loading}
                                loadingAuth={loadingAuth}
                                user={user}
                                likes={likes}
                                recursos={recursos}
                                addRecursoToFolder={addRecursoToFolder}
                            />
                        </div>
                    </div>
                </div>
            </div>

            { /* Popovers */ }
            <PopoverCard1/>
            <PopoverCard2/>

        </div>
    </PopoverContextProvider>
</MisRecursosContextProvider>
    );
}

const mapStateToProps = ({ authReducer, recursos }) => {
    return {
        authReducer,
        recursos
    };
};

const mapDispatchToProps = {
    getDataUser,
    getUserMenuBackoffice,
    getLikedRecursos,
    getRecursosCompartidos,
    setFavoritos,
    setFolders
}

export default connect(mapStateToProps, mapDispatchToProps)(MisRecursosContainer);