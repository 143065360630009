import React from "react";
import CategoriaFiltros from "src/components/recursos/sidebar/categoriasFiltros";

export function VistaRecursosListaSidebar(props)
{
    const { filtros, history, location, setShow } = props;
    const removedFilters = ["modalities", "owners"];
    const filterFiltrosRemovidos = (filtro) => ( !removedFilters.includes( filtro.key ) );

    return (
<>
    {filtros.inactive.filter(filterFiltrosRemovidos).map((categoria, index) =>
    {
        return (
            <CategoriaFiltros
                key={index}
                categoria={categoria}
                indexFiltro={index}
                history={history}
                location={location}
                setShow={setShow}
            />
        )
    })}
</>
    )
}
