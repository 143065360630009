import React from 'react';
import { MisRecursosCreateFolderDialog } from './dialog/MisRecursosCreateFolderDialog';
import { MisRecursosDeleteFolderDialog } from './dialog/MisRecursosDeleteFolderDialog';
import { useMisRecursos } from './MisRecursosContext';
import { MisRecursosLikeFolderListItem } from './MisRecursosLikeFolderListItem';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorderList } from 'src/util/reorderList';

export const MisRecursosLikeFolderListContent = ({folderMisFavoritos, handleRenameFolder, handleDeleteFolder, ...props}) =>
{
    const [ folderIdToDelete, setFolderIdToDelete ] = React.useState(null);
    const [ folderDataToRename, setFolderDataToRename ] = React.useState(null);

    const {
        likeFolders,
        sharedFolders,
        activeFolderId,
        selectFolder,

        updateFolderList,
        changePositionFolder,
    } = useMisRecursos();

    return (
<>
<ul className="favorite-resource-list-content">

    { /* Mis favoritos */}
    { folderMisFavoritos &&
    <MisRecursosLikeFolderListItem
        folder={{ ...folderMisFavoritos, title: "Mis Favoritos" }}
        isActive={activeFolderId === folderMisFavoritos?.id}
        onSelect={selectFolder}
    />
    }

    { /* Compartidos en mis aulas */}
    { sharedFolders && (sharedFolders ?? []).length > 0 &&
        <MisRecursosLikeFolderListItem
            folder={{
                key: null,
                title: "Compartidos en mis aulas"
            }}
            isActive={activeFolderId === null}
            onSelect={selectFolder}
        />
    }
</ul>

{ /* Otras carpetas */ }
{ true &&
<DragDropContext
    onDragEnd={(result) =>
    {
        // dropped outside the list
        if(!result.destination)
        {
            return;
        }

        //
        // Actualizar lista de carpetas de likes
        //
        updateFolderList(reorderList(
            likeFolders,
            result.source.index,
            result.destination.index
        ));

        //
        // Change position like folder
        //
        const folderId = result.draggableId;
        const newPosition = result.destination.index;
        changePositionFolder(folderId, newPosition);
    }}
>
    <Droppable droppableId="likeFolders">
        { (droppableProvided) =>
        (
            <ul
                className="favorite-resource-list-content"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
            >
                { likeFolders.map((folder, index) => (
                    <Draggable
                        key={folder.id}
                        draggableId={"" + folder.id}
                        index={index}
                    >
                    {(draggableProvided) =>
                    (
                        <MisRecursosLikeFolderListItem
                            draggableProvided={draggableProvided}
                            folder={folder}
                            isActive={activeFolderId === folder.id}
                            onSelect={selectFolder}
                            onRename={(folderId, folderName) =>
                            {
                                setFolderDataToRename({
                                    id: folderId,
                                    name: folderName,
                                });
                            }}
                            onDelete={(folderId) =>
                            {
                                setFolderIdToDelete(folderId);
                            }}
                        />
                    )}
                    </Draggable>
                ))}
                { droppableProvided.placeholder }
            </ul>
        )}
    </Droppable>
</DragDropContext>
}

{ /* Delete folder dialog */ }
<MisRecursosDeleteFolderDialog
    folderIdToDelete={folderIdToDelete}
    onClose={() => setFolderIdToDelete(null)}
/>

{ /* Rename folder dialog */ }
<MisRecursosCreateFolderDialog
    folderToEdit={folderDataToRename}
    open={folderDataToRename != null}
    onClose={() => setFolderDataToRename(null)}
/>

</>
    )
}
