import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useMisRecursos } from "../MisRecursosContext";

export const MisRecursosUnlikeResourceDialog = ({resourceIdToUnlike, onClose}) =>
{
    const {
        recursos,
        selectedFolderId,

        selectFolder,
        unlikeResource,
        deleteModdleResource,
    } = useMisRecursos();

    const handleUnlikeRecurso = () =>
    {
        if (selectedFolderId === null)
        {
            if ((recursos.compartidos ?? []).length === 1)
            {
                selectFolder(0);
            }
            deleteModdleResource(resourceIdToUnlike);
        }
        else
        {
            unlikeResource(resourceIdToUnlike, selectedFolderId);
        }

        onClose();
    }

    return (
<>
<Modal isOpen={resourceIdToUnlike != null} toggle={onClose}>
    <ModalBody style={{ padding: "2rem 2rem" }}>
        <h3 className="mb-3 modal-title">Eliminando recurso</h3>
        <p>¿Deseas sacar este recurso {selectedFolderId !== null && "de tus favoritos"} ?</p>
    </ModalBody>
    <ModalFooter>
        <Button color='secondary' onClick={onClose}>
            Cancelar
        </Button>
        <Button color='primary' className="btn-conectar-igualdad" onClick={() =>
        {
            handleUnlikeRecurso();
        }}>
            Eliminar
        </Button>
    </ModalFooter>
</Modal>
</>
    )
}
