import React from 'react';

const JuanamansoRedirect = ({ newLocation, ...props }) =>
{
    window.location.replace(`${process.env.REACT_APP_JUANAMANSO}/${newLocation}`);
    return <></>
}

export default [
    {
        path: '',
        redirect: <JuanamansoRedirect newLocation="inicio" />
    },
    {
        path: 'inicio',
        redirect: <JuanamansoRedirect newLocation="inicio" />
    },
    {
        path: 'home',
        redirect: <JuanamansoRedirect newLocation="inicio" />
    },
    {
        path: 'home-usuario',
        redirect: <JuanamansoRedirect newLocation="home-usuario" />
    },
    {
        path: 'mis-datos',
        redirect: <JuanamansoRedirect newLocation="mi-perfil/editar" />
    },
    {
        path: 'acercade',
        redirect: <JuanamansoRedirect newLocation="acercade" />
    },
    {
        path: 'faq',
        redirect: <JuanamansoRedirect newLocation="faq" />
    },
    {
        path: 'contacto',
        redirect: <JuanamansoRedirect newLocation="contacto" />
    },
    {
        path: 'aulasvirtuales',
        redirect: <JuanamansoRedirect newLocation="aulasvirtuales" />
    },
    {
        path: 'recursosJM',
        redirect: <JuanamansoRedirect newLocation="recursosJM" />
    },
    {
        path: 'tutoriales',
        redirect: <JuanamansoRedirect newLocation="tutoriales" />
    },
    {
        path: 'condiciones',
        redirect: <JuanamansoRedirect newLocation="condiciones" />
    },
    {
        path: 'formacion',
        redirect: <JuanamansoRedirect newLocation="formacion" />
    }
];
