import React from "react";
import FavoriteActiveIcon from 'src/assets/images/JM_iconos-02_favorito_act-blue.svg';
import FavoriteDisabledIcon from 'src/assets/images/JM_iconos-01_favorito_desac.svg';

export const HeartIcon = ({ isLiked, id }) =>
{
    return isLiked
        ? <img id={id} src={FavoriteActiveIcon}  width="24px" height="24px" title="Quitar de mis recursos" alt="Quitar de mis recursos" />
        : <img id={id} src={FavoriteDisabledIcon} width="24px" height="24px" title="Agregar a mis recursos" alt="Agregar a mis recursos" />
}
