import React from "react";
import { Popover } from 'reactstrap';
import { usePopover } from "./PopoverContext";
import classnames from 'classnames';

export const PopoverCard = ({name, placement, children, extraClassName}) =>
{
    const { next, close, ready, isLast, currentPopover } = usePopover();

    return (
<Popover
    popperClassName={classnames("alert alert-dark alert-dismissible popover-card", extraClassName)}
    placement={placement || "right"}
    target={name}
    isOpen={currentPopover === name}
>
    { children }
    { isLast &&
        <div className="row justify-content-between mr-2 ml-2">
            <PopoverCardButton onClick={ready}>
                ENTENDIDO
            </PopoverCardButton>
            <PopoverCardButton onClick={close}>
                CERRAR
            </PopoverCardButton>
        </div>
    }
    { !isLast &&
        <div className="row float-right mr-2">
            <PopoverCardButton onClick={next}>
                SIGUIENTE
            </PopoverCardButton>
        </div>
    }
</Popover>
    )
}

const PopoverCardButton = ({children, onClick}) =>
{
    return (
<span className="popover-card-button" aria-label="close" onClick={onClick}>
    { children }
</span>
    )
}