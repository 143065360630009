import React from 'react'

const LibroRecurso = ({ libros }) =>  (
    <>
        {
            libros.map(libro => (
                <embed
                    className="embed-responsive embed-responsive-16by9" style={{ height: "100vh" }}
                    id="libroRecursoId" key={libro.id} src={libro.url}
                />
                )
            )
        }
    </>
);

export default LibroRecurso;
