import galeriaImagenesBck from '../assets/images/recursos/bkg_galeria.jpg';
import interactivoBck from '../assets/images/recursos/bkg_juegos.jpg';
import librosBck from '../assets/images/recursos/bkg_libros.jpg';
import textosBck from '../assets/images/recursos/bkg_textos.jpg';
import videosBck from '../assets/images/recursos/bkg_videos.jpg';

export const getDefaultImage = (recurso) => {
    let splitPath = recurso.thumbnail.split('/');
    let imageName = splitPath[splitPath.length - 1];
    switch(imageName) {
        case 'audio.png': {
            return videosBck;
        }
        case 'libro.png': {
            return librosBck;
        }
        case 'video.png': {
            return videosBck;
        }
        case 'galeria.png': {
            return galeriaImagenesBck;
        }
        case 'texto.png': {
            return textosBck;
        }
        case 'interactivo.png': {
            return interactivoBck;
        }
        default: return librosBck;
    }
}