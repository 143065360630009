import React from 'react';
// import ReactJWPlayer from 'react-jw-player';

/* const getPlayList = (videos) => {
    let playlist = []

    videos.map((video, index) => {
        playlist.push({
            "title": video.file,
            "description": "",
            "mediaid": index,
            "sources": [
                {
                    "label": "Calidad estándar",
                    "file": video.url,
                    "type": "mp4",
                    "default": true
                },
                {
                    "label": "Calidad muy alta",
                    "file": video.url,
                    "type": "mp4"
                }
            ],
            "tracks": []
        })
    })
    return playlist
} */
const regex=/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
const VideoRecurso = (props) => {
    const { videos, title } = props;

    return (
        <div className="embed-responsive embed-responsive-4by3">
            {
                videos.map(video => {
                    return (video.url.match(regex))
                    ?  (
                        <iframe 
                            key={video.id}
                            src={video.url}
                            title={title}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="embed-responsive-item"
                            frameBorder="0"
                            width="560"
                            height="315"
                        >
                        </iframe>
                    )
                    : (
                        <video key={video.id} className="embed-responsive-item" controls poster={video.preview_video_path}>
                            <source src={video.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )
                })
            }

            {/* // <div className="react-jw-player-container">
        //     <ReactJWPlayer
        //         playerId='videoPlayer'
        //         playerScript='https://libs.educ.ar/jwplayer-7.0.3/jwplayer.js'
        //         licenseKey={process.env.REACT_APP_JWPLAYER_KEY}
        //         customProps={{
        //             "width": "100%",
        //             "height": "100%",
        //             "aspectratio": "4:3",
        //             "abouttext": "Recursos educ.ar",
        //             "aboutlink": "http://www.educ.ar",
        //             controls: true,
        //             "autostart": false,
        //             "ga": {
        //                 "idstring": "title",
        //                 "label": "mediaid"
        //             },
        //             "skin": {
        //                 "name": "seven"
        //             },
        //             "displaytitle": true,
        //             "displaydescription": true,
        //         }}
        //         playlist={getPlayList(videos)}
        //     />
        // </div></div> */}
        </div>

    )
}

export default VideoRecurso