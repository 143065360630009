import React, { useState } from 'react';
import CategoriaFiltrosItem from './CategoriaFiltrosItem';

const filtrosCount_CargarMas_magicNumber = 6;

const CategoriaFiltros = (props) => {
    const { categoria, indexFiltro, location, history } = props;
    const [slideOpen, setSlideOpen] = useState(false);

    const cargarMas = () => {
        let mdiv = document.getElementById('itemsToHide-' + indexFiltro);
        let els = mdiv.children;
        if (slideOpen) {
            setSlideOpen(false);
            Array.prototype.forEach.call(els, function (el) {
                el.style.display = "none";
            });
            mdiv.style.height = '0px';
        }
        else {
            setSlideOpen(true);
            Array.prototype.forEach.call(els, function (el) {
                el.style.display = "block";
            });
            mdiv.style.height = 'auto';
        }
    }

    /*  
        Mapeo el display_name de la API al nombre que quieren mostrar para el filtro 
        Key es categoria.display_name que viene de API - Value es el titulo a mostrar en frontend 
        Si no está presente toma el categoria.display_name
    */
    const categoriaNameMap = {
        'Propietario': 'Editor/a responsable',
        'Categoria': 'Tipo de Propuesta',
        'Área / Disciplina': 'Área / disciplina',
        'Audiencia': 'Personas destinatarias',
    };
    
    return (categoria.elements.length > 0 &&
        < >
            <div>
                <h6 className="font-weight-bold ml-4">
                    {categoriaNameMap[categoria.display_name] ?? (categoria.display_name) }
                </h6>
                <ul className="term-list">
                    {
                        categoria.elements ? categoria.elements.length > 0 ? categoria.elements.map((filtro, index) => {
                            if (index < filtrosCount_CargarMas_magicNumber)
                                return <CategoriaFiltrosItem key={`${filtro.id}`} filtro={filtro} categoria={categoria} history={history} location={location} alwaysVisible={true}/>
                            else
                                return <React.Fragment key={`${filtro.id}`}></React.Fragment>
                        }) : <></> : <></>
                    }
                    {
                        categoria.display_name !== "Modalidad" &&
                        categoria.elements.length > filtrosCount_CargarMas_magicNumber &&
                        <div id={"itemsToHide-" + indexFiltro}>
                        { categoria.elements.map((filtro, index) =>
                        {
                            if (index >= filtrosCount_CargarMas_magicNumber)
                                return <CategoriaFiltrosItem key={`${filtro.id}`} filtro={filtro} categoria={categoria} history={history} location={location} alwaysVisible={false}/>
                            else
                                return <React.Fragment key={`${filtro.id}`}></React.Fragment>
                        })}
                        </div>
                    }
                    {
                        categoria.elements.length > filtrosCount_CargarMas_magicNumber &&
                        <li style={{ /* textTransform: 'capitalize' */}} className="more" onClick={(event) => {
                            cargarMas();
                        }}>{slideOpen ? "Cargar menos..." : "Cargar más..."}</li>
                    }
                </ul>
            </div>
            <hr></hr>
        </>
    )
}
export default CategoriaFiltros;
