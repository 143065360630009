import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useMisRecursos } from "../MisRecursosContext";

export const MisRecursosDeleteFolderDialog = ({folderIdToDelete, onClose}) =>
{
    const {
        deleteFolder,
    } = useMisRecursos();

    return (
<Modal isOpen={folderIdToDelete != null} toggle={onClose}>
    <ModalBody style={{ padding: "2rem 2rem" }}>
        <h3 className="mb-3 modal-title">Eliminando lista</h3>
        <p>¿Deseas eliminar esta lista de favoritos?</p>
    </ModalBody>
    <ModalFooter>
        <Button color='secondary' onClick={onClose}>Cancelar</Button>
        <Button color='primary' className="btn-conectar-igualdad" onClick={() =>
        {
            deleteFolder(folderIdToDelete);
            onClose();

        }}>Eliminar</Button>
    </ModalFooter>
</Modal>
    )
}
