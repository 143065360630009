import {
    GET_CLASSROOMS, GET_CLASSROOMS_SUCCESS, GET_CLASSROOMS_FAILURE, CLASSROOM_NOT_LOADING
} from '../types';

const INIT_STATE = {
    niveles: [],
    error: null,
    loaded: false,
    loading: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLASSROOM_NOT_LOADING:
            return {
                ...state,
                loaded: true
            }
        case GET_CLASSROOMS:
            return {
                ...state,
                loaded: false,
                loading: true
            }
        case GET_CLASSROOMS_SUCCESS:
            return {
                ...state,
                niveles: action.payload,
                error: null,
                loaded: true,
                loading: false
            }
        case GET_CLASSROOMS_FAILURE:
            return {
                ...state,
                niveles: [],
                error: action.payload,
                loaded: true,
                loading: false
            }
        default:
            return { ...state }
    }
}