import React from "react";
import { isMobile } from "src/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function REATooltip2({children, TooltipText, ...props})
{
  if(isMobile() || TooltipText==''){
    return children
  }
  return (
    <OverlayTrigger
      placement=""
      delay={{ show: 150, hide: 250 }}
      overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            {TooltipText}
          </Tooltip>
        )}
    >
      {children}
    </OverlayTrigger>
  )
}

