import { useEffect, useCallback } from 'react';
import { attachEvent, removeEvent } from 'src/helpers';

const useEvent = (callback = () => {}) => {
    useEffect(
        useCallback(() => {
            function listener (event) {
                event = event ? event : window.event;
                callback(event);
            }

            attachEvent(document, 'click', listener);

            return () => {
                removeEvent(document, 'click', listener)
            }
        }, [callback])
    , []);
}

export default useEvent;