import React, {useState} from "react";

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import downloadIcon from 'src/assets/images/file_download_black_24dp.svg';
import Transcripcion from "./Transcript";
import Main from "./Main";
import REATooltip2 from "src/components/shared/REATooltip2";


export default function Episodio({episode, orden}) {

    const transcription = episode.transcription;

    const [sinopIsOpen, setSinopIsOpen] = useState(false);
    const toggleSinops = () => setSinopIsOpen(!sinopIsOpen);

    const [transcrIsOpen, setTranscrIsOpen] = useState(false);
    const toggleTranscr = () => setTranscrIsOpen(!transcrIsOpen);

    const [isPlaying, setisPlaying] = useState(false);


    function downloadAudio(event)
    {
        event.preventDefault();

        let url = episode.resource.attachments[0].url;
        if(url.includes("/get-attachment/"))
        {
            url += "/attachment";
        }

        const element = document.createElement('a');
        element.href = url;
        element.click();
    }


    return (
        <div
            className={( sinopIsOpen || transcrIsOpen || isPlaying )
            ? "episode selected"
            : "episode" }
        >
            <img src={episode.image} className="miniatura"></img>
            <div className="content">
                <div className="primario">
                    <Main
                        sinopIsOpen={sinopIsOpen}
                        toggleSinops={toggleSinops}
                        setisPlaying={setisPlaying}
                        episode={episode}
                        orden={orden}
                        isMobile={false}
                    />
                    <div className="botonera">
                    <REATooltip2 TooltipText={transcription ? "Ver transcripción": ""}>
                        <div
                            className={transcrIsOpen ? "side-btn-expanded" : "side-btn" }
                            disabled={!transcription}
                            onClick={transcription && toggleTranscr}
                        >
                            <DescriptionOutlinedIcon/>
                        </div>
                    </REATooltip2>
                    <REATooltip2 TooltipText="Descargar" >
                        <div className="side-btn" onClick={downloadAudio} >
                            <img src={downloadIcon}></img>
                        </div>
                    </REATooltip2>
                    </div>
                </div>
                <Transcripcion
                    transcrIsOpen={transcrIsOpen}
                    transcription={transcription}
                />
            </div>
        </div>
    )
}