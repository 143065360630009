import React from "react";
import CodeIcon from "src/assets/images/JM_iconos-08_codigo.svg";
import { ResourcesService } from 'src/axios';
import { ApiResourceDialog } from "./ApiResourceDialog";

export const ApiResourceButton = ({view, resourceId, isCollection}) =>
{
    const [ modalOpen, setModalOpen ] = React.useState(false);
    const [ idResponse, setIdResponse ] = React.useState('');

    const modalToggle = () => setModalOpen(!modalOpen);

    async function informarRecursoApi(id)
    {
        const response = await getResponseById(id);
        setIdResponse(response);
        modalToggle();
    }

    async function getResponseById(id)
    {
        try
        {
            const response = isCollection
                                ? await ResourcesService.oneCollection(id)
                                : await ResourcesService.oneResource(id)
                                ;

            if (response.status)
            {
                return JSON.stringify(response.data, null, 4);
            }
        }
        catch (e)
        {
          // console.log(e)
        }
    }

    return (
<>
{ view &&
    <img src={CodeIcon}
        style={{ cursor: 'pointer' }}
        onClick={(event) =>
        {
            event.preventDefault();
            informarRecursoApi(resourceId);
        }}
        className={view !== 'detail' ? "icon-bar" : "icon-bar detail"}
        width="24px"
        height="24px"
        title="Obtener datos API"
        alt="Obtener datos API"
    />
}
<ApiResourceDialog
    resourceId={resourceId}
    idResponse={idResponse}
    modalOpen={modalOpen}
    modalToggle={modalToggle}
/>
</>
    )
}
