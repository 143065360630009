import moment from 'moment';
import { NotificationManager } from 'react-notifications';

export * from "./event";
export * from "./url";
export * from "./env";

export const dateHuman = (dateString, withTime = false) => (
    (new Date(dateString)).toLocaleDateString('es-ar', { 
        ...{
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            calendar: 'iso8601',
            timeZone: "America/Buenos_Aires"
        },
        ...(withTime ? {
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        } : {})
    })
);

export const convertDate = (date, currentFormat, incomingFormat, lowerCase = false) => {
    let months = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre';

    if(lowerCase)
        months = months.toLocaleLowerCase();

    moment.locale("es", {
        months: months.split('_'),
    });

    return moment(date, currentFormat).format(incomingFormat);
}

export const copyToClipboard = containerid => {
    const element = document.getElementById(containerid);
    navigator.clipboard.writeText(element.value ?? element.innerText ?? element.innerHTML);
    NotificationManager.info("Código copiado", null, 1500)
}
