import { cleanCookie, getCookie, hasCookie } from './cookie';
import { generateCodeChallenge, generateCodeVerifier } from './crypto';
import { redirect } from './url';

export const isLoggedIn = () => hasCookie('logged_in');

const fetchToken = (formData) => (
    //https://sso.juanamanso.espinlabs.com.ar/oauth/token'
    fetch(`${process.env.REACT_APP_BASE_URL_OAUTH}/oauth/token`, {
        method: 'POST',
        body: formData,
        headers: {
            "Accept": "application/json",
        },
        credentials: 'include',
    })
    .then(response => response.json())
);

export const getTokenUser = async (urlParams) => {
    const code_verifier = getCookie('code_verifier'); // Recuperar de cookie
    const code = urlParams.get('code'); // Recuperar del request / url

    if(!code_verifier || !code)
        return null;

    const formData = new FormData();
    formData.append("grant_type", "authorization_code");
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID_OAUTH);
    formData.append("redirect_url", process.env.REACT_APP_CALLBACK_OAUTH);
    formData.append("code_verifier", code_verifier);
    formData.append("code", code);

    return await fetchToken(formData)
        .catch(function(error) {
            console.error('Error al Loguearse:', error);
        });
}

export const refreshTokenUser = async () => {
    const refresh_token = getCookie('refresh_token'); // Recuperar de cookie

    if(!refresh_token)
        return null;

    const formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID_OAUTH);
    formData.append("redirect_url", process.env.REACT_APP_CALLBACK_OAUTH);
    formData.append("refresh_token", refresh_token);

    return await fetchToken(formData)
        .then(function(response){
            return response;
        })
        .catch(function(error) {
            console.error('Error al refrescar token:', error);
            localStorage.clear();
            return null;
        });
}

const getOauthHost = () => process.env.REACT_APP_BASE_URL_OAUTH

export const redirectOAuthLogin = () => {
    const code_verifier = generateCodeVerifier();
    const code_challenge = generateCodeChallenge(code_verifier);

    // 'https://sso.juanamanso.espinlabs.com.ar/oauth/authorize';
    const oauthAuthorize = `${getOauthHost()}/oauth/authorize`;
    const query = new URLSearchParams({
        client_id: process.env.REACT_APP_CLIENT_ID_OAUTH,
        redirect_url: process.env.REACT_APP_CALLBACK_OAUTH,
        response_type: "code",
        scope: "",
        state: "asdasd",
        code_challenge: code_challenge,
        code_challenge_method: "S256"
    });

    document.cookie = "code_verifier=" + code_verifier;
    redirect(`${oauthAuthorize}?${query.toString()}`);
}

export const redirectOAuthRegister = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const tujm = urlParams.get('tujm');
    const code_verifier = generateCodeVerifier();
    const code_challenge = generateCodeChallenge(code_verifier);

    // 'https://sso.juanamanso.espinlabs.com.ar/oauth/authorize';
    const oauthURL = `${getOauthHost()}/oauth/authorize`;
    const query = new URLSearchParams({
        client_id: process.env.REACT_APP_CLIENT_ID_OAUTH,
        redirect_url: process.env.REACT_APP_CALLBACK_OAUTH,
        response_type: "code",
        scope: "",
        state: "asdasd",
        code_challenge: code_challenge,
        code_challenge_method: "S256",
        tujm: tujm
    });

    document.cookie = "code_verifier=" + code_verifier;
    redirect(`${oauthURL}?${query.toString()}`);
}

export const redirectOAuthLogout = () => {
    // https://sso.juanamanso.espinlabs.com.ar/salir?post_logout_redirect_uri=https://www.google.com/
    const oauthURL = getOauthHost();
    const query = new URLSearchParams({
        post_logout_redirect_uri: process.env.REACT_APP_CALLBACK_LOGOUT
    });

    cleanCookie("logged_in");
    redirect(`${oauthURL}/salir?${query.toString()}`);
}
