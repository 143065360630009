import React from 'react';

import AudioPlayerControlsPlayButton from 'src/components/shared/audio-player/AudioPlayerControlsPlayButton';
import PodcastAudioVolControl from './PodcastAudioVolControl';
import AudioPlayerControlsProgressBar from 'src/components/shared/audio-player/AudioPlayerControlsProgressBar';
import AudioPlayerControlsProgressTime from 'src/components/shared/audio-player/AudioPlayerControlsProgressTime';

const PodcastAudioPlayer = ({ audio, episodeID, setisPlaying }) =>
{
    const [canPlay, setCanPlay] = React.useState(false);
    const [duration, setDuration] = React.useState(0);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [currentVolume, setCurrentVolume] = React.useState(1);

    const playingRef = React.useRef(false);
    const audioPlayerRef = React.useRef();
    const progressBarRef = React.useRef();
    const progressBarButton = React.useRef();
    const volumeBarRef = React.useRef();
    const volumeBarButtonRef = React.useRef();
    const animationRef = React.useRef();
    const audioClassStates = React.useRef();

    React.useEffect(() =>
    {
        if(duration > 0)
            setCanPlay(true);
    }, [duration, setCanPlay]);

    const playAudio = (event) =>
    {
        event.preventDefault();
        if(!canPlay)
            return;

        setisPlaying(prev => !prev);

        /* si hay cualquier otro audio en reproducción, lo pausa - #20682 */
        const selector = '.audioplayer-playing'+`:not(#btn-play-${episodeID})`;
        if(document.querySelector(selector)) {
            document.querySelector(selector).firstChild.click();
        };

        const btnPlay = document.getElementById(`btn-play-${episodeID}`)
        btnPlay.classList.toggle('audioplayer-playing')

        //var isPlaying = audioPlayerRef.current.currentTime > 0 && !audioPlayerRef.current.paused && !audioPlayerRef.current.ended && audioPlayerRef.current.readyState > audioPlayerRef.current.HAVE_CURRENT_DATA;
        //if(!isPlaying)
        if(!playingRef.current)
        {
            if(audioPlayerRef.current.ended)
            {
                const newCurrentTime = 0;
                audioPlayerRef.current.currentTime = newCurrentTime;
                progressBarButton.current.value = newCurrentTime;
                progressBarRef.current.style.setProperty('width', `${newCurrentTime / duration * 100}%`);
                setCurrentTime(newCurrentTime);
            }

            setTimeout(() =>
            {
                audioPlayerRef.current.play().then(() =>
                {
                })
                .catch((error) =>
                {
                    console.error(error);
                });
            }, 0);
            animationRef.current = requestAnimationFrame(whilePlaying);
        }
        else
        {
            audioPlayerRef.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }

    const whilePlaying = () =>
    {
        if(progressBarButton.current.value == duration)
        {
            audioPlayerRef.current.pause();
            cancelAnimationFrame(animationRef.current);
            audioClassStates.current.classList.remove('audioplayer-playing');
        }
        else
        {
            progressBarRef.current.value = audioPlayerRef.current.currentTime;
            setCurrentTime(progressBarRef.current.value);
            progressBarRef.current.style.setProperty('width', `${progressBarRef.current.value / duration * 100}%`);
            progressBarButton.current.value = progressBarRef.current.value;
            animationRef.current = requestAnimationFrame(whilePlaying);
        }
    }

    const changeRange = () =>
    {
        audioPlayerRef.current.currentTime = progressBarButton.current.value;
        progressBarRef.current.style.setProperty('width', `${progressBarButton.current.value / duration * 100}%`);
        setCurrentTime(progressBarButton.current.value);
    }

    const muteVolume = (event) =>
    {
        event.preventDefault();
        setCurrentVolume(audioPlayerRef.current.volume * 100);
        audioPlayerRef.current.muted = !audioPlayerRef.current.muted;
        const btnPlay = document.getElementById(`btn-play-${episodeID}`);
        if(audioPlayerRef.current.muted)
        {
            btnPlay.classList.add('audioplayer-mute');
            volumeBarRef.current.style.setProperty('width', '0%');
        }
        else
        {
            btnPlay.classList.remove('audioplayer-mute')
            volumeBarRef.current.style.setProperty('width', `${currentVolume}%`);
        }
    }

    const volumeControl = (event, audio_id) =>
    {
        event.preventDefault();
        audioPlayerRef.current.volume = volumeBarButtonRef.current.value / 100;
        volumeBarRef.current.style.setProperty('width', `${volumeBarButtonRef.current.value}%`);
        if(audioPlayerRef.current.volume == 0)
        {
            audioClassStates.current.classList.add('audioplayer-mute')
        }
        else
        {
            audioClassStates.current.classList.remove('audioplayer-mute')
        }
    }

    return (
        <div className='podcast-aud-player'>
            <audio
                ref={audioPlayerRef}
                src={audio.url}
                controlsList="sound"
                controls
                className="audio-responsive"

                onPause={(e) => {playingRef.current = false;}}
                onPlaying={(e) => {playingRef.current = true;}}
                onLoadedMetadata={(e) => {setDuration(e.target.duration);}}>
            </audio>

            <div id={`btn-play-${episodeID}`} className={(() =>
            {
                const className = [ "audioplayer" ];
                if(!audioPlayerRef.current)
                    className.push("audioplayer-disabled");
                return className.join(" ");
            })()} ref={ audioClassStates }>

                <AudioPlayerControlsPlayButton
                    audioRef={audioPlayerRef}
                    onClick={(e) =>
                    {
                        playAudio(e);
                    }}
                />
                <AudioPlayerControlsProgressBar
                    audioRef={audioPlayerRef}
                    progressBarRef={progressBarRef}
                    progressBarButton={progressBarButton}
                    max={duration}
                    changeRange={changeRange}
                />
                <AudioPlayerControlsProgressTime
                    duration={duration}
                    currentTime={currentTime}
                />
                <PodcastAudioVolControl
                    volumeBarRef={volumeBarRef}
                    volumeBarButtonRef={volumeBarButtonRef}
                    audio={audio}
                    muteVolume={muteVolume}
                    volumeControl={volumeControl}
                    episodeID={episodeID}
                />
            </div>
        </div>
    )
}

export default PodcastAudioPlayer