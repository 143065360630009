import RecursosContainer from "src/containers/RecursosContainer";
import DatosAbiertosContainer from "src/containers/DatosAbiertosContainer";
import HomeUsuarioContainer from "src/containers/usuarioDatos/HomeUsuarioContainer";
import MisRecursosContainer from "src/containers/usuarioDatos/MisRecursosContainer";
import ReaContainer from "src/containers/ReaContainer";
import MisAulas from "src/components/usuarioDatos/misaulas";
import Capacitacion from "src/components/institucionales/capacitacion";
import NoticiasCollection from "src/components/colecciones";
import PublicacionesContainer from "src/containers/PublicacionesContainer";

export { default as redirects } from './redirects';

export default [
   {
      path: 'publicaciones',
      component: PublicacionesContainer,
   },
   {
      path: 'publicaciones/:recursoId',
      component: PublicacionesContainer
   },
   {
      path: 'publicaciones/:recursoId/:recursoTitulo',
      component: PublicacionesContainer
   },
   {
      path: 'recursos',
      component: RecursosContainer
   },
   {
      path: 'recurso/:recursoId',
      component: RecursosContainer
   },
   {
      path: 'recurso/:recursoId/:recursoTitulo',
      component: RecursosContainer
   },
   {
      path: 'mis-recursos',
      component: MisRecursosContainer,
      auth: true
   },
   {
      path: 'datosabiertos/:recursoId',
      component: DatosAbiertosContainer
   },
   {
      path: 'datosabiertos',
      component: DatosAbiertosContainer
   },
   {
      path: 'callback',
      component: HomeUsuarioContainer
   },
   {
      path: 'rea',
      component: ReaContainer
   },
   {
      path:"mis-aulas",
      component: MisAulas,
      auth: true
   },
   {
      path:"capacitacion",
      component: Capacitacion
   },
   {
      path:"colecciones/:recursoId",
      component: NoticiasCollection
   },
]

